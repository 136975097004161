/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package Bigtincan Hub
 * @copyright 2010-2017 BigTinCan Mobile Pty Ltd
 * @author Shibu Bhattarai <shibu.bhattarai@bigtincan.com>
 */

import * as classNames from "classnames/bind";
import Loader from "components/Loader/Loader";
import * as React from "react";
import { removeWildcardTerm, retextExtract } from "../../helpers/extractWords";
import { IOpportunityModel } from "../../models/index";
import crmServiceClient from "../../service/CrmService";
import outlookClient from "../../service/OutlookClient";
import { OpportunityItemBlock } from "../OpportunityItemBlock/Index";
import { crmDetails } from "../../helpers/crm";
import styles from "./index.less";

export interface IOpportunityBlockState {
    onsearchfocus: boolean;
    activeSearch: boolean;
    isUserLogin: boolean;
    loading: boolean;
    loadingCrmData: boolean;
    opportunitiesLoading: boolean;
    opportunities: IOpportunityModel[];
    showSearchPanel: boolean;
    searchTerm?: string;
    error?: any;
}
export interface IOpportunityBlockProps {
    crmSource: string;
    onItemSelected: (item: IOpportunityModel[]) => void;
    onAddClick?: () => void;
}
export class OpportunityBlock extends React.Component<IOpportunityBlockProps, IOpportunityBlockState> {
    private searchTimeout: number;
    private composerTimer: number;
    private currentSearchTimeStamp: number;
    constructor(props: Readonly<IOpportunityBlockProps>) {
        super(props);
        this.state = {
            onsearchfocus: false,
            activeSearch: false,
            isUserLogin: crmDetails.isCrmauthenticated,
            loading: true,
            loadingCrmData: false,
            opportunitiesLoading: false,
            opportunities: [],
            showSearchPanel: false,
            searchTerm: "",
            error: null,
        };
    }
    public componentWillUnmount() {
        const oldTimer = localStorage.getItem("composetimer");
        if (oldTimer) {
            this.composerTimer = +oldTimer;
            window.clearInterval(this.composerTimer);
        }
    }
    public componentDidMount() {
        this.setState({
            loading: false,
        });
    }
    public startTimer() {
        const oldTimer = localStorage.getItem("composetimer");
        if (oldTimer) {
            this.composerTimer = +oldTimer;
            window.clearInterval(this.composerTimer);
        }
        this.composerTimer = window.setInterval(() => {

            this.setState({
                loading: false,
                loadingCrmData: true,
                error: null,
            });
            outlookClient.getEmailBody().then((item: any) => {
                this.searchCrmInternalBySender(item);
            }).catch(() => {
                this.setState({
                    loading: false,
                    loadingCrmData: false,
                    error: null,
                });
            });
        }, 5000);
        localStorage.setItem("composetimer", this.composerTimer.toString());
    }
    public setErrorMessage(err) {
        let message = "Internal server error. Please try again later.";
        if (err.status_code === 429) {
            message = err.message;
        }
        this.setState({
            loading: false,
            loadingCrmData: false,
            error: { ...err, message },
        });
        // tslint:disable-next-line:no-console
        console.log(err);
    }
    public searchCrmInternalBySender(item: any) {
        if (item) {
            const emails: string[] = [];
            if (item.emailMode === 1) {
                emails.push(item.sender.emailAddress);
                if (emails.length > 0) {
                    crmServiceClient.getOpportunityByEmails(emails.join(","), item.itemId, false, this.props.crmSource).then((oppData) => {
                        this.updateCrmItems(oppData, item);
                    }).catch((err) => {
                        this.setErrorMessage(err);
                    });
                } else {
                    this.setState({
                        opportunities: [],
                        loadingCrmData: false,
                        error: null,
                    });
                }
            } else {
                item.to.getAsync((toData: any) => {
                    if (toData) {
                        const allEmail = toData.value || [];
                        allEmail.forEach((singleTo) => {
                            emails.push(singleTo.emailAddress);
                        });
                        if (emails.length > 0) {
                            crmServiceClient.getOpportunityByEmails(emails.join(","), item.itemId, false, this.props.crmSource).then((oppData) => {
                                this.updateCrmItems(oppData, item);
                            }).catch((err) => {
                                this.setErrorMessage(err);
                            });
                        } else {
                            this.setState({
                                opportunities: [],
                                loadingCrmData: false,
                                error: null,
                            });
                        }
                    }
                });
            }
        } else {
            this.setState({
                opportunities: [],
                loadingCrmData: false,
                error: null,
            });
        }
    }
    public searchCrmInternal(item: any) {
        if (item) {
            const searchTerm = item.subject + " " + item.bodyText;
            retextExtract(searchTerm).then((tokenTerm: string) => {
                crmServiceClient.getOpportunity(tokenTerm, item.itemId, false, this.props.crmSource).then((oppData) => {
                    this.updateCrmItems(oppData, item);
                }).catch((err) => {
                    this.setErrorMessage(err);
                });
            });
        } else {
            this.setState({
                opportunities: [],
                loadingCrmData: false,
                error: null,
            });
        }
    }
    public searchCrm(term) {
        this.setState({
            opportunities: [],
            loadingCrmData: true,
            opportunitiesLoading: true,
            error: null,
        });

        outlookClient.getEmailBody().then((item: any) => {
            if (item && (!term || removeWildcardTerm(term))) {
                if (term === "") {
                    this.searchCrmInternalBySender(item);
                } else {
                    this.currentSearchTimeStamp = new Date().getTime();
                    crmServiceClient.getOpportunity(term, item.itemId, true, this.props.crmSource, this.currentSearchTimeStamp).then((oppData) => {
                        const requestTimeStamp = oppData ? oppData.requestTimeStamp : "";
                        if (this.currentSearchTimeStamp === requestTimeStamp) {
                            this.updateCrmItems(oppData, item);
                        }
                    }).catch((err) => {
                        this.setErrorMessage(err);
                    });
                }
            } else {
                this.setState({
                    opportunities: [],
                    loadingCrmData: false,
                    error: null,
                });
            }
        });

    }
    public updateCrmItems(data, item: any) {
        if (data && data.objects) {
            const allData: any[] = data.objects;
            const arrayOfOpp: IOpportunityModel[] = allData.filter((dataObject: any, index: any) => {
                const { id, name, stage } = dataObject;
                const isSelected = false;
                return {
                    logged: isSelected,
                    index,
                    name,
                    stageName: stage,
                    id,
                    selected: isSelected,
                };
            });
            if (item.emailMode === 2) {
                window.cachesOppData = arrayOfOpp;
            }
            this.setState({
                opportunities: arrayOfOpp,
                loadingCrmData: false,
                opportunitiesLoading: false,
                error: null,
            });
        }
    }
    public render() {
        const { error, isUserLogin, loading, opportunities, loadingCrmData } = this.state;
        const hasOpp = opportunities.length > 0;
        const cx = classNames.bind(styles);
        const selectedOpp = crmDetails.selectedOpportunity;
        const classes = cx({
            searchinput: true,
        }, styles.input);
        const oppBody = cx({
            // hideitem: !hasOpp && !showSearchPanel,
            // compose: this.isComposeMode,
        }, styles.oppbody);

        const classsearchBlock = cx({
            onsearchfocus: this.state.onsearchfocus,
        }, styles.searchBlock);
        const totalCount = opportunities.length;
        return (
            <div>
                {isUserLogin &&
                    <div>
                        <div className={styles.log_email_to_salesfo}>
                            <div className={styles.searchcontainer}>
                                <div className={classsearchBlock}>
                                    <input className={classes} name="searchopp" onFocus={this.onFocusLoss.bind(this, true)} onBlur={this.onFocusLoss.bind(this, false)} onChange={this.onSearchChange.bind(this)} value={this.state.searchTerm} placeholder="Search Opportunity" autoComplete="off" />
                                    {this.state.activeSearch &&
                                        <div onClick={this.onClearSearchTerm.bind(this)} className={styles.close} />
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{ height: "auto" }}>
                            <div className={oppBody}>
                                {opportunities.length === 0 && selectedOpp &&
                                    selectedOpp.map((opp: any) => {
                                        return <div key={opp.id}>
                                            <OpportunityItemBlock onItemSelected={this.onItemSelected.bind(this)} item={opp} isLastItem={totalCount === opp.index + 1} />
                                        </div>;
                                    })
                                }
                                {opportunities.map((item: any) => {
                                    return <div key={item.id}  >
                                        <OpportunityItemBlock onItemSelected={this.onItemSelected.bind(this)} item={item} isLastItem={totalCount === item.index + 1} />
                                    </div>;
                                })}
                                {/* {this.state.opportunitiesLoading &&
                                    <div style={{ position: "absolute", left: 160 }}>
                                        <svg x="0px" y="0px" width="24px" height="30px" viewBox="0 0 24 30">
                                            <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                                                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
                                                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                                                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                                            </rect>
                                            <rect x="8" y="10" width="4" height="10" fill="#333" opacity="0.2">
                                                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                                                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                                                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                                            </rect>
                                            <rect x="16" y="10" width="4" height="10" fill="#333" opacity="0.2">
                                                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                                                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                                                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                                            </rect>
                                        </svg>
                                    </div>
                                } */}
                            </div>
                        </div>
                    </div>
                }
                {!isUserLogin && !loading &&
                    <div className={styles.crmErrorBlock}>
                        <div className={styles.title}>You must authenticate Saleforce from Bigtincan Hub</div>
                        <div className={styles.title} />
                    </div>
                }
                {loading &&
                    <div className={styles.loadingBlock}>
                        <div className={styles.title}>Loading CRM Details</div>
                        <Loader className={styles.loading} type="content" />
                    </div>
                }
                {loadingCrmData &&
                    <div className={styles.loadingcrmblock}>
                        <div className="loader" title="7">
                            <svg x="0px" y="0px" width="24px" height="30px" viewBox="0 0 24 30">
                                <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
                                    <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                                    <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                                </rect>
                                <rect x="8" y="10" width="4" height="10" fill="#333" opacity="0.2">
                                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                                    <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                                    <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                                </rect>
                                <rect x="16" y="10" width="4" height="10" fill="#333" opacity="0.2">
                                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                                    <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                                    <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                                </rect>
                            </svg>
                        </div>
                    </div>
                }
                {error && error.message && !loadingCrmData && !loading &&
                    <div className={styles.oppbody}>
                        <div className={styles.loadingBlock}>
                            <div className={styles.error}>{error.message}</div>
                        </div>
                    </div>
                }
                {/* {!hasOpp && !loadingCrmData && !loading && (!error || !error.message) &&
                    <div className={styles.oppbody}>
                        <div className={styles.loadingBlock}>
                            <div className={styles.title}>No results found</div>
                            <div className={styles.subtitle}>Try searching or creating a new one</div>
                        </div>
                    </div>
                } */}
            </div>
        );
    }
    private onFocusLoss(onFocus) {
        this.setState({
            onsearchfocus: onFocus,
        });
    }
    private onItemSelected(item) {

        const { opportunities } = this.state;

        opportunities.forEach((oppItem) => {
            oppItem.selected = false;
        });

        localStorage.setItem("selectedOpportunity", JSON.stringify(item));

        if (this.props.onItemSelected) {
            opportunities.forEach((oppItem) => {
                oppItem.selected = oppItem.id === item.id;
            });

            this.setState({
                opportunities,
            });

            const notLoggedItem = this.state.opportunities.filter((oppItem) => oppItem.selected && !oppItem.logged);
            this.props.onItemSelected(notLoggedItem);
        }
    }
    private onSearchChange(target) {
        this.setState({
            searchTerm: target.target.value,
        });
        if (target.target.value.length >= 2) { // Set minimun search term char to 2
            if (this.searchTimeout) {
                window.clearTimeout(this.searchTimeout);
            }
            this.searchTimeout = window.setTimeout(() => {
                this.searchCrm(this.state.searchTerm);
            }, 800);
            const isActive = target.target.value && target.target.value.length > 0;
            this.setState({
                activeSearch: isActive,
            });
        }
    }
    private onClearSearchTerm() {
        if (this.state.searchTerm === "") {
            return;
        }
        const selectedOpportunities = this.state.opportunities.filter((item) => {
            return item.selected;
        });
        this.setState({
            opportunities: selectedOpportunities,
            searchTerm: "",
        });
    }
    // private onSearchClick() {
    //     this.setState({
    //         showSearchPanel: !this.state.showSearchPanel,
    //     });
    // }
}
