/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Rubenson Barrios <rubenson.barrios@bigtincan.com>
 */

const DEFAULT_SCALE_VALUE = 'page-width';
const DEFAULT_SCROLL_MODE = 0;
export const LOCAL_STORAGE_KEY_OF_SAVED_SCROLL_MODE = 'BTC_PDF_SCROLL_TYPE';

export const SCALE_VALUES = {
  continuous: 'page-width',
  singlePage: 'page-fit',
};
export const SCROLL_MODE = {
  // these scrolling mode codes (i.e. 0, 3) are defined by PDF.js
  // more available codes could be found https://github.com/mozilla/pdf.js/blob/master/web/ui_utils.js
  continuous: 0, // default mode
  singlePage: 3,
};

const initEventBus = props => {
  const {
    currentPage,
    eventBus,
    pdfViewer,
    scale,
    onCurrentPageChange,
    onPageAnchorClick,
    onPageRendered,
    onScaleChange,
    onUpdateResultsCount,
    onUpdateState,
  } = props;

  // Set default scale/rotation on pageinit
  eventBus.on('pagesinit', () => {
    // When the document has been completely loaded, check if there is a default scroll type saved in local storage
    const scrollType = localStorage.getItem(
      LOCAL_STORAGE_KEY_OF_SAVED_SCROLL_MODE
    );
    const mode = SCROLL_MODE[scrollType];

    pdfViewer.scrollMode =
      typeof mode === 'number' ? mode : DEFAULT_SCROLL_MODE;

    pdfViewer.currentScaleValue =
      SCALE_VALUES[scrollType] || DEFAULT_SCALE_VALUE;

    pdfViewer.pagesRotation = 0;
  });

  eventBus.on(
    'scalechanging',
    e => {
      if (onScaleChange && scale !== e.scale) {
        pdfViewer.currentScaleValue = e.scale;
        onScaleChange({ scale: e.scale });
      }
      pdfViewer.update();
    },
    true
  );

  eventBus.on('pagechanging', e => {
    if (currentPage !== e.pageNumber || e.pageNumber === 1) {
      onCurrentPageChange(e, e.pageNumber);
    }
  });

  eventBus.on('pagerendered', e => {
    // try to scroll the highlighted search into view
    if (onPageRendered) {
      const pageNumber = e.detail.pageNumber;
      const pageIndex = pageNumber - 1;
      onPageRendered(pageIndex);
    }
    const pagesCollection = document.getElementsByClassName('page');
    const pages = [].slice.call(pagesCollection);
    pages.forEach(page => {
      const hyperlinksCollection = page.getElementsByTagName('a');
      const hyperlinks = [].slice.call(hyperlinksCollection).filter(item => {
        const { classList, href } = item;
        return (
          !classList.contains('internalLink') || (href || '').indexOf('#') === 0
        );
      });
      hyperlinks.forEach(hyperlink => {
        const tmpHyperlink = hyperlink;
        tmpHyperlink.onclick = anchorEventArg => {
          if (onPageAnchorClick) {
            anchorEventArg.preventDefault();
            onPageAnchorClick(anchorEventArg.currentTarget);
          }
        };
      });
    });
  });

  eventBus.on('updatefindcontrolstate', context => {
    if (onUpdateState) {
      onUpdateState(context);
    }
  });

  eventBus.on('updatefindmatchescount', context => {
    if (onUpdateResultsCount) {
      onUpdateResultsCount(context);
    }
  });

  eventBus.on('switchscrollmode', ({ mode }) => {
    pdfViewer.scrollMode = mode;

    const type = Object.keys(SCROLL_MODE).find(
      key => SCROLL_MODE[key] === mode
    );

    if (type) {
      // update the scale value
      pdfViewer.currentScaleValue = SCALE_VALUES[type] || DEFAULT_SCALE_VALUE;
      // save user preference into localStorage
      localStorage.setItem(LOCAL_STORAGE_KEY_OF_SAVED_SCROLL_MODE, type);
    }
  });

  return eventBus;
};

export default initEventBus;
