
class OutlookClient {
    private removeForwardedEmail(body) {
        const regexFrom = /^\s*From:.*$/mg;
        const regexTo = /^\s*To:.*$/mg;
        const regexDate = /^\s*Date:.*$/mg;
        const regexCc = /^\s*Cc:.*$/mg;
        const regexCC = /^\s*CC:.*$/mg;
        const regexSent = /^\s*Sent:.*$/mg;
        const regexSubject = /^\s*Subject:.*$/mg;
        const regexBlankLine = /^(?:[\t ]*(?:\r?\n|\r))+/mg;
        const subst = "";
        let bdy = body.replace(regexFrom, subst);
        bdy = bdy.replace(regexTo, subst);
        bdy = bdy.replace(regexSent, subst);
        bdy = bdy.replace(regexSubject, subst);
        bdy = bdy.replace(regexBlankLine, subst);
        bdy = bdy.replace(regexDate, subst);
        bdy = bdy.replace(regexCc, subst);
        bdy = bdy.replace(regexCC, subst);
        bdy = bdy.replace("Forwarded message", subst);
        return bdy;
    }
    private removeForwardedEmailSingle(body) {
        const regexFrom = /^From:.*$/mg;
        const regexTo = /^To:.*$/mg;
        const regexDate = /^Date:.*$/mg;
        const regexCc = /^Cc:.*$/mg;
        const regexCC = /^CC:.*$/mg;
        const regexSent = /^Sent:.*$/mg;
        const regexSubject = /^Subject:.*$/mg;
        const regexBlankLine = /^(?:[\t ]*(?:\r?\n|\r))+/mg;
        const subst = "";
        let bdy = body.replace(regexFrom, subst);
        bdy = bdy.replace(regexTo, subst);
        bdy = bdy.replace(regexSent, subst);
        bdy = bdy.replace(regexSubject, subst);
        bdy = bdy.replace(regexBlankLine, subst);
        bdy = bdy.replace(regexDate, subst);
        bdy = bdy.replace(regexCc, subst);
        bdy = bdy.replace(regexCC, subst);
        bdy = bdy.replace("Forwarded message", subst);
        return bdy;
    }
    private refineBodyText(body: string) {
        let bdy = body;
        for (let i = 0; i < 10; i++) {
            bdy = this.removeForwardedEmail(bdy);
            bdy = this.removeForwardedEmailSingle(bdy);
        }
        return bdy.split("\n").slice(0, 50).join(" ");
    }
    public getEmailBodyProm(item: any, resolve: any) {
        item.emailMode = item.normalizedSubject !== undefined ? 1 : 2;
        item.body.getAsync("text", { asyncContext: "Bigtincan Plugins" }, (result) => {
            if (result.status === window.Office.AsyncResultStatus.Failed) {
                item.composeSubject = "";
                resolve(item);
            } else {
                item.bodyText = this.refineBodyText(result.value);
                if (item.emailMode === 2) {
                    if (item.subject) {
                        item.subject.getAsync((asyncResult) => {
                            if (asyncResult.status === window.Office.AsyncResultStatus.Failed) {
                                item.composeSubject = "";
                                resolve(item);
                            } else {
                                item.composeSubject = asyncResult.value;
                                resolve(item);
                            }
                        });
                    } else {
                        item.composeSubject = "";
                        resolve(item);
                    }
                } else {
                    item.composeSubject = "";
                    resolve(item);
                }
            }
        });
    }
    public getEmailBody() {
        return new Promise((resolve, reject) => {
            if (window.Office && window.Office.context && window.Office.context.mailbox) {
                const mailbox = window.Office.context.mailbox;
                const item = mailbox.item;
                let itemId = item.itemId;
                if (itemId === null || itemId === undefined) {
                    item.saveAsync((result) => {
                        itemId = result.value;
                        item.itemId = itemId;
                        this.getEmailBodyProm(item, resolve);
                    });
                } else {
                    this.getEmailBodyProm(item, resolve);
                }

            } else {
                resolve({
                    subject: "test",
                    bodyText: "test",
                    itemId: "2qwqw",
                    emailMode: 2,
                });
            }
        });
    }
    public onEmailChange() {
        return new Promise((resolve, reject) => {
            if (window.Office && window.Office.context && window.Office.context.mailbox) {
                const mailbox = window.Office.context.mailbox;
                const item = mailbox.item;
                mailbox.addHandlerAsync(window.Office.EventType.ItemChanged, resolve.bind(this, item));
            } else {
                reject();
            }
        });
    }
}
const outlookClient = new OutlookClient();
export default outlookClient;
