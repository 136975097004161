/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package Bigtincan Hub
 * @copyright 2010-2017 BigTinCan Mobile Pty Ltd
 * @author Shibu Bhattarai <shibu.bhattarai@bigtincan.com>
 */

import * as React from "react";
import * as classNames from "classnames/bind";
import { RouteComponentProps } from "react-router";
import hubService from "../../service/HubService";
import PdfViewer from "components/ViewerFiles/PdfViewer/PdfViewer";
import SpreadsheetViewer from "components/ViewerFiles/SpreadsheetViewer/SpreadsheetViewer";
import PresentationViewer from "components/ViewerFiles/PresentationViewer/PresentationViewer";
import ViewerToolbar from "components/Viewer/ViewerToolbar";
import Plaintext from "components/ViewerFiles/Plaintext";
import AppViewer from "components/ViewerFiles/AppViewer/AppViewer";
import AudioVideo from "../../components/Viewers/AudioVideo";
import ImageViewer from "components/ViewerFiles/ImageViewer/ImageViewer";
import Blankslate from "components/Blankslate/Blankslate";
import Csv from "components/ViewerFiles/Csv";
import Loader from "components/Loader/Loader";
import styles from "./Index.less";
export interface IFileViewerState {
  activeFile: any;
  fileId: number;
  isLoading: boolean;
  hasError: boolean;
  error: string;
}
export class FileViewer extends React.Component<RouteComponentProps<{}>, IFileViewerState> {
  private filesWithHTML: string[];
  private files: any;
  private authString: string;
  constructor(props) {
    super(props);
    this.filesWithHTML = ["app", "excel", "keynote", "powerpoint"];
    this.handleToolbarItemClick = this.handleToolbarItemClick.bind(this);
    const params: any = this.props.match ? this.props.match.params : null;
    this.files = {

    };
    let fileId: number = 0;
    if (params && params.fileId) {
      fileId = +params.fileId;
    }
    this.state = {
      hasError: false,
      error: "",
      isLoading: true,
      activeFile: {

      },
      fileId,
    };
    let token: any = null;
    if (params && params.token) {
      token = params.token;
    }
    if (params && params.region) {
      const global: any = window;
      const regions = global.PLUGINCONFIG.REGIONS;
      const selectedRegion = regions.find((item) => {
        return item.code === params.region;
      });
      global.BTC = selectedRegion.apiServer;
      global.BTC_APP_URL = selectedRegion.value;
      localStorage.setItem("serverprofile", JSON.stringify(selectedRegion));
    }
    if (!token) {
      token = localStorage.getItem("BTCTK_A");
    } else {
      localStorage.setItem("BTCTK_A", token);
    }
    this.authString = "&access_token=" + token;
  }
  public componentDidMount() {
    const { fileId } = this.state;
    if (fileId > 0) {
      hubService.getFile(fileId).then((fileInfo) => {
        if (fileInfo && this.filesWithHTML.indexOf(fileInfo.category) > -1) {
          hubService.loadHtmlData(fileInfo.id).then((data) => {
            fileInfo.data = data;
            fileInfo.baseUrl = data.baseUrl;
            this.setState({
              activeFile: fileInfo,
              isLoading: false,
              hasError: false,
              error: "",
            });
          });
        } else if (fileInfo && fileInfo.category === "web") {
          location.href = fileInfo.sourceUrl;
        } else {
          this.setState({
            activeFile: fileInfo,
            isLoading: false,
            hasError: false,
            error: "",
          });
        }
      }).catch((err) => {
        this.setState({
          hasError: true,
          error: err.message,
        });
      });
    }
  }
  private fixedFileExtension() {
    const file = this.state.activeFile;
    if (file && file.url && file.url.indexOf(".docx") >= 0) {
      file.url = file.url.replace(".docx", ".pdf");
    } else if (file && file.url && file.url.indexOf(".doc") >= 0) {
      file.url = file.url.replace(".doc", ".pdf");
    } else if (file && file.url && file.url.indexOf(".vsd") >= 0) {
      file.url = file.url.replace(".vsd", ".pdf");
    } else if (file && file.url && file.url.indexOf(".dwg") >= 0) {
      file.url = file.url.replace(".dwg", ".pdf");
    } else if (file && file.url && file.url.indexOf(".pages") >= 0) {
      file.url = file.url.replace(".pages", ".pdf");
    } else if (file && file.url && file.url.indexOf(".numbers") >= 0) {
      file.url = file.url.replace(".numbers", ".pdf");
    } else if (file && file.url && file.url.indexOf(".mpp") >= 0) {
      file.url = file.url.replace(".mpp", ".jpg");
    }
    //
  }
  private getViewerComponent() {
    this.fixedFileExtension();
    const file = this.state.activeFile;
    let Comp: any = null;

    switch (file.category) {
      case "cad":
      case "numbers":
      case "pages":
      case "pdf":
      case "word":
      case "visio":
        Comp = (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
            }}
          >
            <PdfViewer
              key={"file-" + file.id}
              ref={(c) => {
                this.files[file.id] = c;
              }}
              authString={this.authString}
              onDocumentComplete={this.handlePdfComplete.bind(this)}
              onDocumentError={this.handlePdfError}
              onOutlineComplete={this.handlePdfOutlineComplete.bind(this)}
              onSidePanelClose={this.handlePdfSidePanelClose}
              {...file}
            />
          </div>
        );
        break;
      case "excel":
        Comp = <SpreadsheetViewer inViewer={true} {...file} />;
        break;
      case "keynote":
      case "powerpoint":
      case "presentation":
        Comp = (
          <div>
            {file.data && (
              <PresentationViewer
                key={"file-" + file.id}
                ref={(c) => {
                  this.files[file.id] = c;
                }}
                currentSlide={file.currentPage}
                onViewerToolbarMenuItemClick={this.handleToolbarItemClick}
                onGetSlideCount={this.handlePresentationGetSlideCount.bind(
                  this,
                )}
                onSlideChange={this.handlePresentationSlideChange}
                onThumbPanelChange={this.handlePresentationThumbPanelChange}
                onError={this.handleError}
                {...file}
              />
            )}
            {file.totalPages && (
              <ViewerToolbar
                currentPage={file.currentPage || 1}
                totalPages={file.totalPages}
                fullscreen={!file.hasWatermark}
                inViewer={true}
                onCurrentPageChange={this.handleCurrentPageChange.bind(
                  this,
                )}
                onItemClick={this.handleToolbarItemClick.bind(this)}
              />
            )}
          </div>
        );
        break;
      case "txt":
      case "vcard":
        Comp = (
          <Plaintext
            key={"file-" + file.id}
            ref={(c) => {
              this.files[file.id] = c;
            }}
            authString={this.authString}
            onLoad={this.handlePlaintextLoad.bind(this)}
            onError={this.handleError}
            {...file}
          />
        );
        break;
      case "audio":
      case "video":
        Comp = (
          <AudioVideo
            key={"file-" + file.id}
            ref={(c) => {
              this.files[file.id] = c;
            }}
            inViewer={true}
            autoPlay={true}
            isVideo={file.category === "video"}
            authString={this.authString}
            onFullscreenClick={this.handleVideoFullscreenClick}
            {...file}
          />
        );
        break;
      case "image":
      case "project":
        Comp = (
          <ImageViewer
            authString={this.authString}
            ref={(c) => {
              this.files[file.id] = c;
            }}
            key={"file-" + file.id}
            inViewer={true}
            {...file}
          />
        );
        break;
      case "csv":
        Comp = (
          <div>
            <Csv
              key={"file-" + file.id}
              ref={(c) => {
                this.files[file.id] = c;
              }}
              currentPage={file.currentPage || 1}
              authString={this.authString}
              onLoad={this.handleCsvLoad}
              onError={this.handleError}
              {...file}
            />
            {file.totalPages && (
              <ViewerToolbar
                currentPage={file.currentPage || 1}
                totalPages={file.totalPages}
                inViewer={true}
                onCurrentPageChange={this.handleCurrentPageChange}
                onItemClick={this.handleToolbarItemClick}
              />
            )}
          </div>
        );
        break;
      case "app":
        Comp = (
          <AppViewer
            key={`file-${file.id}`}
            ref={(c) => {
              this.files[file.id] = c;
            }}
            referrer={document.location.origin}
            onError={this.handleError}
            {...file}
          />
        );
        break;
      default:
        Comp = (
          <div className={styles.center}>
            <Blankslate
              className={styles.error}
              icon={file.category}
              heading="Sorry, the file you're trying to view is currently unsupported."
            />
          </div>
        );
        break;
    }

    return Comp;
  }
  private handleCsvLoad(data) {
    const { totalPages } = data;
    const file = this.state.activeFile;
    file.currentPage = 1;
    file.totalPages = totalPages;
    this.setState({
      activeFile: file,
    });
  }
  private handleVideoFullscreenClick(event) {
    event.preventDefault();
    const { activeFile } = this.state;
    if (activeFile) {
      this.requestFileFullscreen(activeFile);
    }
  }
  private handlePlaintextLoad(event) {
    const file = this.state.activeFile;
    file.content = event.responseText;
    this.setState({
      activeFile: file,
    });
  }
  public handleCurrentPageChange(event) {
    const file = this.state.activeFile;
    const { totalPages } = file;
    let newPage = parseInt(event.currentTarget.value, 10) || 0;
    if (newPage < 1) {
      newPage = totalPages;
    } else if (newPage > totalPages) {
      newPage = 1;
    }

    if (newPage) {
      file.currentPage = newPage;
      this.setState({
        activeFile: file,
      });
    }
  }
  public handleToolbarItemClick(event, action) {
    const file = this.state.activeFile;
    const { currentPage, totalPages } = file;
    switch (action) {
      case "fullscreen":
        this.requestFileFullscreen(file);
        return;
      case "prevPage": {
        let newPage = currentPage - 1;
        if (newPage < 1) {
          newPage = totalPages;
        }
        file.currentPage = newPage;
        break;
      }
      case "nextPage": {
        let newPage = currentPage + 1;
        if (newPage > totalPages) {
          newPage = 1;
        }
        file.currentPage = newPage;
        break;
      }
      default:
        return;
    }
    this.setState({
      activeFile: file,
    });
  }

  public requestFileFullscreen(file) {
    let elem = this.files[file.id].elem;

    // btc file
    if (file.data) {
      elem = this.files[file.id].btcFrame;

      // cad/pdf file
    } else if (file.category === "cad" || file.category === "pdf") {
      elem = this.files[file.id].canvas;
    }

    if (!elem.mozFullScreenElement && !elem.webkitDisplayingFullscreen && !elem.msFullscreenElement) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      }
    } else if (elem.exitFullscreen) {
      elem.exitFullscreen();
    } else if (elem.msExitFullscreen) {
      elem.msExitFullscreen();
    } else if (elem.mozCancelFullScreen) {
      elem.mozCancelFullScreen();
    } else if (elem.webkitExitFullscreen) {
      elem.webkitExitFullscreen();
    }
  }
  public handlePresentationGetSlideCount(event, data) {
    const { slideCount } = data;
    const file = this.state.activeFile;
    file.totalPages = slideCount;
    file.currentPage = 1;
    this.setState({
      activeFile: file,
    });

  }
  // tslint:disable-next-line:no-empty
  public handlePresentationThumbPanelChange() {
  }
  // tslint:disable-next-line:no-empty
  public handlePresentationSlideChange() {
  }
  // tslint:disable-next-line:no-empty
  public handlePdfComplete(id, pdf) {
    const file = this.state.activeFile;
    file.currentPage = 1;
    file.findtext = true;
    file.totalPages = pdf.numPagesle;
    this.setState({
      activeFile: file,
    });
  }
  // tslint:disable-next-line:no-empty
  public handlePdfError() {
    // tslint:disable-next-line:no-console
    console.log(arguments);
  }
  // tslint:disable-next-line:no-empty
  public handlePdfOutlineComplete(id, hasToc) {
    const file = this.state.activeFile;
    file.hasToc = hasToc;
    this.setState({
      activeFile: file,
    });
  }
  // tslint:disable-next-line:no-empty
  public handlePdfSidePanelClose() {

  }
  // tslint:disable-next-line:no-empty
  public handleError() {

  }
  public render() {
    const { activeFile, hasError, error, isLoading } = this.state;
    const { description } = activeFile;

    const cx = classNames.bind(styles);
    const classes = cx({
    }, styles.container);
    const comp = this.getViewerComponent();
    return (
      <div className={classes}>
        <div className={styles.header}>
          <div className={styles.title}>
            {description}
          </div>
        </div>
        <div className={styles.viewercontainer}>
          {!isLoading && !hasError &&
            comp
          }
          {isLoading && !hasError &&
            <div className={styles.center}>
              <Loader type="content" />
            </div>
          }
          {hasError &&
            <div className={styles.center}>
              <Blankslate
                className={styles.error}
                icon="error"
                heading={error}
              />
            </div>
          }
        </div>
      </div>
    );
  }
  // tslint:disable-next-line:no-empty
  public handleBookmarkAll() {
  }
  public onFileSearchClick() {
    this.props.history.push("/filerecommended");
  }
}
