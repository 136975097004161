import apiClient from "./ApiClient";
import crmApiClient from "./CrmApiClient";
class CrmService {
  public oppColumns: any[];
  public acountColumns: any[];
  public getCrmSettings(crmSource: string) {
    return apiClient.get(`/crm/settings?source=${crmSource}`, "webapi", {
    });
  }
  public initDefaultColumns() {
    this.oppColumns = [{
      label: "Stage Name",
      value: "StageName",
    }];

    this.acountColumns = [{
      label: "Owner",
      value: "Owner.Name",
    }, {
      label: "Type",
      value: "Type",
    }, {
      label: "AccountNumber",
      value: "AccountNumber",
    }];
  }
  public setOppColumns(data, nameSpace) {
    const field = data[1] || "";
    const filedMappingValue = field.indexOf("__c") >= 0 ? (nameSpace ? `${nameSpace}__${data[1]}` : `${data[1]}`) : field;
    this.oppColumns = [{
      label: data[0],
      value: filedMappingValue,
    }];
  }
  public setAccountColumns(firstData, secondData, nameSpace) {
    if (firstData) {
      const field = firstData[1] || "";
      const firstFiledMappingValue = field.indexOf("__c") >= 0 ? (nameSpace ? `${nameSpace}__${firstData[1]}` : `${firstData[1]}`) : field;
      this.acountColumns = [{
        label: firstData[0],
        value: firstFiledMappingValue,
      }];
    } else {
      this.acountColumns = [{
        label: "Owner",
        value: "Owner.Name",
      }];
    }
    if (secondData) {
      const secondField = secondData[1] || "";
      const secondFiledMappingValue = secondField.indexOf("__c") >= 0 ? (nameSpace ? `${nameSpace}__${secondData[1]}` : `${secondData[1]}`) : secondField;
      this.acountColumns.push({
        label: secondData[0],
        value: secondFiledMappingValue,
      });
    } else {
      this.acountColumns.push({
        label: "Type",
        value: "Type",
      });
    }
    this.acountColumns.push({
      label: "AccountNumber",
      value: "AccountNumber",
    });
  }
  private getAccountId() {
    return localStorage.getItem("cloudAccountId");
  }
  public getOpportunity(keyword: string, messageId: string, searchMode: boolean, source: string, requestTimeStamp?: number) {
    return crmApiClient.get("/crm/search", "webapi", {
      params: {
        source,
        type: "opportunity",
        limit: 10,
        search: keyword,
        requestTimeStamp,
      },
    });
  }
  public getOpportunityByEmails(keyword: string, messageId: string, searchMode: boolean, source: string, requestTimeStamp?: number) {
    return crmApiClient.get("/crm/search", "webapi", {
      params: {
        source,
        type: "opportunity",
        limit: 10,
        search: keyword,
        requestTimeStamp,
      },
    });
  }
  public logEmail(data: any, source: string) {
    return crmApiClient.post("/v0/accounts/" + this.getAccountId() + "/crm/objects?raw_type=Task", source, {
      data,
    });
  }
}
const crmServiceClient = new CrmService();
export default crmServiceClient;
