/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package Bigtincan Hub
 * @copyright 2010-2017 BigTinCan Mobile Pty Ltd
 * @author Shibu Bhattarai <shibu.bhattarai@bigtincan.com>
 */

import * as React from "react";
import autobind from "class-autobind";
import * as classNames from "classnames/bind";
import { SlideItem } from "../../components/SlideItem/Index";
import styles from "./Introduction.less";

import Btn from "components/Btn/Btn";

import Slider from "react-slick";
export interface IWelcomeProps {
  className?: string;
  style?: any;
  onSkip?: () => void;
}
export interface IWelcomState {
  slideIndex: number;
}
export class Introduction extends React.Component<IWelcomeProps, IWelcomState> {

  constructor(props) {
    super(props);
    autobind(this);
    this.state = {
      slideIndex: 0,
    };

  }
  public render() {
    const cx = classNames.bind(styles);
    const classes = cx({
      introduction: true,
      view: true,
    }, this.props.className);

    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: (index) => {
        this.setState({
          slideIndex: index,
        });
      },
    };
    return (
      <div className={classes} style={this.props.style}>
        <div className={styles.conainer}>
          <div className={styles.cmdcontainer}>
            <Btn className={styles.skipbtn} onClick={this.props.onSkip} borderless={true}>Skip</Btn>
          </div>
          <Slider {...settings}>
            <div><SlideItem title="Log your emails to Salesforce" className={styles.introfirstimage} desc="Capture important communications by logging emails to Salesforce. " /></div>
            <div><SlideItem title="Log against an Entity" className={styles.introsecondimage} desc="Allow the Hub to automatically record an Opportunity, Contact or Lead. " /></div>
            <div><SlideItem title="Attach Hub files to your emails" className={styles.introfthirdimage} desc="Compose rich emails by attaching Hub files direct to Outlook. " /></div>

          </Slider>
          {this.state.slideIndex === 2 &&
            <div className={styles.signincontainer}>
              <Btn onClick={this.props.onSkip} className={styles.signbtn} inverted={true}>Log In</Btn>
            </div>
          }
        </div>
      </div>
    );
  }
}
