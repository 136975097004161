/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package Bigtincan Hub
 * @copyright 2010-2017 BigTinCan Mobile Pty Ltd
 * @author Shibu Bhattarai <shibu.bhattarai@bigtincan.com>
 */
import * as React from "react";
import Cookies from "universal-cookie";
import styles from "./index.less";
export interface ILayoutProps {
    children?: React.ReactNode;
}

export interface ILayoutState {
    isSamlLogin: boolean;
}
export class Layout extends React.Component<ILayoutProps, ILayoutState> {
    constructor(props) {
        super(props);
        this.state = {
            isSamlLogin: false,
        };
    }
    public componentDidMount() {
        const queryString = require("query-string");
        let url = location.search;
        if (location.hash.indexOf("access_token") > 0) {
            url = location.hash;
            url = url.replace("/access_token", "access_token");
        }
        const parsed = queryString.parse(url);
        if (parsed && parsed._sem) {
            const parent = window.parent;
            if (parent != null) {
                localStorage.setItem("_sem", parsed._sem);
                window.close();
            }
        }
        if (parsed && parsed.access_token) {
            this.setState({
                isSamlLogin: true,
            });
            const tokens: any = {
                BTCTK_A: parsed.access_token,
                BTCTK_R: parsed.refresh_token,
                expires_in: parsed.expires_in,
            };
            localStorage.setItem("BTCTK_A", tokens.BTCTK_A);
            localStorage.setItem("BTCTK_R", tokens.BTCTK_R);
            localStorage.setItem("expires_in", tokens.expires_in);
            try {
                const cookies = new Cookies();
                cookies.set("BTCTK_A", tokens.access_token, { path: "/" });
                cookies.set("BTCTK_R", tokens.refresh_token, { path: "/" });
                cookies.set("expires_in", tokens.expires_in, { path: "/" });
                // tslint:disable-next-line:no-empty
            } catch {
            }
            const json = JSON.stringify(tokens);
            window.Office.initialize = () => {
                window.Office.context.ui.messageParent(json);
            };
        } else if (parsed && parsed.samlIdentifier) {
            let apiBaseUrl = decodeURIComponent(parsed.apiBaseUrl);
            apiBaseUrl = apiBaseUrl.replace("://", "://" + decodeURIComponent(parsed.samlIdentifier) + ".");
            const tmploginUrl = apiBaseUrl + "/signin?auth_type=saml&redirect_referrer=" + location.href;
            localStorage.removeItem("_sem");
            location.href = tmploginUrl;
            this.setState({
                isSamlLogin: true,
            });
        } else {
            this.setState({
                isSamlLogin: false,
            });
        }

    }
    public render() {
        let com: any;
        if (this.state.isSamlLogin) {
            com = (
                <div className={styles.container_fluid}>
                    Please wait
                </div>
            );
        } else {
            com = (
                <div className={styles.container_fluid}>
                    {this.props.children}
                </div>
            );
        }
        return com;
    }
}
