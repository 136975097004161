/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2020 BigTinCan Mobile Pty Ltd
 * @author Olivia Mo <olivia.mo@bigtincan.com>
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import classNames from 'classnames/bind';
import tinycolor from 'tinycolor2';

import StoryThumbNew from 'components/StoryThumbNew/StoryThumbNew';
import ShareItemContent from 'components/ShareItemContent/ShareItemContent';
import Btn from 'components/Btn/Btn';
import Icon from 'components/Icon/Icon';
import styles from './StoryItemNew.less';

const StoryHoverPreview = props => {
  const { children, excerpt, updated, ...others } = props;
  const trimmedExcerpt =
    excerpt.length < 90 ? excerpt : `${excerpt.slice(0, 89)}...`;
  return (
    <div {...others}>
      <div className={styles.excerpt}>{trimmedExcerpt}</div>
      <div className={styles.updated}>
        <FormattedDate
          value={updated}
          day="2-digit"
          month="short"
          year="numeric"
          hour="numeric"
          minute="numeric"
        />
      </div>
      {children}
    </div>
  );
};
const StoryItemNew = props => {
  const {
    badgeColour,
    badgeTitle,
    id,
    permId,
    name,
    childType,
    files,
    fileCount,
    isProtected,
    rootUrl,
    grid,
    isQuickfile,
    isQuicklink,
    onClick,
    quicklinkUrl,
    ratingCount,
    commentCount,
    comments,
    style,
    thumbSize,
    noLink,
    updated,
    isShare,
    isLiked,
    customThumbSize,
    excerpt,
    showQuicklinkEdit,
    showQuickEdit,
    onQuickEditClick,
    showBadges,
  } = props;

  const handleClick = (e, stopPropagation) => {
    e.preventDefault();
    if (stopPropagation) e.stopPropagation();
    if (typeof onClick === 'function') {
      onClick(e, { ...{ props } });
    }
  };

  const handleQuickEditClick = e => {
    e.preventDefault();
    e.stopPropagation();
    if (typeof onQuickEditClick === 'function') {
      onQuickEditClick(e, { ...{ props } });
    }
  };

  // TODO - Revisit sizes when design has given correct dimensions
  let thumbWidthSize = customThumbSize;
  const sizes = {
    small: grid ? 100 : 46,
    medium: grid ? 200 : 70,
    large: grid ? 300 : 90,
    custom: customThumbSize,
  };
  if (!customThumbSize) thumbWidthSize = sizes[thumbSize];

  // Story anchor URL
  let anchorUrl = `${rootUrl}/story/${permId}${
    id && !permId ? `?rev=${id}` : ''
  }`;
  // Ignore protected & archived stories
  if (!isProtected && childType !== 'revision') {
    // Quicklink
    if (isQuicklink && quicklinkUrl) {
      anchorUrl = quicklinkUrl;
      // Quickfile
    } else if (isQuickfile && files && files[0]) {
      anchorUrl = `/file/${files[0].id}`;
    }
  }

  const cx = classNames.bind(styles);
  const itemClasses = cx({
    storyItem: true,
    listItem: !grid && !isShare,
    gridItem: grid,
  });

  const nameStyle = {
    width: grid ? `${thumbWidthSize}px` : 'auto',
  };

  // Merge passed style with grid customThumbSize
  const itemStyle = {
    ...style,
    width: (style && !style.width) || grid ? `${thumbWidthSize}px` : 'auto',
  };

  const badgeStyle = {
    color: `${badgeColour}`,
  };

  const ratingClasses = cx({
    ratingCount: true,
    isLiked: isLiked,
  });

  const quickDetailBtn = showQuicklinkEdit ? (
    <Btn
      icon="edit"
      small
      borderless
      onClick={e => handleClick(e, true)}
      className={styles.openQuicklinkBtn}
    />
  ) : null;

  const quickEditBtn = showQuickEdit ? (
    <Icon
      name="edit-box"
      onClick={handleQuickEditClick}
      className={styles.quickEditBtn}
    />
  ) : null;

  // Badge label text color should constrast with bg colour
  const contrast = tinycolor.readability(badgeColour, '#fff');
  const limit = 1.65; // contrast before switching from light/dark text
  const labelColor = contrast < limit ? '#222222' : '#FFFFFF';

  // badge label style
  const labelStyle = {
    backgroundColor: badgeColour,
    color: labelColor,
  };

  const itemContent = (
    <>
      <StoryThumbNew {...props} />
      <div className={styles.nameItem}>
        <label className={styles.name} style={nameStyle}>
          {name}
          {showBadges && !grid && badgeTitle && (
            <span className={styles.badgeTitle} style={labelStyle}>
              {badgeTitle}
            </span>
          )}
        </label>
        {grid && (
          <div>
            {showBadges && badgeTitle && (
              <span className={styles.badgeTitle} style={badgeStyle}>
                {badgeTitle}
              </span>
            )}
            <span>
              <FormattedDate
                value={updated * 1000}
                day="2-digit"
                month="short"
                year="numeric"
              />
            </span>
          </div>
        )}
        {!grid && !isShare && (fileCount > 0 || files.length > 0) && (
          <p className={styles.fileCount}>
            <FormattedMessage
              id="n-files"
              defaultMessage="{itemCount, plural, one {File} other {Files}}"
              values={{
                itemCount: fileCount || files.length,
              }}
            />
          </p>
        )}
      </div>
      {!grid && (
        <div className={styles.badgeCounts}>
          {ratingCount > 0 && (
            <span className={ratingClasses}>
              <FormattedNumber value={ratingCount} />
            </span>
          )}
          {(commentCount > 0 || comments?.length > 0) && (
            <span className={styles.commentCount}>
              <FormattedNumber value={commentCount || comments?.length} />
            </span>
          )}
          {!grid && (isQuickfile || isQuicklink) && quickDetailBtn}
          {!grid && quickEditBtn}
        </div>
      )}
      {grid && thumbSize !== 'small' && (
        <StoryHoverPreview
          excerpt={excerpt}
          updated={updated * 1000}
          className={styles.preview}
          style={{ height: sizes[thumbSize] }}
        >
          {(isQuickfile || isQuicklink) && quickDetailBtn}
        </StoryHoverPreview>
      )}
      {quickEditBtn}
    </>
  );

  if (noLink) {
    return (
      <div
        aria-label={name}
        data-id={permId || id}
        className={itemClasses}
        style={itemStyle}
        onClick={handleClick}
      >
        {isShare ? (
          <ShareItemContent anchorUrl={anchorUrl} {...props} />
        ) : (
          itemContent
        )}
      </div>
    );
  }

  return (
    <div
      aria-label={name}
      data-id={permId || id}
      className={itemClasses}
      style={itemStyle}
    >
      <a href={anchorUrl} rel="noopener noreferrer" onClick={handleClick}>
        {itemContent}
      </a>
    </div>
  );
};

StoryItemNew.defaultProps = {
  authString: '',
  colour: '',
  customThumbSize: 0,
  disabled: false,
  isFeatured: false,
  isSelected: false,
  isShare: false,
  noLink: false,
  rootUrl: '',
  showQuicklinkEdit: true,
  thumbSize: 'large',
  showQuickEdit: false,
  tab: {},
  channel: {},
};

StoryItemNew.propTypes = {
  id: PropTypes.number,
  permId: PropTypes.number,
  name: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  colour: PropTypes.string.isRequired,
  commentCount: PropTypes.number,
  excerpt: PropTypes.string,
  fileCount: PropTypes.number,
  updated: PropTypes.number,
  isFeatured: PropTypes.bool,
  isProtected: PropTypes.bool,
  isSubscribed: PropTypes.bool,
  note: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rootUrl: PropTypes.string,
  grid: PropTypes.bool,
  isActive: PropTypes.bool,
  isSelected: PropTypes.bool,

  /** Pass true to render as HubShareConsole layout */
  isShare: PropTypes.bool,
  ratingCount: PropTypes.number,
  showCheckbox: PropTypes.bool,
  thumbSize: PropTypes.oneOf(['small', 'medium', 'large', 'custom']),

  /** custom thumb height + width */
  customThumbSize: PropTypes.number,
  showThumb: PropTypes.bool,
  showBadges: PropTypes.bool,
  showIcons: PropTypes.bool,
  showSubscribe: PropTypes.bool,
  showQuicklinkEdit: PropTypes.bool,
  showQuickEdit: PropTypes.bool,
  disabled: PropTypes.bool,

  /** do not render an enclosing anchor tag */
  noLink: PropTypes.bool,
  authString: PropTypes.string,
};

export default StoryItemNew;
