/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Yi Zhang <yi.zhang@bigtincan.com>
 */
import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import styles from './FileItem.less';

const SyncingIcon = () => <span className={styles.syncingIcon} />;
const ProcessingIcon = () => (
  <div className={styles.processingIcon}>
    <span className={styles.topGear} />
    <span className={styles.bottomGear} />
  </div>
);

const FileStatus = ({ type, isGrid, strings }) => {
  const cx = classNames.bind(styles);
  const classes = cx({ grid: isGrid }, styles.fileStatus);

  return (
    <div className={classes}>
      {type === 'syncing' ? <SyncingIcon /> : <ProcessingIcon />}
      <p>{strings[type]}...</p>
    </div>
  );
};

export default FileStatus;

FileStatus.defaultProps = {
  /* File Status types */
  type: PropTypes.oneOf(['syncing', 'processing']),

  /* File Status shows in Grid view or not */
  isGrid: PropTypes.bool,

  /* Translations  */
  strings: PropTypes.array,
};
