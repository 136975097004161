/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package Bigtincan Hub
 * @copyright 2010-2017 BigTinCan Mobile Pty Ltd
 * @author Shibu Bhattarai <shibu.bhattarai@bigtincan.com>
 */

import * as React from "react";
import autobind from "class-autobind";
import * as classNames from "classnames/bind";
import styles from "./Welcome.less";
export class Welcome extends React.Component<{}, {}> {

  constructor(props) {
    super(props);
    autobind(this);
  }
  public render() {
    const cx = classNames.bind(styles);
    const classes = cx({
      welcome: true,
      view: true,
    });

    const classes2 = cx({
      Loader: true,
      content: true,
    }, styles.loader);

    return (
      <div className={classes}>
        <div className={styles.center}>
          <div className={styles.logo} />
          <div className={classes2}>
            <svg className={styles.orange} viewBox="0 0 100 100">
              <circle cx="50%" cy="50%" r="51" opacity="0.65" />
            </svg>
            <svg className={styles.midGrey} viewBox="0 0 100 100">
              <circle cx="50%" cy="50%" r="48" opacity="0.65" />
            </svg>
            <svg className={styles.smallGrey} viewBox="0 0 100 100">
              <circle cx="50%" cy="50%" r="51" opacity="0.65" />
            </svg>
          </div>
        </div>
      </div>
    );
  }
}
