/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2016 BigTinCan Mobile Pty Ltd
 * @author Shibu Bhattarai <shibu.bhattarai@bigtincan.com>
 */

const superagent = require("superagent");

class ApiClient {

  private InternalCall(method: string, path: any, source?: string, options?: any): Promise<any> {
    const { params, data, progress, headers, companyIdentifier } = options;
    const globale: any = window;
    return new Promise((resolve, reject) => {
      let apiPath;
      if (source === "external") {
        apiPath = path;
      } else if (source === "btc-id") {
        apiPath = globale.BTC.BTCIDAPI + path;
      } else {
        apiPath = source === "webapi4" ? (globale.BTC.BTCAPI4 + path) : (globale.BTC.BTCAPI + path);
      }
      if (companyIdentifier) {
        const indentiferName = ["://", companyIdentifier + "."].join("");
        apiPath = apiPath.replace("://", indentiferName);
      }
      // Our superagent request
      const request = superagent[method](apiPath);

      // Append access_token to all requests except signin
      if (source === "external") {
        if (headers && headers.access_token) {
          request.set({ Authorization: "Bearer " + headers.access_token });
        }
      } else {
        if (apiPath.indexOf("signin") < 0 && localStorage.getItem("BTCTK_A")) {
          request.set({ Authorization: "Bearer " + localStorage.getItem("BTCTK_A") });
        }
      }

      // Attach uploadData to request
      if (params && params.uploadData) {
        const attrName = Object.keys(params.uploadData)[0];
        params.uploadData[attrName].forEach((f) => {
          request.attach(attrName, f);
        });
        delete params.uploadData;  // eslint-disable-line

        // Progress event
        if (progress) {
          request.on("progress", progress);
        }
      }

      // Request payload
      if (params) {
        request.query(params);
      }

      // Send as form data
      if (data) {
        if (source === "external" || source === "internal-as-json") {
          request.send(data);
        } else {
          if (path === "/hubshare-link") {
            request.type("application/json").send(data); // send data as application/json if hubshare
          } else {
            request.type("form").send(data);
          }
        }
      }
      request.end((err: any, res: { statusCode: any; status: any; body: any; }) => {
        const statusCode = res.status || res.status;
        if (res && statusCode === 200) {
          const body = res.body;
          resolve(body);
        } else {
          reject(err);
        }
      });
    });
  }
  public get(path, source?: string, options?: any): Promise<any> {
    return this.InternalCall("get", path, source, options);
  }
  public post(path, source?: string, options?: any): Promise<any> {
    return this.InternalCall("post", path, source, options);
  }
  public patch(path, source?: string, options?: any): Promise<any> {
    return this.InternalCall("patch", path, source, options);
  }
  public put(path, source?: string, options?: any): Promise<any> {
    return this.InternalCall("put", path, source, options);
  }
}
const apiClient = new ApiClient();
export default apiClient;
