/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package Bigtincan Hub
 * @copyright 2010-2017 BigTinCan Mobile Pty Ltd
 * @author Shibu Bhattarai <shibu.bhattarai@bigtincan.com>
 */

import * as React from "react";
import { useEffect, useState } from "react";
import * as classNames from "classnames/bind";
import { RouteComponentProps } from "react-router";
import hubServiceClient from "../../service/HubService";
import outlookClient from "../../service/OutlookClient";
import Popover from "react-simple-popover";
import { crmDetails } from "../../helpers/crm";
import Cookies from "universal-cookie";
import authenticationServiceClient from "../../service/AuthneticationService";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Checkbox from "components/Checkbox/Checkbox";
import Select from "components/Select/Select";
import styles from "./Index.less";
import Text from "components/Text/Text";
import FileItem from "components/FileItem/FileItem";
import Btn from "components/Btn/Btn";
import DateTimePicker from "components/DateTimePicker/DateTimePicker";
import { SalesforceBlock } from "../Salesforce/Index";
import { DynamicsBlock } from "../Dynamics/Index";
import { getLocalStorageItem } from "../../helpers/handleLocalstorage";
import Icon from "components/Icon/Icon";
import { Tooltip } from "react-tooltip";

const moment = require("moment-timezone");

enum ViewMode {
    Home = 1,
}

interface IHomeProps {
    leadFormList: any[];
}

type Props = RouteComponentProps & IHomeProps;

const Home = ({ history }: Props) => {
    const popUpTarget = React.useRef<any>(null);
    const shareLinkData = getLocalStorageItem("shareLinkData", {}, true);
    const [lockedFile, setLockedFile] = useState<any>([]);
    const [emailMode, setEmailMode] = useState(1);
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [message, setMessage] = useState("");
    const [viewMode, setViewMode] = useState(ViewMode.Home);
    const [showPopover, setShowPopover] = useState(false);
    const [includeAccessForm, setIncludeAccessForm] = useState(shareLinkData.includeAccessForm || false);
    const [expireLink, setExpireLink] = useState(shareLinkData.expireLink);
    const [showInfo, setShowInfo] = useState(false);
    const [linkTitle, setLinkTitle] = useState(shareLinkData.linkTitle || "");
    const [fileCollectionArray, setFileCollectionArray] = useState([]);
    const [daysToExpiry, setDaysToExpiry] = useState(() => getLocalStorageItem("daysToExpiry", ""));
    const [includeAccessFormSetting, setIncludeAccessFormSetting] = useState(() => getLocalStorageItem("includeAccessFormSetting", ""));
    const [canEditExpiryLink, setCanEditExpiryLink] = useState(() => getLocalStorageItem("canEditExpiryLink", false) === "true");
    const [canCreateHubShareLink, setCanCreateHubShareLink] = useState(() => getLocalStorageItem("canCreateHubShareLink", false) === "true");
    const [crmEnabled, setCrmEnabled] = useState(false);
    const [crmSource, setCrmSource] = useState("salesforce");
    const [defaultLinkExpirationDate, setDefaultLinkExpirationDate] = useState(() => getLocalStorageItem("defaultExpirationDate", "0"));
    const [includeAccessFormUpdated, setIncludeAccessFormUpdated] = useState(() => getLocalStorageItem("includeAccessFormUpdated", false) === "true");
    const [displayExpireLinkCheckbox, setDisplayExpireLinkCheckbox] = useState(() => getLocalStorageItem("canEditExpiryLink", false) === "true");
    const [formTemplatesEnabled, setFormTemplatesEnabled] = useState(() => getLocalStorageItem("formTemplatesEnabled", false) === "true");
    const [selectedLeadFormId, setSelectedLeadFormId] = useState(() => Number(getLocalStorageItem("selectedLeadFormId", 1, false)));
    const [downloadSharedFilesAsPDF, setDownloadSharedFilesAsPDF] = useState(shareLinkData.downloadSharedFilesAsPDF || false);
    const [downloadSharedFilesAsPDFDefault, setDownloadSharedFilesAsPDFDefault] = useState(() => getLocalStorageItem("downloadSharedFilesAsPDFDefault", false) === "true");
    const [downloadSharedFilesAsPDFUpdated, setDownloadSharedFilesAsPDFUpdated] = useState(() => getLocalStorageItem("downloadSharedFilesAsPDFUpdated", false) === "true");
    const [displayDownloadCheckbox, setDisplayDownloadCheckbox] = useState(() => getLocalStorageItem("displayDownloadCheckbox", false) === "true");
    const [isCompanySettingsInitialized, setIsCompanySettingsInitialized] = useState(false);
    const [leadFormList, setLeadFormList] = useState([]);

    if (!shareLinkData.linkTitle) {
        outlookClient.getEmailBody().then((item: any) => {
            setLinkTitle(item.composeSubject);
        });
    }

    useEffect(() => {
        async function fetchData() {
            const tokens = await authenticationServiceClient.getTokens();
            if (!tokens.BTCTK_R) {

                /* Check if company settings have already been initialized, if yes then no need to fetch them again */
                if (!isCompanySettingsInitialized) {
                    crmDetails.initCompanySettings().then(() => {
                        setIsCompanySettingsInitialized(true);
                        initBody();
                    }).catch(() => {
                        initBody();
                    });
                } else {
                    initBody();
                }
            }
            initCRMSettings();
        }
        fetchData();
    }, [isCompanySettingsInitialized]);

    function initCRMSettings() {
        if (!isCompanySettingsInitialized) {
            crmDetails.initCompanySettings().then(() => {
                setIsCompanySettingsInitialized(true);
                fetchCRMSettings();
            });
        } else {
            fetchCRMSettings();
        }
    }

    /* Function to fetch and set settings from the setting endpoint */
    function fetchCRMSettings() {
        const user = crmDetails.user;
        const company = crmDetails.company;
        const { userCapabilities, sharing } = crmDetails;
        const { hasTemplates } = userCapabilities;
        const formTemplatesEnabledHub = hasTemplates.isWidgetEnabled && hasTemplates?.children?.has_form_template_dashboard;
        const includeAccessFormSettingHub = userCapabilities.canCreateHubshareLink?.children?.lead_form || userCapabilities.canCreateHubshareLink?.children?.link_lead_form;
        const expireLinkSettingHub = userCapabilities.canCreateHubshareLink?.children?.can_edit_link_expiry_date;
        const downloadSharedFilesAsPDFSettingHub = userCapabilities.canCreateHubshareLink?.children?.download_shared_files_as_pdf;
        const canCreateHubShareLinkHub = userCapabilities.canCreateHubshareLink?.isEnabled;
        const updatedShareLinkData = getLocalStorageItem("shareLinkData", {}, true);

        localStorage.setItem("displayDownloadCheckbox", downloadSharedFilesAsPDFSettingHub.toString());
        localStorage.setItem("canEditExpiryLink", expireLinkSettingHub.toString());
        localStorage.setItem("formTemplatesEnabled", formTemplatesEnabledHub);
        localStorage.setItem("includeAccessFormSetting", includeAccessFormSettingHub);
        localStorage.setItem("canCreateHubShareLink", canCreateHubShareLinkHub);

        /* Calling the identity discovery endpoint to get user and tenant details */
        hubServiceClient.getUserProfileFromIdentity().then((data: any) => {
            const userUrn = data.userUrn;
            const customerUrn = data.customerUrn;

            /* Initializing Pendo when the user logs in */
            (window as any).pendo.initialize({
                visitor: {
                    id: userUrn,
                    role: user.roleId < 100 ? user.role : "Custom Admin Role",
                    timezone: user.tz,
                    language: user.langCode,
                },
                account: {
                    id: customerUrn,
                    name: company.name,
                    app: "OA",
                },
            });
        });

        setCanCreateHubShareLink(canCreateHubShareLinkHub);
        setCrmEnabled(crmDetails.crmEnabled);
        setCrmSource(crmDetails.source);
        setFormTemplatesEnabled(formTemplatesEnabledHub);
        setIncludeAccessFormSetting(includeAccessFormSettingHub);
        setDisplayDownloadCheckbox(downloadSharedFilesAsPDFSettingHub);
        setCanEditExpiryLink(expireLinkSettingHub);

        if (includeAccessFormSettingHub === "mandatory") {
            setIncludeAccessForm(true);
            updatedShareLinkData.includeAccessForm = true;
        }

        if (sharing) {
            const addLeadFormDefaultHub = sharing.addLeadFormDefault || sharing.addLeadFormLinkDefault;
            const defaultLinkExpirationDateHub = sharing.defaultLinkExpiryDuration;
            const downloadSharedFilesAsPDFDefaultHub = sharing.defaultDownloadSharedFilesAsPdf === 1;

            if (!includeAccessFormUpdated && addLeadFormDefaultHub) {
                setIncludeAccessForm(addLeadFormDefaultHub);
                updatedShareLinkData.includeAccessForm = addLeadFormDefaultHub;
            }

            if (!downloadSharedFilesAsPDFUpdated && downloadSharedFilesAsPDFDefaultHub && downloadSharedFilesAsPDFSettingHub) {
                setDownloadSharedFilesAsPDF(downloadSharedFilesAsPDFDefaultHub);
                updatedShareLinkData.downloadSharedFilesAsPDF = downloadSharedFilesAsPDFDefaultHub;
            }

            if (defaultLinkExpirationDateHub > 0) {
                localStorage.setItem("defaultExpirationDate", defaultLinkExpirationDateHub);
                setDefaultLinkExpirationDate(defaultLinkExpirationDateHub);

                /* To check if the user has updated expire link checkbox already */
                if (expireLink === undefined) {
                    setExpireLink(true);
                    updatedShareLinkData.expireLink = true;
                }
            }
        }

        localStorage.setItem("shareLinkData", JSON.stringify(updatedShareLinkData));

        if (formTemplatesEnabledHub && canCreateHubShareLinkHub) {
            hubServiceClient.getLeadFormList().then((data) => {
                setLeadFormList(data);
                data.forEach((row: any) => {
                    if (row.isDefault && selectedLeadFormId === 1) {
                        setSelectedLeadFormId(row.id);
                        localStorage.setItem("selectedLeadFormId", row.id);
                    }
                });
            });
        }
    }

    const onLogOut = () => {
        localStorage.clear();
        history.push("/");
        try {
            const cookies = new Cookies();
            cookies.remove("BTCTK_A", { path: "/" });
            cookies.remove("BTCTK_R", { path: "/" });
            cookies.remove("expires_in", { path: "/" });
            // tslint:disable-next-line:no-empty
        } catch {
        }
    };

    const handleClose = () => {
        setShowPopover(false);
    };

    const onSettingClick = () => {
        setShowPopover(true);
    };

    const onOpportunityItemSelected = (item: any) => {
        crmDetails.setCurrentSelectedOpportunity(item);
    };

    const onFileSearchClick = () => {
        history.push("/filerecommended");
    };

    const getRowTemplate = (file: any, data: any, canCreateHubshareLink?: boolean) => {
        let symbol = "";
        let symbolColor = "";
        switch (file.category) {
            case "pdf":
                symbol = "PDF";
                symbolColor = "#f01300";
                break;
            case "powerpoint":
                symbol = "PPT";
                symbolColor = "#f26724";
                break;
            case "excel":
                symbol = "Excel";
                symbolColor = "#00ff00";
                break;
            case "keynote":
                symbol = "Key";
                symbolColor = "#1baff9";
                break;
            case "image":
                symbol = "Image";
                symbolColor = "#9052e3";
                break;
            case "app":
                symbol = "BTCA";
                symbolColor = "#f26726";
                break;
            default:
                symbol = file.category;
                symbolColor = "#9052e3";
        }

        if (canCreateHubshareLink) {
            return getTemplateRowData(data.shareableLink, file, symbolColor, symbol, canCreateHubshareLink);
        } else {
            return getTemplateRowData(data.landingURL, file, symbolColor, symbol);
        }
    };

    const getTemplateRowData = (fileURL, { name, id }, symbolColor, symbol, canCreateHubshareLink?) => {
        const URL = canCreateHubshareLink ? fileURL : `${fileURL}/f/${id}`;
        return "<tr>" +
            "<td>" +
            "<div style='width: 250px; min-height: 30px;background-color: #f5f5f5;padding: 10px;'>" +
            "<table style='width: 100%; height: 100%; display: block;'>" +
            "<tr>" +
            "<td style='width: 18px;height: 18px;display: inline-block;'>" +
            "<div style='background-color: " + symbolColor + "; height: 100%;width: 100%; font-size: 6px;text-align: center;line-height: 18px; color:white; border-radius: 3px;'>" +
            symbol +
            "</div>" +
            "</td>" +
            " <td style='width: 179px;display: inline-block; padding-left: 5px; height: 100%!important; font-size: 13px;line-height: 1.16rem;letter-spacing: 0.1px;text-align: left;color: #222222;'>" +
            "<div style='height: 100%;width: 100%; font-size: 13px;line-height: 1.46;letter-spacing: 0.1px;text-align: left;color: #222222;'>" +
            "<a style='text-decoration: none;' href='" + URL + "'>" +
            name +
            "</a>" +

            "</div>" +
            "</td>" +
            "</tr>" +
            "</table>" +
            "</div>" +
            "<td>" +
            "</tr>";
    };

    const initBody = () => {
        outlookClient.getEmailBody().then((item: any) => {
            setEmailMode(item.emailMode);
        }).catch(() => {
            setEmailMode(2);
        });
    };

    const getHomeView = () => {

        const [state, setState] = useState({
            crmEnabled: false,
            crmSource: "",
            canCreateHubShareLink: false,
            showInfo: false,
            showError: false,
            linkTitle: "",
            includeAccessForm: false,
            expireLink: false,
            downloadSharedFilesAsPDF: false,
            hasError: false,
            daysToExpiry: 0,
            selectedLeadFormId: "",
            defaultLinkExpirationDate: null,
        });

        useEffect(() => {
            const storedShareLinkData = getLocalStorageItem("shareLinkData", "{}", true);
            setState((prevState) => ({
                ...prevState,
                ...storedShareLinkData,
            }));
        }, []);

        const cx = classNames.bind(styles);
        const classes = cx({}, styles.container);
        const footerClasses = cx({
            showInfo: state.showInfo,
            showError: state.showError,
        }, styles.footer);

        const handleLinkShareDataChange = (event: any) => {
            const storedShareLinkData = getLocalStorageItem("shareLinkData", "{}", true);
            let { includeAccessForm, expireLink, downloadSharedFilesAsPDF } = storedShareLinkData;

            if (event.target.name === "includeAccessForm") {
                includeAccessForm = includeAccessFormSetting === "mandatory" ? true : event.target.checked;
                setIncludeAccessForm(includeAccessForm);
                setIncludeAccessFormUpdated(true);
                localStorage.setItem("includeAccessFormUpdated", "true");
            } else if (event.target.name === "expireLink") {
                expireLink = event.target.checked;
                setExpireLink(expireLink);
            } else if (event.target.name === "downloadSharedFilesAsPdf") {
                downloadSharedFilesAsPDF = event.target.checked;
                setDownloadSharedFilesAsPDF(downloadSharedFilesAsPDF);
                setDownloadSharedFilesAsPDFUpdated(true);
                localStorage.setItem("downloadSharedFilesAsPDFUpdated", "true");
            }

            const updatedData = {
                includeAccessForm,
                expireLink,
                linkTitle: shareLinkData.linkTitle || "",
                downloadSharedFilesAsPDF,
            };
            localStorage.setItem("shareLinkData", JSON.stringify(updatedData));
        };


        const handleDateToDaysChange = (timestamp) => {
            if (timestamp) {
                // User has selected a date
                const start = moment(new Date().setHours(0, 0, 0, 0));
                const end = moment(new Date(timestamp).setHours(24, 0, 0, 0));
                const expireDays = moment.duration(end.diff(start)).asDays() - 1 as any;

                setDaysToExpiry(expireDays);
                localStorage.setItem("daysToExpiry", expireDays);
            }
        };

        const handleTitleValueChange = (event) => {
            const linkTitleValue = event.target.value || "";
            setLinkTitle(linkTitleValue);
            const shareLinkData = getLocalStorageItem("shareLinkData", {}, true);
            shareLinkData.linkTitle = linkTitleValue;
            localStorage.setItem("shareLinkData", JSON.stringify(shareLinkData));
        };

        const fileCollection = getLocalStorageItem("fileCollection", "");
        const fileCollectionArray = fileCollection ? JSON.parse(fileCollection) : null;
        const expireDays = Number(daysToExpiry) > 0 ? daysToExpiry : Number(defaultLinkExpirationDate);

        const handleRemoveFileClick = (context: any) => {
            const fileId = context.id;
            const updatedFileCollectionArray = fileCollectionArray.filter((file: any) => file.id !== fileId);
            localStorage.setItem("fileCollection", JSON.stringify(updatedFileCollectionArray));
            setFileCollectionArray(updatedFileCollectionArray);
        };

        const onRemoveAllButtonClick = () => {
            const updatedFileCollectionArray = [];
            localStorage.setItem("fileCollection", JSON.stringify(updatedFileCollectionArray));
            setFileCollectionArray(updatedFileCollectionArray);
        };

        const onAddClick = () => {
            const proxyCollection: any[] = [];
            const opportunity = crmDetails.selectedOpportunity;
            const logToCrm = crmDetails.logToCrm;

            if (canCreateHubShareLink) { // Check if the user can create hubShare links
                if (!linkTitle) {
                    setShowError(true);
                    setHasError(true);
                    setError("Title required");
                    setTimeout(() => {
                        setError(" ");
                        setShowError(false);
                        setHasError(false);
                    }, 3000);
                    return;
                }

                let daysToExpiryValue: string | null = daysToExpiry;
                if (!expireLink) {
                    daysToExpiryValue = null;
                } else {
                    if (!daysToExpiryValue) {
                        daysToExpiryValue = defaultLinkExpirationDate ? defaultLinkExpirationDate : null;
                    }
                }

                proxyCollection.push(hubServiceClient.sendShare({
                    leadFormId: selectedLeadFormId,
                    canCreateHubShareLink,
                    files: fileCollectionArray,
                    opportunity,
                    logToCrm,
                    crmSource,
                    subject: linkTitle,
                    daysToExpiry: daysToExpiryValue,
                    includeAccessForm,
                    downloadSharedFilesAsPDF,
                }));

                Promise.all(proxyCollection).then((data) => {
                    let templateHtml = " <table style='table-layout:fixed;'>";
                    data.forEach((resultItem: any) => {
                        const files = resultItem.files;
                        files.forEach((fileId) => {
                            const fileData = fileCollectionArray.find((file) => file.id === fileId);
                            if (fileData) {
                                fileData.isLocked = true;
                                lockedFile.push(fileData.id);
                                const temRow = getRowTemplate(fileData, resultItem.result, canCreateHubShareLink);
                                templateHtml += temRow;
                            }
                        });
                    });

                    templateHtml += "</table>";
                    window.cachesOppData = [];
                    crmDetails.setCurrentSelectedOpportunity(null);
                    const mailItem = window.Office.context.mailbox.item;
                    const fContent = "<p>&nbsp;</p>" + templateHtml;
                    mailItem.body.setSelectedDataAsync(fContent, { coercionType: "html" });
                    setShowInfo(true);
                    setMessage("File(s) attached");
                    setTimeout(() => {
                        setMessage(" ");
                        setShowInfo(false);
                    }, 5000);
                    localStorage.removeItem("fileCollection");
                    localStorage.removeItem("daysToExpiry");
                    localStorage.removeItem("selectedOpportunity");
                    localStorage.removeItem("addLeadFormDefault");
                    localStorage.removeItem("includeAccessFormUpdated");
                    localStorage.removeItem("defaultExpirationDate");
                    localStorage.removeItem("selectedLeadFormId");
                    localStorage.removeItem("downloadSharedFilesAsPDFUpdated");
                });
            } else {

                // If the user is not allowed to create hubShare links, default to Email share
                outlookClient.getEmailBody().then((item: any) => {
                    item.to.getAsync((rawMailContacts) => {
                        const emails = rawMailContacts.value.map((contact) => (contact && contact.emailAddress));
                        if (emails.length === 0) {
                            setShowError(true);
                            setHasError(true);
                            setError("Email address required.");
                            setTimeout(() => {
                                setError(" ");
                                setShowError(false);
                                setHasError(false);
                            }, 3000);
                            return;
                        }
                        if (!linkTitle) {
                            setShowError(true);
                            setHasError(true);
                            setError("Title required");
                            setTimeout(() => {
                                setError(" ");
                                setShowError(false);
                                setHasError(false);
                            }, 3000);
                            return;
                        }

                        proxyCollection.push(hubServiceClient.sendShare({
                            emails,
                            files: fileCollectionArray,
                            opportunity,
                            logToCrm,
                            subject: linkTitle,
                        }));

                        Promise.all(proxyCollection).then((data) => {
                            let templateHtml = " <table style='table-layout:fixed;'>";
                            data.forEach((resultItem: any) => {
                                const files = resultItem.files;
                                files.forEach((fileId) => {
                                    const fileData = fileCollectionArray.find((file) => file.id === fileId);
                                    if (fileData) {
                                        fileData.isLocked = true;
                                        lockedFile.push(fileData.id);
                                        const temRow = getRowTemplate(fileData, resultItem.result);
                                        templateHtml += temRow;
                                    }
                                });
                            });
                            window.cachesOppData = [];
                            crmDetails.setCurrentSelectedOpportunity(null);
                            templateHtml += "</table>";
                            const mailItem = window.Office.context.mailbox.item;
                            const fContent = "<p>&nbsp;</p>" + templateHtml;
                            mailItem.body.setSelectedDataAsync(fContent, { coercionType: "html" });
                            setShowInfo(true);
                            setMessage("File(s) attached");
                            setTimeout(() => {
                                setMessage(" ");
                                setShowInfo(false);
                            }, 5000);
                            localStorage.removeItem("fileCollection");
                            localStorage.removeItem("selectedOpportunity");
                            localStorage.removeItem("addLeadFormDefault");
                            localStorage.removeItem("defaultExpirationDate");
                        });
                    });
                });
            }
        };

        const handleLeadFormSelectChange = (selectedLeadForm: any) => {
            if (selectedLeadForm) {
                const leadFormId = selectedLeadForm.id;
                setSelectedLeadFormId(leadFormId);
                localStorage.setItem("selectedLeadFormId", leadFormId);
            }
        };

        return (
            <div className={styles.home} >
                <div className={classes}>
                    <div className={styles.header}>
                        <div className={styles.title}>Select an action</div>
                        <div
                            className={styles.setting}
                            ref={popUpTarget}
                            onClick={onSettingClick}
                        />
                        {showPopover && (
                            <Popover
                                placement="bottom"
                                container={popUpTarget.current}
                                target={popUpTarget.current}
                                show={showPopover}
                                style={{ marginLeft: "110px", marginTop: "10px", zIndex: 10000 }}
                                onHide={handleClose}
                            >
                                <a className="btnlogout" onClick={onLogOut} href="#">
                                    Log out
                                </a>
                            </Popover>
                        )}
                    </div>
                    <div className={styles.home_file_block}>
                        <div className={styles.formContainer}>
                            <h3 className={styles.title}>
                                Title <span className={styles.mandatory}>*</span>
                            </h3>
                            <Text
                                id="linkTitle"
                                name="linkTitle"
                                value={linkTitle}
                                style={{ width: "100%" }}
                                dir="auto"
                                onChange={handleTitleValueChange}
                                placeholder="Enter a title"
                            />
                            {canCreateHubShareLink &&
                                <>
                                    <>
                                        <Checkbox
                                            name="expireLink"
                                            label="Expire link"
                                            onChange={handleLinkShareDataChange}
                                            className={styles.checkboxes}
                                            checked={expireLink}
                                            disabled={!canEditExpiryLink}
                                        />
                                        <TransitionGroup className={styles.expireDaysContainer}>
                                            {expireLink && (
                                                <CSSTransition
                                                    transitionName="fade"
                                                    classNames="fade"
                                                    timeout={150}
                                                    appear={true}
                                                >
                                                    <DateTimePicker
                                                        id="expireDays"
                                                        name="expireDays"
                                                        placeholder="Select Date..."
                                                        datetime={moment(new Date())
                                                            .add(expireDays, "days")
                                                            .unix() * 1000}
                                                        min={new Date()}
                                                        format="YYYY-MM-DD"
                                                        showDate={true}
                                                        showTime={false}
                                                        showTz={false}
                                                        onChange={handleDateToDaysChange}
                                                        className={styles.expireDaysInput}
                                                        disabled={!canEditExpiryLink}
                                                    />
                                                </CSSTransition>
                                            )}
                                        </TransitionGroup>
                                    </>
                                    {includeAccessFormSetting === "optional" || includeAccessFormSetting === "mandatory" ? (
                                        <>
                                            <Checkbox
                                                name="includeAccessForm"
                                                label="Include access form"
                                                onChange={handleLinkShareDataChange}
                                                checked={includeAccessFormSetting === "mandatory" || includeAccessForm}
                                                className={styles.checkboxes}
                                                disabled={includeAccessFormSetting === "mandatory" ? true : false}
                                            >
                                                <Icon
                                                    className={styles.tooltipIcon}
                                                    name="info"
                                                    data-tooltip-id="access-form-tooltip"
                                                    data-tooltip-content=" An access form requires users to enter Name and Email, with optional fields for Title, Company and Phone Number."
                                                />
                                                <Tooltip id="access-form-tooltip" place="top" effect="solid" style={{ width: "150px", zIndex: "1" }} />
                                            </Checkbox>
                                            {formTemplatesEnabled && includeAccessForm &&
                                                <Select
                                                    id={"select-access-form"}
                                                    className={styles.selectFormContainer}
                                                    options={leadFormList}
                                                    value={selectedLeadFormId}
                                                    placeholder={"Select access form"}
                                                    onChange={handleLeadFormSelectChange}
                                                    searchable={leadFormList.length > 1}
                                                />
                                            }
                                        </>
                                    ) : null}
                                    {displayDownloadCheckbox &&
                                        <>
                                            <Checkbox
                                                name="downloadSharedFilesAsPdf"
                                                label="Download shared files as PDF"
                                                checked={downloadSharedFilesAsPDF}
                                                onChange={handleLinkShareDataChange}
                                                className={styles.checkboxes}
                                            >
                                                <Icon
                                                    className={styles.tooltipIcon}
                                                    name="info"
                                                    data-tooltip-id="tooltip"
                                                    data-tooltip-content="Supported file types such as Docs, PowerPoints, and Spreadsheets will be downloaded as PDFs"
                                                />
                                                <Tooltip id="tooltip" place="top" effect="solid" style={{ width: "150px", zIndex: "1" }} />
                                            </Checkbox>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                    <div className={styles.attachedFilesHeader}>
                        <div className={styles.attachedFilesTitle}>
                            <h3>Files</h3>
                            <div className={styles.filesCounter}>{fileCollectionArray ? fileCollectionArray.length : 0}</div>
                        </div>
                        <div className={styles.action}>
                            <Btn
                                inverted={true}
                                onClick={onFileSearchClick}
                                className={styles.addFilesBtn}
                            >
                                Add Files
                            </Btn>
                        </div>
                        <div className={styles.action}>
                            <Btn className={styles.removeAllBtn} onClick={onRemoveAllButtonClick} inverted={false}>Remove All</Btn>
                        </div>
                    </div>
                    <div className={styles.fileListContainer}>
                        <div className={styles.fileList}>
                            {fileCollectionArray && fileCollectionArray.length > 0 ? (
                                fileCollectionArray.map((file: any, index: any) => {
                                    return (
                                        <FileItem
                                            key={file.id}
                                            category={file.category}
                                            className={styles.fileItem}
                                            description={file.name}
                                            showRemoveButton={true}
                                            handleRemoveButtonOnClick={() => handleRemoveFileClick(file)}
                                            hideMeta={true}
                                            thumbSize="small"
                                            {...file}
                                        />
                                    );
                                })
                            ) : (
                                <p className={styles.noFilesMessage}>No file(s) selected</p>
                            )}
                        </div>
                    </div>

                    {crmEnabled && (
                        crmSource === "salesforce" ? (
                            <SalesforceBlock
                                crmSource={crmSource}
                                onOpportunityItemSelected={onOpportunityItemSelected}
                            />
                        ) : crmSource === "dynamics" ? (
                            <DynamicsBlock
                                crmSource={crmSource}
                                onOpportunityItemSelected={onOpportunityItemSelected}
                            />
                        ) : null
                    )}

                    <div className={footerClasses}>
                        {!showInfo && !showError &&
                            <div className={styles.action}>
                                <Btn
                                    className={styles.attchbtn}
                                    inverted={true}
                                    large={true}
                                    onClick={onAddClick}
                                    disabled={!fileCollectionArray?.length}
                                >
                                    Attach
                                </Btn>
                            </div>
                        }
                        {showInfo &&
                            <div className={styles.oppCmdContainer}>
                                <div className={styles.title}>File(s) attached</div>
                            </div>
                        }
                        {hasError &&
                            <>
                                <div className={styles.oppCmdContainer}>
                                    <div className={styles.title}>{error}</div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div >
        );
    };

    return getHomeView();
};

export default Home;
