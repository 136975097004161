/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package Bigtincan Hub
 * @copyright 2010-2020 BigTinCan Mobile Pty Ltd
 * @author Shibu Bhattarai <shibu.bhattarai@bigtincan.com>
 * @author Nimesh Sherpa <nimesh.sherpa@bigtincan.com>
 */

/* tslint:disable */

import * as React from "react";
import * as classNames from "classnames/bind";
import Btn from "components/Btn/Btn";
import { RouteComponentProps } from "react-router";
import hubServiceClient from "../../service/HubService";
import { RecommendedFileItem } from "../../components/RecommendedFileItem/Index";
import outlookClient from "../../service/OutlookClient";
import { retextExtract } from "../../helpers/extractWords";
import Popover from "react-simple-popover";
import styles from "./Index.less";
import { SearchFilterBlock } from "../../components/SearchFilter/index";
import Cookies from "universal-cookie";
import List from "components/List/List";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import moment = require("moment");
import Checkbox from "components/Checkbox/Checkbox";
import { crmDetails } from "../../helpers/crm";

interface ISignInOptionProps {
  version: string;
}

type AppProps = RouteComponentProps & ISignInOptionProps;

export interface IFileRecommendedState {
  onsearchfocus: boolean;
  activeSearch: boolean;
  files: any[];
  recommendedFiles: any[];
  error: string;
  hasError: boolean;
  isLoading: boolean;
  searchTerm: string;
  message: string;
  hideAttachedBtn: boolean;
  emailMode: number;
  showPopover: boolean;
  showInfo: boolean;
  viewTitle: string;
  // browseViewTitle: string;
  includeAccessForm: false;
  expireLink: false;
  linkExpirationDate: null;
  selectedDate: Date;
  defaultTextValue: string;
  autosize: boolean;
  isRecommendations: boolean;
  showFilters: boolean;
  selectedFilters: any[];
  dateFrom: any;
  dateTo: any;
  shareOption: string;
  paths: any[];
  searchPaths: any[];
  tabs: any[];
  channels: any[];
  stories: any[];
  browseFiles: any[];
  addedFiles: any[];
  browseIsLoading: boolean;
  tabSelected: boolean;
  channelSelected: boolean;
  storySelected: boolean;
  isFileSelected: boolean;
  browseFilesLoaded: boolean;
  searchResultsVisible: boolean;
  selectedFiles: any[];
  selectAllChecked: boolean;
  selectAllIndeterminate: boolean;
  fileCount: number;
  namingSettings: any;
}
export class FileRecommended extends React.Component<AppProps, IFileRecommendedState> {
  public searchTimeout: number;
  // private lockedFile: any[];
  public sendEvent: any;
  private popUpTarget: any;
  private currentRequest: number;
  constructor(props) {
    super(props);
    // this.lockedFile = [];
    this.state = {
      onsearchfocus: false,
      activeSearch: false,
      viewTitle: "",
      // browseViewTitle: "",
      showInfo: false,
      hasError: false,
      error: "",
      showPopover: false,
      files: [],
      recommendedFiles: [],
      searchTerm: "",
      isLoading: true,
      message: "",
      hideAttachedBtn: true,
      emailMode: 1,
      includeAccessForm: false,
      expireLink: false,
      linkExpirationDate: null,
      selectedDate: new Date(),
      defaultTextValue: "",
      autosize: true,
      isRecommendations: false,
      showFilters: false,
      selectedFilters: [],
      dateTo: null,
      dateFrom: null,
      searchResultsVisible: false,
      shareOption: "recommendations",
      selectAllChecked: false,
      paths: [
        {
          name: "Content",
          path: ""
        },
      ],
      searchPaths: [
        {
          name: "Back to content",
          path: ""
        },
      ],
      tabs: [],
      channels: [],
      stories: [],
      browseFiles: [],
      addedFiles: [],
      browseIsLoading: true,
      tabSelected: false,
      channelSelected: false,
      storySelected: false,
      isFileSelected: false,
      selectedFiles: [],
      browseFilesLoaded: true,
      selectAllIndeterminate: false,
      fileCount: 0,
      namingSettings: null,
    };
  }

  async componentDidMount() {
    await this.initializeEmailMode();
    await this.loadTabsAndSettings();
    this.loadAddedFiles();
  }

  /* Function to set email Mode and initiate search to get recommended content
  Email Mode is passed as 2 if the user is composing an email and is passed as 1 if the user is viewing an email */
  async initializeEmailMode() {
    const email = await outlookClient.getEmailBody() as any;
    const subject = email.emailMode === 2 ? await new Promise(resolve => email.subject.getAsync(result => resolve(result.value))) : email.subject;
    const searchTerm = `${subject} ${email.bodyText}`;

    if (searchTerm) {
      retextExtract(searchTerm).then((tokenTerm: string) => {
        this.searchFiles(tokenTerm, true);
      });
    }

    this.setState({
      emailMode: email.emailMode,
    });
  }

  async loadTabsAndSettings() {
    const tabs = await hubServiceClient.getTabs();
    this.setupTabsAndSettings(tabs, crmDetails.naming);
  }

  setupTabsAndSettings(tabs, namingSettings) {
    const tabsArray = tabs.map(tab => ({
      id: tab.id,
      name: tab.name,
      type: tab.type,
      colour: tab.colour,
      thumbnail: tab.thumbnail,
      note: tab.childCount > 1 ? `${tab.childCount} ${namingSettings.channels}` : `${tab.childCount} ${namingSettings.channel}`
    }));

    const myContentIndex = tabsArray.findIndex(tab => tab.name === "My Content");
    if (myContentIndex !== -1) {
      const myContentTab = tabsArray.splice(myContentIndex, 1)[0];
      tabsArray.unshift(myContentTab);
    }

    this.setState({
      tabs: tabsArray,
      browseIsLoading: false,
      namingSettings
    });
  }

  loadAddedFiles() {
    const addedFiles = JSON.parse(localStorage.getItem("fileCollection") || "[]");
    this.setState({ addedFiles });
  }

  public getKeywords(term: string) {
    return term;
    /*
    const allKeyWords = term.split(" ");
    const keyCollection: string[] = [];
    allKeyWords.forEach((key) => {
      keyCollection.push("[text::" + key + "]");
    });
    return "[" + keyCollection.join(" OR ") + "]";
    */
  }

  /* Function to search for files, isRecommendations is passed as true when the function is being invoked to display recommendations */
  private searchFiles(term: string, isRecommendations: boolean, filters?: any) {
    if (!term) {
      this.setState({ isLoading: false });
      return;
    }

    this.currentRequest = new Date().getTime();
    this.setState({
      searchTerm: term,
      hasError: false,
      isLoading: true,
      error: "",
      viewTitle: this.state.viewTitle || "",
      isRecommendations: isRecommendations
    });

    const modifiedTerm = isRecommendations ? term.split(" ").join(" || ") : term;
    const errorMessage = isRecommendations ? "Failed to get recommendations. Please try again." : "Failed to get search results. Please try again.";

    hubServiceClient.getRecommendedContent(modifiedTerm, this.currentRequest, filters).then((data) => {
      this.handleSearchResponse(data, isRecommendations, errorMessage)
    }).catch(() => {
      this.handleSearchError(errorMessage)
    });
  }

  private handleSearchResponse(data: any, isRecommendations: boolean, errorMessage: string) {
    if (data.requestId === this.currentRequest) {

      const results = data.data.filter((resultItem: any) => resultItem.shareStatus !== "blocked");
      this.updateSelectedFilesState(results);

      if (!this.state.searchTerm) {
        this.setState({
          files: [],
          recommendedFiles: [],
          isLoading: false
        });
        return;
      }

      this.updateResultsState(results, isRecommendations);
    } else { return }
  }

  private updateSelectedFilesState(results: any[]) {
    results.forEach(file => {
      file.selected = this.isFileSelected(file);
    });
  }

  private isFileSelected(file: any): boolean {
    return this.state.selectedFiles.some((selectedFile: any) => selectedFile.id === file.id) || this.state.addedFiles.some((addedFile: any) => addedFile.id === file.id);
  }

  private updateResultsState(results: any[], isRecommendations: boolean) {
    const countText = results.length === 1 ? "Result" : "Results";
    const recommendationText = results.length === 1 ? "Recommendation" : "Recommendations";
    const textLabel = isRecommendations ? recommendationText : countText;

    if (isRecommendations) {
      this.setState({
        recommendedFiles: results,
        isLoading: false,
        viewTitle: results.length > 0 ? `${results.length} ${textLabel}` : ""
      });
    } else {
      const newSearchPath = this.updateSearchPaths(results);
      this.setState({
        files: results,
        isLoading: false,
        searchResultsVisible: true,
        searchPaths: newSearchPath
      });
    }
  }

  /* 
    Function to update path for the breadcrumbs while searching for a file -
    When search is being invoked for the 2nd time, it retains the old path state.
    Hence this function replaces the existing path to display the text in the breadcrumbs
  */
  private updateSearchPaths(results: any[]): any[] {
    const savedSearchPaths = [...this.state.searchPaths];
    const pathLabel = results.length > 0 ? `${results.length}` : "No";
    const existingIndex = savedSearchPaths.findIndex(pathItem => pathItem.path === "/fileSearchResults");

    if (existingIndex !== -1) {
      savedSearchPaths[existingIndex] = {
        name: `${pathLabel} matches for "${this.state.searchTerm}"`,
        path: "/fileSearchResults"
      };
    } else {
      savedSearchPaths.push({
        name: `${pathLabel} matches for "${this.state.searchTerm}"`,
        path: "/fileSearchResults"
      });
    }

    return savedSearchPaths;
  }

  private handleSearchError(errorMessage: string) {
    this.setState({
      hasError: true,
      error: errorMessage,
      isLoading: false
    });
  }

  public render() {
    const { recommendedFiles, hasError, error, fileCount, isLoading,/* message, hideAttachedBtn,*/ emailMode, showInfo, /*isRecommendations*/ } = this.state;
    const cx = classNames.bind(styles);
    const classes = cx({
      recomended: true,
    });
    const footerClasses = cx({
      showInfo: this.state.showInfo,
    }, styles.footer);
    const header = this.getHeader();
    const browse = this.renderBrowse();

    return (
      <div className={classes}>
        <div className={styles.header}> {header}</div>
        <div className={styles.body}>
          {this.state.shareOption === "recommendations" ?
            <>
              <div className={styles.title}>{this.state.viewTitle}</div>
              <div className={styles.bodyinternal}>
                {recommendedFiles && recommendedFiles.length > 0 && !isLoading && !hasError &&
                  recommendedFiles.map((item, index) => {
                    return <div key={item.id}>
                      <RecommendedFileItem shareOption={this.state.shareOption} filters={this.state.selectedFilters} searchTerm={this.state.searchTerm} emailMode={emailMode} isLastItem={recommendedFiles.length - 1 === index} item={item} onSelected={this.onSelected.bind(this, item)} />
                    </div>;
                  })}
                {isLoading &&
                  <div className={styles.center}>
                    <div className="loader" title="7">
                      <svg x="0px" y="0px" width="24px" height="30px" viewBox="0 0 24 30">
                        <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                          <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
                          <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                          <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                        </rect>
                        <rect x="8" y="10" width="4" height="10" fill="#333" opacity="0.2">
                          <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                          <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                          <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                        </rect>
                        <rect x="16" y="10" width="4" height="10" fill="#333" opacity="0.2">
                          <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                          <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                          <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                        </rect>
                      </svg>
                    </div>
                  </div>
                }
                {recommendedFiles && recommendedFiles.length === 0 && !isLoading && !hasError &&
                  <div className={styles.center}>
                    <>
                      <div className={styles.noresult}>No recommendations</div>
                      <div className={styles.noresult_message}>We could not find any recommendations for the current email. Please use Search to find desired content.</div>
                    </>
                  </div>
                }
                {!isLoading && hasError &&
                  <div className={styles.center}>
                    <div className={styles.noresult}>Error</div>
                    <div className={styles.noresult_message}>{error}</div>
                  </div>
                }
              </div>
            </>
            :
            <>
              {browse}
            </>}
        </div>
        <div className={footerClasses}>
          {emailMode === 2 && !showInfo &&
            <div >
              <Btn
                data-id="add"
                large
                inverted
                disabled={!this.state.selectedFiles.length}
                onClick={this.onAddClick.bind(this)}
                counter={fileCount}
                className={styles.action}
              >
                Add
              </Btn>
            </div>
          }
        </div>
      </div >
    );
  }

  public renderBrowse() {

    const handlePathClick = (event) => {
      event.preventDefault();
      const clickedPath = event.target.dataset.path;
      const savedPaths = [...this.state.paths];
      const clickedIndex = savedPaths.findIndex(item => item.path === clickedPath);

      if (clickedIndex !== -1) {
        const updatedPaths = savedPaths.slice(0, clickedIndex + 1);
        this.setState({
          paths: updatedPaths,
          tabSelected: false,
          channelSelected: false,
          storySelected: false,
        });

        const [type] = clickedPath.split('/');

        if (type === 'tab') {
          this.setState({
            tabSelected: true,
          });
        } else if (type === 'channel') {
          this.setState({
            tabSelected: true,
            channelSelected: true,
          });
        } else if (type === 'story') {
          this.setState({
            tabSelected: true,
            channelSelected: true,
            storySelected: true,
          });
        }
      }
    };

    const handleSearchPathClick = (event) => {
      event.preventDefault();
      this.setState({
        searchResultsVisible: false
      })
    }

    const {
      isLoading,
      hasError,
      emailMode,
      tabs,
      stories,
      files,
      browseFiles,
      channels,
      paths,
      searchPaths,
      storySelected,
      tabSelected,
      channelSelected,
      browseFilesLoaded,
      searchResultsVisible,
      isRecommendations,
      selectAllChecked,
      selectAllIndeterminate,
    } = this.state;

    const handleSelectAllChange = (event) => {
      let browseFiles = this.state.browseFiles;
      const checked = event.target.checked;
      const previouslySelectedFiles = this.state.selectedFiles;

      if (checked) {
        browseFiles.forEach((file) => {
          file.selected = true
          previouslySelectedFiles.push(file);
        });
      } else {
        browseFiles.forEach((file) => {
          const selectedFile = previouslySelectedFiles.findIndex((item) => item.id === file.id);
          if (selectedFile > -1) {
            file.selected = false;
            previouslySelectedFiles.splice(selectedFile, 1)
          }
        });
      }

      const fileCount = previouslySelectedFiles.length;

      this.setState({
        selectAllChecked: checked,
        browseFiles,
        selectedFiles: previouslySelectedFiles,
        fileCount,
        hideAttachedBtn: previouslySelectedFiles.length <= 0,
      });
    }

    const handleTabClick = async (event: any, item: any) => {
      event.preventDefault();
      this.setState({ browseIsLoading: true });
      const channels = await hubServiceClient.getChannels(item.props.id) as any[];
      const channelsArray = channels.map((channel: any) => ({
        id: channel.id,
        name: channel.name,
        type: channel.type,
        colour: channel.colour,
        thumbnail: channel.thumbnail,
        note: channel.childCount > 1 ? `${channel.childCount} stories` : `${channel.childCount} story`
      }));
      const newPath = `tab/${item.props.id}`;
      updatePathAndSetState(item.props.id, item.props.name, newPath, 'tabSelected', channelsArray, 'channels');
    };

    const handleChannelClick = async (event: any, item: any) => {
      event.preventDefault();
      this.setState({ browseIsLoading: true });
      const stories = await hubServiceClient.getStories(item.props.id) as any[];
      const storiesArray = stories.map(story => ({
        id: story.id,
        permId: story.permId,
        name: story.name,
        type: "channel",
        colour: story.colour,
        note: story.fileCount > 1 ? `${story.fileCount} files` : `${story.fileCount} file`
      }));
      const newPath = `channel/${item.props.id}`;
      updatePathAndSetState(item.props.id, item.props.name, newPath, 'channelSelected', storiesArray, 'stories');
    };

    const handleStoryClick = async (event: any, item: any) => {
      event.preventDefault();
      this.setState({ browseIsLoading: true });
      let files = await hubServiceClient.getFilesFromStoryId(item.props.permId) as any[];
      files = files.filter(file => file.shareStatus !== "blocked");
      const filesArray = files.map(file => ({
        description: file.description,
        id: file.id,
        permId: file.permId,
        type: file.type,
        thumbnail: file.thumbnail,
        category: file.category,
        selected: this.state.addedFiles.some(f => f.id === file.id) || this.state.selectedFiles.some(f => f.id === file.id),
      }));
      const newPath = `story/${item.props.id}`;
      updatePathAndSetState(item.props.id, item.props.name, newPath, 'storySelected', filesArray, 'browseFiles');
    };

    /* 
        selectedKey: To check what has been selected (tab, channels or story)
        data: Value of the entity that has been selected, so if tab is selected - we store channels to be displayed
        selectedValue: Name of the entity being stored in the data
    */
    const updatePathAndSetState = (id: number, name: string, newPath: string, selectedKey: string, data: any, selectedEntity: string) => {
      let savedPaths = [...this.state.paths];
      const existingIndex = savedPaths.findIndex(path => path.path.startsWith(newPath.split('/')[0]));
      if (existingIndex !== -1) {
        savedPaths[existingIndex] = { name, path: newPath };
      } else {
        savedPaths.push({ name, path: newPath });
      }

      this.setState({
        [selectedKey]: true,
        [selectedEntity]: data,
        paths: savedPaths,
        browseIsLoading: false,
      } as any);
    }

    return (
      <>
        <div className={styles.browse}>
          <div className={styles.browseBodyInternal}>
            {files && files.length === 0 && !isLoading && !isRecommendations && searchResultsVisible &&
              <div className={styles.center}>
                <>
                  <div className={styles.noresult}>No results</div>
                  <div className={styles.noresult_message}>Your search criteria returned no matched results. Please try again</div>
                </>
              </div>
            }
            {files && !isLoading && !hasError && searchResultsVisible &&
              <>
                <div className={styles.crumbWrapper}>
                  <Breadcrumbs
                    paths={[...searchPaths]}
                    noLink={true}
                    onPathClick={handleSearchPathClick}
                    className={styles.crumbs}
                  />
                </div>
                {files.map((item, index) => {
                  return <div key={item.id}>
                    <RecommendedFileItem shareOption={this.state.shareOption} filters={this.state.selectedFilters} searchTerm={this.state.searchTerm} emailMode={emailMode} isLastItem={files.length - 1 === index} item={item} onSelected={this.onSelected.bind(this, item)} />
                  </div>;
                })}
              </>
            }
            {!isLoading &&
              <>
                {!this.state.browseIsLoading && !searchResultsVisible &&
                  <div className={styles.crumbWrapper}>
                    <Breadcrumbs
                      paths={[...paths]}
                      noLink={true}
                      onPathClick={handlePathClick}
                      className={styles.crumbs}
                    />
                  </div>
                }
                {!tabSelected && !searchResultsVisible &&
                  <List
                    list={tabs}
                    thumbSize="small"
                    onItemClick={handleTabClick}
                    className={styles.item}
                    showThumb={true}
                    loading={this.state.browseIsLoading}
                  />
                }
                {tabSelected && !channelSelected && !searchResultsVisible &&
                  <List
                    list={channels}
                    thumbSize="small"
                    onItemClick={handleChannelClick}
                    className={styles.item}
                    showThumb={true}
                    loading={this.state.browseIsLoading}
                  />
                }
                {tabSelected && channelSelected && !storySelected && !searchResultsVisible &&
                  <List
                    list={stories}
                    thumbSize="small"
                    onItemClick={handleStoryClick}
                    className={styles.item}
                    showThumb={true}
                    noLink={true}
                    loading={this.state.browseIsLoading}
                  />
                }
                {browseFiles && browseFiles.length > 0 && browseFilesLoaded && !searchResultsVisible && tabSelected && channelSelected && storySelected &&
                  <>
                    <div className={styles.selectAllWrapper}>
                      <Checkbox
                        inputId="file-picker-select-all"
                        name="file-picker-select-all"
                        label=" Select All"
                        checked={selectAllChecked}
                        indeterminateValue={selectAllIndeterminate}
                        onChange={handleSelectAllChange}
                      />
                    </div>
                    <div style={{ display: "block" }} className={styles.browseFilesList}>
                      {browseFiles.map((item, index) => {
                        return <div key={item.id}>
                          <RecommendedFileItem
                            emailMode={emailMode}
                            isLastItem={browseFiles.length - 1 === index}
                            item={item}
                            onSelected={this.onSelected.bind(this, item)}
                          />
                        </div>
                      })}
                    </div>
                  </>
                }
                {browseFiles && browseFiles.length < 1 && browseFilesLoaded && !searchResultsVisible && tabSelected && channelSelected && storySelected &&
                  <div className={styles.center}>
                    <>
                      <div className={styles.noresult}>No files available</div>
                    </>
                  </div>
                }
              </>
            }
            {isLoading &&
              <div className={styles.center}>
                <div className="loader" title="7">
                  <svg x="0px" y="0px" width="24px" height="30px" viewBox="0 0 24 30">
                    <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                      <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
                      <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                      <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                    </rect>
                    <rect x="8" y="10" width="4" height="10" fill="#333" opacity="0.2">
                      <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                      <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                      <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                    </rect>
                    <rect x="16" y="10" width="4" height="10" fill="#333" opacity="0.2">
                      <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                      <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                      <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                    </rect>
                  </svg>
                </div>
              </div>
            }
          </div>
        </div>
      </>
    )
  }

  public onAddClick() {
    const { selectedFiles } = this.state;
    const filesInLocalStorage = localStorage.getItem("fileCollection");
    const fileCollection = filesInLocalStorage ? JSON.parse(filesInLocalStorage) : [];

    selectedFiles.forEach((selectedFile) => {
      const index = fileCollection.findIndex(fileInStorage => fileInStorage.id === selectedFile.id);

      if (index !== -1) {
        fileCollection[index] = {
          ...fileCollection[index],
          selected: true,
          name: selectedFile.description,
          category: selectedFile.category,
          shareStatus: selectedFile.shareStatus
        };
      } else {
        fileCollection.push({
          selected: true,
          id: selectedFile.id,
          name: selectedFile.description,
          category: selectedFile.category,
          shareStatus: selectedFile.shareStatus
        });
      }
    });

    this.setState({
      addedFiles: fileCollection,
      selectedFiles: []
    });

    localStorage.setItem("fileCollection", JSON.stringify(fileCollection));
    this.props.history.push("/home");
  }

  public onSelected(item) {
    const { browseFiles, selectedFiles } = this.state;
    let previouslySelectedFiles = [...selectedFiles];
    const index = previouslySelectedFiles.findIndex(file => file.id === item.id);

    if (item.selected) {
      if (index === -1) {
        previouslySelectedFiles.push(item);
      }
    } else {
      if (index !== -1) {
        previouslySelectedFiles.splice(index, 1);
      }
    }

    this.setState({
      selectedFiles: previouslySelectedFiles,
      fileCount: previouslySelectedFiles.length,
      hideAttachedBtn: previouslySelectedFiles.length === 0,
    });

    const areAllFilesSelected = browseFiles.every(file => file.selected);
    const isAtLeastOneSelected = browseFiles.some(file => file.selected);

    if (areAllFilesSelected) {
      this.setState({
        selectAllChecked: true,
        selectAllIndeterminate: false,
      });
    } else if (isAtLeastOneSelected) {
      this.setState({
        selectAllChecked: true,
        selectAllIndeterminate: true,
      });
    } else {
      this.setState({
        selectAllChecked: false,
        selectAllIndeterminate: false,
      });
    }
  };

  private onSettingClick() {
    this.setState({
      showPopover: true,
    });
  }

  private handleClose() {
    this.setState({
      showPopover: false,
    });
  }

  private onLogOut() {
    localStorage.clear();
    this.props.history.push("/");
    try {
      const cookies = new Cookies();
      cookies.remove("BTCTK_A", { path: "/" });
      cookies.remove("BTCTK_R", { path: "/" });
      cookies.remove("expires_in", { path: "/" });
      // tslint:disable-next-line:no-empty
    } catch {
    }
  }

  private getHeader() {
    const handleFilterClick = () => {
      this.setState({
        showFilters: !this.state.showFilters,
      });
    };

    const cx = classNames.bind(styles);
    const btnClasses = cx({
      backbtn: true,
    }, styles.back);
    const classes = cx({
      searchinput: true,
    }, styles.input);
    const classsearchBlock = cx({
      onsearchfocus: this.state.onsearchfocus,
    }, styles.searchBlock);
    const filterButtonClasses = cx({
      filterButton: true,
      active: this.state.showFilters,
    });

    const getNavigationClasses = type =>
      cx(styles.tabButton, {
        [styles.active]: this.state.shareOption === type,
      });

    const selectNavigationOption = type => {
      this.setState({ shareOption: type });
    };

    const handleDoneClick = (event) => {
      const selectedFilterList = [...this.state.selectedFilters];

      const updatedFilterList = selectedFilterList.filter((i) => i.type !== "date");

      if (this.state.dateFrom || this.state.dateTo) {
        const data = {
          id: "dateFilter",
          type: "date",
          from: this.state.dateFrom !== null
            ? moment(this.state.dateFrom)
              .set({ hour: 0, minute: 0, second: 0 })
              .format()
            : moment(new Date(1900, 0, 1))
              .set({ hour: 0, minute: 0, second: 0 })
              .format(),
          to: this.state.dateTo !== null
            ? moment(this.state.dateTo)
              .set({ hour: 23, minute: 59, second: 59 })
              .format()
            : moment().set({ hour: 23, minute: 59, second: 59 }).format(),
          status: "active",
        };
        updatedFilterList.push(data);
      }

      this.setState({
        selectedFilters: updatedFilterList,
      });
      this.searchFiles(this.state.searchTerm, this.state.isRecommendations, updatedFilterList);
    };

    const handleDateChange = (date, type) => {
      switch (type) {
        case "to": {
          const end = date;

          if (this.state.dateFrom && end && end <= this.state.dateFrom) {
            this.setState({ dateFrom: end });
          }
          this.setState({ dateTo: end });
          break;
        }
        case "from": {
          const start = date;

          if (this.state.dateTo && this.state.dateTo <= start) {
            this.setState({ dateTo: start });
          }
          this.setState({ dateFrom: start });

          break;
        }
        case "anytime": {
          this.setState({
            dateFrom: null,
            dateTo: null,
          });
          break;
        }
        default:
          break;
      }
    };

    const handleFilterItemClick = (context, selectedItem) => {

      let selectedFilterList = [...this.state.selectedFilters];

      const id = selectedItem.id || selectedItem.value || 0;
      const data = {
        ...selectedItem,
        id,
        name: selectedItem.name || selectedItem.label,
        type: context.key,
        status: "active",
      };

      // if multi selection keep previous values
      if (context && !context.isMulti) {
        selectedFilterList = selectedFilterList.filter(
          i => i.type !== context.key,
        );
      }

      // Remove item when clicking again
      if (selectedFilterList.find((i) => i.id === id && i.type === context.key)) {
        selectedFilterList = selectedFilterList.filter(
          i => !((i).id === id && i.type === context.key),
        );
        // Avoid duplicated items from multi selection
      } else {
        selectedFilterList.push(data);
      }

      this.setState({
        selectedFilters: selectedFilterList,
      });

      if (context.key === "date") {
        handleDateChange(null, "anytime");
      }

      this.searchFiles(this.state.searchTerm, this.state.isRecommendations, selectedFilterList);
    };

    const handleClearFiltersClick = () => {

      this.setState({
        selectedFilters: [],
        dateFrom: null,
        dateTo: null,
      });

      const selectedFilterList = [this.state.selectedFilters];
      this.searchFiles(this.state.searchTerm, true, selectedFilterList);
    };

    return (
      <>
        <div className={styles.navbar}>
          <div className={btnClasses} onClick={this.onBack.bind(this)} />
          <div className={styles.searchcontainer}>
            <div className={classsearchBlock}>
              <div className={styles.icon} />
              <input className={classes} name="searchopp" onFocus={this.onFocusLoss.bind(this, true)} onBlur={this.onFocusLoss.bind(this, false)} onChange={this.onSearchTermChanged.bind(this)} value={this.state.isRecommendations ? "" : this.state.searchTerm} placeholder="Search Files" />
              {this.state.activeSearch &&
                <div className={styles.close} onClick={this.onClearClick.bind(this)} />
              }
            </div>
          </div>
          <Btn
            icon="filter"
            className={filterButtonClasses}
            onClick={handleFilterClick}
          />
          <div className={styles.setting} ref={(c) => { this.popUpTarget = c; }} onClick={this.onSettingClick.bind(this)} />
          {this.state.showPopover &&
            <Popover placement="bottom" container={this} target={this.popUpTarget} show={this.state.showPopover} onHide={this.handleClose.bind(this)} >
              <a className="btnlogout" onClick={this.onLogOut.bind(this)} href="#">Log out</a>
            </Popover>
          }
        </div>
        {
          this.state.showFilters &&
          <div style={{ display: "block" }}>
            <SearchFilterBlock
              selectedFilters={this.state.selectedFilters}
              onFilterItemClick={handleFilterItemClick}
              dateTo={this.state.dateTo}
              dateFrom={this.state.dateFrom}
              onDoneClick={handleDoneClick}
              onDateChange={handleDateChange}
              onClearFiltersClick={handleClearFiltersClick}
            />
          </div>
        }
        <div className={styles.tabs}>
          {(
            <div
              data-test="share-modal-options-email"
              className={getNavigationClasses("recommendations")}
              onClick={() => selectNavigationOption("recommendations")}
              id="share-modal-nav-email"
            >
              Recommendations
            </div>
          )}
          {(
            <div
              data-test="share-modal-options-link"
              className={getNavigationClasses("browse")}
              onClick={() => selectNavigationOption("browse")}
              id="share-modal-nav-link"
            >
              Browse
            </div>
          )}
        </div>
      </>
    );
  }
  private onFocusLoss(onFocus) {
    this.setState({
      onsearchfocus: onFocus,
      // shareOption: "browse"
    });
  }
  private onClearClick() {
    this.setState({
      searchTerm: "",
    });
    if (this.searchTimeout) {
      window.clearTimeout(this.searchTimeout);
    }
    if (this.state.searchResultsVisible) {
      this.setState({
        searchResultsVisible: false
      })
    }
    this.setState({
      files: [],
      isLoading: false,
    });
  }
  private onSearchTermChanged(target) {
    const value = target.target.value || "";
    const selectedFilterList = [...this.state.selectedFilters];
    this.setState({
      searchTerm: target.target.value,
      shareOption: "browse",
      isRecommendations: false,
    });
    if (value.length >= 1) {
      if (this.searchTimeout) {
        window.clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = window.setTimeout(() => {
        this.searchFiles(value, false, selectedFilterList);
      }, 800);
      const isActive = target.target.value && target.target.value.length > 0;
      this.setState({
        activeSearch: isActive,
      });
    }
  }
  private onBack() {
    this.props.history.push("/home");
  }
}