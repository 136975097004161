/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

import _get from 'lodash/get';
import filesize from 'filesize';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import autobind from 'class-autobind';
import classNames from 'classnames/bind';
import {
  defineMessages,
  FormattedDate,
  FormattedMessage,
  injectIntl,
} from 'react-intl';
import generateStrings from 'helpers/generateStrings';
import getCategory from 'helpers/getCategory';
import getFileStatusDetails from 'helpers/getFileStatusDetails';

import TagItems from './TagItems';
import Checkbox from 'components/Checkbox/Checkbox';
import FileThumb from 'components/FileThumb/FileThumb';
import DropMenu from 'components/DropMenu/DropMenu';
import Icon from 'components/Icon/Icon';
import FileStatus from './FileStatus';

import styles from './FileItem.less';

const messages = defineMessages({
  more: { id: 'more', defaultMessage: 'More' },
  blocked: { id: 'blocked', defaultMessage: 'blocked' },
  mandatory: { id: 'mandatory', defaultMessage: 'mandatory' },
  optional: { id: 'optional', defaultMessage: 'optional' },
  processing: { id: 'processing', defaultMessage: 'Processing' },
  syncing: { id: 'syncing', defaultMessage: 'Syncing' },
  mandatoryFileShareInfo: {
    id: 'mandatory-file-share-info',
    defaultMessage: 'Mandatory files cannot be removed from Shares',
  },
  disallowHubshareDownloadsInfo: {
    id: 'disallow-hubshare-downloads',
    defaultMessage: 'This file cannot be downloaded from Shares',
  },
  blockedFileInfo: {
    id: 'blocked-file-info',
    defaultMessage: 'Blocked files cannot be Shared',
  },
  info: { id: 'info', defaultMessage: 'Info' },
  download: { id: 'download', defaultMessage: 'Download' },
  fileSharingblocked: {
    id: 'file-sharing-blocked',
    defaultMessage: 'File sharing blocked',
  },
  fileSharingoptional: {
    id: 'file-sharing-optional',
    defaultMessage: 'File sharing optional',
  },
  fileSharingmandatory: {
    id: 'file-sharing-mandatory',
    defaultMessage: 'File sharing mandatory',
  },
  unableToAdd: {
    id: 'adding-learning-objects-not-supported',
    defaultMessage:
      'Adding this type of learning objects to {stories} is not currently supported.',
  },
  default: { id: 'default-language-variant-label', defaultMessage: 'Default' },
  disallowAddingDriveFiles: {
    id: 'disallow-adding-drive-files',
    defaultMessage:
      'Please add this Bigtincan Drive file by choosing the Bigtincan Drive option',
  },
});

export default injectIntl(
  class FileItem extends PureComponent {
    static propTypes = {
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      filename: PropTypes.string,
      description: PropTypes.string.isRequired,

      /** Valid category */
      category: PropTypes.oneOf([
        'app',
        'audio',
        'bnsk-presentation',
        'btc',
        'cad',
        'course',
        'csv',
        'curriculum',
        'earthviewer',
        'ebook',
        'epub',
        'excel',
        'folder',
        'form',
        'ibooks',
        'image',
        'keynote',
        'learning',
        'none',
        'numbers',
        'oomph',
        'pages',
        'pdf',
        'potx',
        'powerpoint',
        'project',
        'prov',
        'rtf',
        'rtfd',
        'scrollmotion',
        'stack',
        'twixl',
        'txt',
        '3d-model',
        'vcard',
        'video',
        'visio',
        'web',
        'word',
        'xr',
        'zip',
        'igs',
        'learningHub',
        'presentation',
        'bnsk',
        'zunos',
        'dashboard',
        'btc-drive-link',
      ]),

      /** Only required if showThumb is passed */
      thumbnail: PropTypes.string,

      url: PropTypes.string, // view url e.g. converted file
      downloadUrl: PropTypes.string, // original file

      dateAdded: PropTypes.number,
      /** last modified timestamps for btc-drive files */
      lastModified: PropTypes.number,
      readCount: PropTypes.number,
      shareStatus: PropTypes.oneOf(['blocked', 'mandatory', 'optional']),
      status: PropTypes.oneOf(['active', 'processing', 'syncing', 'failed']),
      statusMessage: PropTypes.string,

      /** Number representing conversion progress - paired with processing status */
      progress: PropTypes.number,

      /** File size in bytes */
      size: PropTypes.number,
      storyId: PropTypes.number,
      sharing: PropTypes.number,

      height: PropTypes.number,
      width: PropTypes.number,

      isRead: PropTypes.bool,
      hasWatermark: PropTypes.bool,

      /** Highlights item to indicate active state */
      isActive: PropTypes.bool,

      /** Marks checkbox as checked */
      isSelected: PropTypes.bool,

      /** Show checkbox */
      showCheckbox: PropTypes.bool,

      /** Show checkbox on folder item */
      showFolderCheckbox: PropTypes.bool,

      /** Valid size: <code>small, medium, large</code> */
      thumbSize: PropTypes.oneOf([
        'xxsmall',
        'xsmall',
        'small',
        'medium',
        'large',
      ]),

      /** Grid layout */
      grid: PropTypes.bool,

      /** show file info icon */
      showInfo: PropTypes.bool,

      /** Grey out the checkbox in list view */
      disabled: PropTypes.bool,

      // Custom file settings
      fileSettings: PropTypes.object,

      /** Enables download link if available */
      showDownload: PropTypes.bool,

      /** Show Share Status in list view */
      showShareStatus: PropTypes.bool,

      /** Show thumbnails (if available) */
      showThumb: PropTypes.bool,

      /** file name use the full width of the file item to display */
      showFullWidthName: PropTypes.bool,

      /** shows file error in details */
      showFileErrorDetail: PropTypes.bool,

      /** Enables view link if available */
      showView: PropTypes.bool,

      /** Hides metadata in list view */
      hideMeta: PropTypes.bool,

      /** Hides DropMenu in grid view */
      hideDropMenu: PropTypes.bool,

      /** Align checkboxes to right */
      alignCheckboxesToRight: PropTypes.bool,

      stackSize: PropTypes.number,

      /** DEPRECATED - use thumbSize instead */
      thumbWidth: function (props, propName, componentName) {
        if (!props.thumbSize) {
          return new Error(
            `\`${propName}\` is deprecated for` +
              ` \`${componentName}\`. Use a valid thumbSize instead.`
          );
        }
        return null;
      },

      /** DEPRECATED - use showCheckbox instead */
      select: function (props, propName, componentName) {
        if (props[propName] !== undefined) {
          return new Error(
            `\`${propName}\` is deprecated for` +
              ` \`${componentName}\`. Use showCheckbox.`
          );
        }
        return null;
      },

      /** DEPRECATED - use showFolderCheckbox instead */
      selectFolder: function (props, propName, componentName) {
        if (props[propName] !== undefined) {
          return new Error(
            `\`${propName}\` is deprecated for` +
              ` \`${componentName}\`. Use showFolderCheckbox.`
          );
        }
        return null;
      },

      /** DEPRECATED - use isActive or isSelected instead */
      selected: function (props, propName, componentName) {
        if (props[propName] !== undefined) {
          return new Error(
            `\`${propName}\` is deprecated for` +
              ` \`${componentName}\`. Use isActive or isSelected instead.`
          );
        }
        return null;
      },

      authString: PropTypes.string,

      onDownloadClick: PropTypes.func,
      onClick: PropTypes.func,
      onInfoIconClick: PropTypes.func,
      onTagMoreClick: PropTypes.func,

      className: PropTypes.string,
      style: PropTypes.object,
    };

    static contextTypes = {
      settings: PropTypes.object,
    };

    static defaultProps = {
      authString: '',
      dateAdded: 0,
      disabled: false,
      isSelected: false,
      showDownload: false,
      thumbSize: 'large',
      tags: [],
    };

    constructor(props) {
      super(props);

      // These categories are compatible with Viewer
      this.viewTypes = [
        'app',
        'audio',
        'btc',
        'cad',
        'csv',
        'epub',
        'excel',
        'form',
        'image',
        'keynote',
        'numbers',
        'pages',
        'pdf',
        'powerpoint',
        'project',
        'txt',
        'word',
        'video',
        'visio',
        'web',
      ];

      // These categories cannot be downloaded
      this.noDownloadTypes = ['app', 'btc', 'folder', 'form', 'web'];

      this.state = {
        tagWidth: 0,
        canDownload: this.getCanDownload(props),
        canView: this.getCanView(props), // eslint-disable-line react/no-unused-state
        isLongFileName: false,
        relativeTop: 'auto',
      };
      // refs
      this.unsupportedInfoIconRef = null;
      this.tagElement = null;
      this.dropmenu = null;
      this.fileNameRef = null;
      this.fileMetadataAndControlsRef = null;
      this.fileInformationRef = null;
      this.errorIconRef = null;
      autobind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.resizeThrottler, false);
      if (this.tagElement) {
        this.resizeThrottler(true);
      }

      if (this.unsupportedInfoIconRef) {
        this.unsupportedInfoIconRef.addEventListener(
          'mouseover',
          this.updateTooltipPosition
        );
      }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (
        nextProps.showFullWidthName &&
        !nextProps.children &&
        this.fileNameRef
      ) {
        const isLongFileName =
          this.fileNameRef.offsetWidth >
          this.fileInformationRef.offsetWidth -
            this.fileMetadataAndControlsRef.offsetWidth;
        const container = document.querySelector('[data-id="story-body"]');
        const containerTop = container && container.getBoundingClientRect().top;
        // 24 is the container marginTop which is hardcoded in the style-sheet.
        const relativeTop =
          this.fileNameRef.getBoundingClientRect().top - containerTop + 24;
        this.setState({
          relativeTop,
          isLongFileName,
        });
      }

      if (nextProps.showDownload !== this.props.showDownload) {
        this.setState({ canDownload: this.getCanDownload(nextProps) });
      }

      if (nextProps.showView !== this.props.showView) {
        this.setState({ canView: this.getCanView(nextProps) }); // eslint-disable-line react/no-unused-state
      }
      if (this.props.status !== nextProps.status) {
        this.resizeThrottler(true);
      }
      if (
        (this.props.grid && !nextProps.grid) ||
        this.props.id !== nextProps.id ||
        this.props.name !== nextProps.name
      ) {
        this.resizeThrottler(false);
      }
    }

    componentWillUnmount() {
      if (this.unsupportedInfoIconRef) {
        this.unsupportedInfoIconRef.removeEventListener(
          'mouseover',
          this.updateTooltipPosition
        );
      }
    }

    updateTooltipPosition() {
      if (!this.unsupportedInfoIconRef) return;

      const container = document.querySelector('#btc-drive-picker');
      if (!container) return;

      const infoIconRect = this.unsupportedInfoIconRef.getBoundingClientRect();
      const {
        top: infoIconTop,
        height: infoIconHeight,
        left: infoIconLeft,
      } = infoIconRect;

      const containerRect = container.getBoundingClientRect();
      const { top: containerTop, left: containerLeft } = containerRect;

      const tooltipLeft = infoIconLeft - containerLeft;
      const tooltipTop = infoIconTop - containerTop - infoIconHeight / 2;

      const savedCustomTooltipPositionTop = getComputedStyle(
        document.documentElement
      ).getPropertyValue('--unsupported-tooltip-top');

      // if the height of custom footer changed, update the CSS variable
      if (savedCustomTooltipPositionTop !== `${tooltipTop}px`) {
        document.documentElement.style.setProperty(
          '--unsupported-tooltip-top',
          `${tooltipTop}px`
        );
        document.documentElement.style.setProperty(
          '--unsupported-tooltip-left',
          `${tooltipLeft}px`
        );
      }
    }

    getCanDownload(props) {
      return (
        props.showDownload &&
        props.downloadUrl &&
        this.noDownloadTypes.indexOf(props.category) < 0
      );
    }

    getCanView(props) {
      return props.showView && this.viewTypes.indexOf(props.category) > -1;
    }

    handleDownloadClick(event) {
      event.stopPropagation();
      if (
        this.dropmenu &&
        typeof this.dropmenu.handleMenuToggle === 'function'
      ) {
        this.dropmenu.handleMenuToggle();
      }

      const { onDownloadClick } = this.props;
      if (typeof onDownloadClick === 'function') {
        onDownloadClick(event, this.props.downloadUrl);
      }
    }

    handleClick(event) {
      const { onClick } = this.props;
      if (
        this.props.disableDriveFile &&
        this.props.driveUrn &&
        this.props.driveMode !== 'copy'
      )
        return;

      if (typeof onClick === 'function') {
        onClick(event, this);
      }
    }

    handleFileInfoClick(event) {
      event.stopPropagation();
      if (
        this.dropmenu &&
        typeof this.dropmenu.handleMenuToggle === 'function'
      ) {
        this.dropmenu.handleMenuToggle();
      }

      const { onInfoIconClick } = this.props;
      if (onInfoIconClick && typeof onInfoIconClick === 'function') {
        onInfoIconClick(event, this);
      }
    }

    handleInputChange(event) {
      event.stopPropagation();
      event.preventDefault();
    }

    resizeThrottler(now) {
      if (this.currentThrottle) {
        window.clearTimeout(this.currentThrottle);
        this.currentThrottle = null;
      }
      this.currentThrottle = window.setTimeout(
        () => {
          if (this.tagElement) {
            this.setState({
              tagWidth: this.tagElement.offsetWidth,
            });
          }
          this.currentThrottle = null;
        },
        now ? 0 : 500
      );
    }

    renderTags(strings) {
      const { tags, onInfoIconClick } = this.props;

      if (onInfoIconClick && typeof onInfoIconClick === 'function') {
        const { tagWidth } = this.state;
        let totalWidth = tagWidth;

        if (
          this.fileMetadataAndControlsRef &&
          this.fileInformationRef &&
          tagWidth !==
            this.fileInformationRef.offsetWidth -
              this.fileMetadataAndControlsRef.offsetWidth
        ) {
          totalWidth =
            this.fileInformationRef.offsetWidth -
            this.fileMetadataAndControlsRef.offsetWidth;
        }
        return (
          <TagItems
            totalWidth={totalWidth}
            tags={tags}
            strings={strings}
            styles={styles}
            onMoreClick={this.handleFileInfoClick}
          />
        );
      }
      return null;
    }

    handleMouseOverErrorIcon() {
      const infoIconRect = this.errorIconRef.getBoundingClientRect();
      const { top, height, left } = infoIconRect;
      const container = document.querySelector('[data-id="story-body"]');
      if (!container) return;
      const { top: containerTop } = container.getBoundingClientRect();

      // 24 is the container marginTop which is hardcode in the style-sheet
      const tooltipTop = top - containerTop - height / 2 + 24;

      const savedCustomTooltipPositionTop = getComputedStyle(
        document.documentElement
      ).getPropertyValue('--error-tooltip-top');

      if (savedCustomTooltipPositionTop !== `${tooltipTop}px`) {
        document.documentElement.style.setProperty(
          '--error-tooltip-top',
          `${tooltipTop}px`
        );
        document.documentElement.style.setProperty(
          '--error-tooltip-left',
          `${left}px`
        );
      }
    }

    render() {
      const { formatMessage } = this.props.intl;
      const naming = _get(this.context, 'settings.naming', {
        stories: 'Stories',
      });

      const { canDownload } = this.state;

      const {
        id,
        category,
        dateAdded,
        lastModified,
        description,
        showCheckbox,
        fileSettings,
        shareStatus,
        size,
        status,
        unsupported,
        language,
        isLanguageVariant,
        languageVariantCount,
        driveMode,
        driveUrn,
        source,

        thumbSize,
        grid,
        showInfo,
        canExpand,
        isActive,
        isSelected,
        showFolderCheckbox,
        showShareStatus,
        hideMeta,
        hideDropMenu,
        disableDriveFile,
        stackSize,
        style,
        className,
        tags,
        showMandatoryFileInfo,
        allowHubshareDownloads,
        showRemoveButton,
        showBlockedFileInfo,
        renderPlaceModesOptionButton,
        statusMessage,
        showFileErrorDetail,
        systemType,
      } = this.props;

      const disabled = this.props.disabled || !!unsupported;
      const isDriveFile =
        driveUrn &&
        (driveMode === 'sync' || driveMode === 'linkContentSyncMetadata');

      const isFileWithBinary =
        driveMode !== 'linkContentSyncMetadata' && size > 0;

      const cx = classNames.bind(styles);
      const itemClasses = cx(
        {
          FileItem: true,
          isActive: isActive,
          gridItem: grid,
          listItem: !grid,

          listItemLarge: !grid && thumbSize === 'large',
          listItemMedium: !grid && thumbSize === 'medium',
          listItemSmall: !grid && thumbSize === 'small',

          gridItemLarge: grid && thumbSize === 'large',
          gridItemMedium: grid && thumbSize === 'medium',
          gridItemSmall: grid && thumbSize === 'small',

          listItemUnsupportedFile:
            unsupported || (showFileErrorDetail && statusMessage),

          fileStack: stackSize,
        },
        className
      );

      // Do not render if no category is provided
      if (!category) {
        return false;
      }

      let thumbWidth = this.props.thumbWidth;

      // Grid sizes
      if (grid) {
        switch (thumbSize) {
          case 'small':
            thumbWidth = 84;
            break;
          case 'medium':
            thumbWidth = 101;
            break;
          default:
            thumbWidth = 150;
            break;
        }

        // List sizes
      } else {
        switch (thumbSize) {
          case 'small':
            thumbWidth = 45;
            break;
          case 'medium':
            thumbWidth = 54;
            break;
          default:
            thumbWidth = 60;
            break;
        }
      }

      const getCursorType = () => {
        if (
          category === 'learningHub' ||
          (systemType === 'brainshark' &&
            (category === 'course' || category === 'curriculum'))
        )
          return 'not-allowed';
        if (category !== 'folder' || canExpand) return 'pointer';
        return 'default';
      };

      const fileErrorDetail = getFileStatusDetails(
        status,
        statusMessage,
        driveUrn,
        formatMessage
      );

      const itemStyle = {
        ...style,
        width: (style && !style.width) || grid ? `${thumbWidth}px` : 'auto',
        cursor: getCursorType(),
      };

      const thumbCategory = getCategory(
        category,
        source === 'btc_drive' ? driveUrn : source
      );

      // Translations
      const strings = generateStrings(messages, formatMessage, naming);

      const fileThumbProps = {
        category: thumbCategory,
        grid: grid,
        showThumb: this.props.showThumb,
        thumbnail: this.props.thumbnail,
        thumbSize: thumbSize,
        dateAdded: dateAdded,
        lastModified: lastModified,
        repoFileCount: this.props.repoFileCount,
        stackSize: stackSize,
        authString: this.props.authString,
        error: showFileErrorDetail && fileErrorDetail,
        fileStatus: this.props.status,
        strings: strings,
        isDriveFile:
          isDriveFile || category === 'course' || category === 'curriculum',
      };

      const humanFileSize =
        !!size && isFileWithBinary ? `${filesize(size)} · ` : '-';
      const descriptionTitle = description; // + ' (' + humanFileSize + ')';

      // Repo folder is syncing
      // should instead return repoId, repoFolderId and syncing status
      const isProcessing = status === 'processing';
      const isSyncing =
        (this.props.source === 'file_repository' && isProcessing) ||
        status === 'syncing';

      const fileDetailLabel = _get(
        fileSettings,
        'fileGeneralSettings.detailsFieldLabel',
        ''
      );
      const showContentApproveBadge =
        _get(
          fileSettings,
          'fileGeneralSettings.showCustomFileDetailsIcon',
          false
        ) && this.props.customDetailsIsEnabled;

      const descriptionStyles = cx({
        description: true,
        name: grid || (tags && tags.length === 0),
      });

      // Grid view
      if (grid) {
        return (
          <div
            aria-label={description}
            data-category={category}
            data-id={id}
            data-status={status}
            className={itemClasses}
            style={itemStyle}
            onClick={showCheckbox ? this.handleClick : null}
          >
            <div
              className={styles.ThumbnailContainer}
              onClick={!showCheckbox ? this.handleClick : null}
            >
              <span
                aria-label={strings[`fileSharing${shareStatus}`]}
                data-status={shareStatus}
                className={styles.shareActive}
              />{' '}
              <FileThumb {...fileThumbProps} />
            </div>
            <div
              aria-label={fileDetailLabel}
              className={styles.nameWrapper}
              title={descriptionTitle}
              onClick={this.handleInputChange}
            >
              {showContentApproveBadge && (
                <span
                  aria-label={fileDetailLabel}
                  className={styles.tooltipGridView}
                >
                  <span />
                </span>
              )}
              <span className={descriptionStyles}>{description}</span>
            </div>
            {!(showCheckbox || hideMeta || hideDropMenu) && (
              <div className={styles.moreOptionWrapper}>
                <DropMenu
                  data-id="view-more"
                  heading={strings.more}
                  className={styles.more}
                  position={{ left: 0, right: 0 }}
                  onToggle={() => {}}
                  width="auto"
                  activeClassName={styles.activeHeading}
                  ref={c => {
                    this.dropmenu = c;
                  }}
                >
                  <ul>
                    {showInfo && (
                      <li
                        onClick={this.handleFileInfoClick}
                        className={styles.infoGrid}
                      >
                        {strings.info}
                      </li>
                    )}
                    {canDownload &&
                      shareStatus !== 'blocked' &&
                      isFileWithBinary && (
                        <li
                          onClick={this.handleDownloadClick}
                          className={styles.downloadGrid}
                        >
                          {strings.download}
                        </li>
                      )}
                  </ul>
                </DropMenu>
              </div>
            )}
            {((category !== 'folder' &&
              showCheckbox &&
              !unsupported &&
              !canExpand) ||
              (category === 'folder' && showFolderCheckbox)) && (
              <div className={styles.checkbox}>
                <Checkbox
                  name={`file-${description}`}
                  value={id}
                  checked={isSelected}
                  disabled={disabled}
                  onChange={this.handleInputChange}
                />
              </div>
            )}
          </div>
        );
      }
      const tagWithDescription = cx(
        {
          notag: tags && tags.length === 0,
        },
        styles.description
      );
      const blockedFileInfo = cx(styles.tooltip, styles.blocked);
      const unsupportedFileInfo = cx(styles.tooltip, styles.unsupported);

      const LeftCheckbox = !this.props.alignCheckboxesToRight && (
        <div
          className={styles.checkbox}
          style={{
            width: showCheckbox ? null : '0',
            visibility: showCheckbox ? 'visible' : 'hidden',
          }}
        >
          {((category !== 'folder' && !unsupported && !canExpand) ||
            (category === 'folder' && showFolderCheckbox)) &&
            (!isDriveFile || !disableDriveFile) && (
              <Checkbox
                name={`file-${description}`}
                data-test={`checkbox-${id}`}
                value={id}
                checked={isSelected}
                disabled={disabled}
                onChange={this.handleInputChange}
                style={{ opacity: showCheckbox ? '1' : '0' }}
              />
            )}
        </div>
      );

      const getLanguageCodeFromDriveUrn = () => {
        if (!driveUrn) return null;
        const splittedUrn = driveUrn.split('=');
        const indexOfLanguageCode =
          splittedUrn.findIndex(str => str === 'ietf_language_tag') + 1;
        if (indexOfLanguageCode <= 0) return null;
        return splittedUrn[indexOfLanguageCode];
      };

      const languageCode = getLanguageCodeFromDriveUrn();
      const FileName = (
        <div className={tagWithDescription}>
          {showContentApproveBadge && (
            <span
              aria-label={fileDetailLabel}
              className={styles.tooltipListView}
            >
              <span />
            </span>
          )}
          <p
            ref={c => {
              this.fileNameRef = c;
            }}
          >
            {description}
          </p>
          {languageCode && <label>{`(${languageCode})`}</label>}
          {showFileErrorDetail && fileErrorDetail && statusMessage && (
            <span
              ref={c => {
                this.errorIconRef = c;
              }}
              onMouseOver={this.handleMouseOverErrorIcon}
              className={styles.error}
              aria-label={fileErrorDetail?.message}
              data-longtip
              style={{
                '--file-item-error-icon-color': fileErrorDetail?.colorCode,
              }}
            >
              <span />
            </span>
          )}
        </div>
      );

      const FileTags = !isProcessing && !(showCheckbox || hideMeta) && (
        <div
          className={styles.tags}
          style={{
            maxWidth: this.fileMetadataAndControlsRef
              ? `calc(100% - ${this.fileMetadataAndControlsRef.offsetWidth}px)`
              : '43rem',
          }}
          title={descriptionTitle}
          ref={elem => {
            this.tagElement = elem;
          }}
        >
          {this.renderTags(strings)}
        </div>
      );

      const LanguageVariantLabel = isLanguageVariant && (
        <div className={styles.languageVariantFile}>
          {language && language.ietfCode && (
            <label>{`(${language.ietfCode})`}</label>
          )}
          <span>{language ? language.label : strings.default}</span>
        </div>
      );

      const downloadIconClasses = cx({
        downloadIcon: true,
        visible: !showCheckbox,
      });

      const displayedModifiedDate =
        driveUrn && driveMode !== 'copy' && !!lastModified
          ? lastModified
          : dateAdded;

      const FileMetadataAndControls = (
        <>
          {!isProcessing && !isSyncing && !(showCheckbox || hideMeta) && (
            <div>
              <span data-status={shareStatus} className={styles.shareActive} />{' '}
              <div
                className={styles.shareStatusColumn}
                data-share-status={shareStatus}
              >
                {strings[shareStatus]}
              </div>
            </div>
          )}
          {!isProcessing && !isSyncing && !hideMeta && (
            <div className={styles.category} data-category={thumbCategory}>
              {thumbCategory}
            </div>
          )}
          {!isProcessing && !isSyncing && !hideMeta && (
            <div className={styles.size}>
              {category !== 'folder' && humanFileSize}
            </div>
          )}

          {!isProcessing &&
            !isSyncing &&
            !hideMeta &&
            displayedModifiedDate > 0 && (
              <div className={styles.date}>
                {!isProcessing && !isSyncing && (
                  <FormattedDate
                    value={displayedModifiedDate * 1000}
                    day="2-digit"
                    month="short"
                    year="numeric"
                    hour="numeric"
                    minute="numeric"
                  />
                )}
              </div>
            )}
          {isProcessing && !isSyncing && (
            <FileStatus
              type="processing"
              category={this.props.category}
              isGrid={grid}
              strings={strings}
            />
          )}
          {isSyncing && (
            <FileStatus
              type="syncing"
              category={this.props.category}
              isGrid={grid}
              strings={strings}
            />
          )}
          {!isSyncing &&
            disableDriveFile &&
            isDriveFile &&
            !showBlockedFileInfo && (
              <span
                aria-label={strings.disallowAddingDriveFiles}
                data-longtip
                className={styles.disabledDriveFile}
                data-test={`info-icon-${id}`}
              >
                <span />
              </span>
            )}
          {showShareStatus && !isProcessing && !isSyncing && (
            <div className={styles.shareStatus} data-share-status={shareStatus}>
              {strings[shareStatus]}
            </div>
          )}
          {!(showCheckbox || hideMeta) && (
            <div className={styles.actionContainer}>
              <div
                className={styles.download}
                style={{
                  width: showCheckbox || hideMeta ? '0' : null,
                  visibility: showCheckbox || hideMeta ? 'hidden' : 'visible',
                }}
              >
                {canDownload &&
                  shareStatus !== 'blocked' &&
                  isFileWithBinary && (
                    <span
                      className={downloadIconClasses}
                      onClick={this.handleDownloadClick}
                    />
                  )}
              </div>
              <div
                className={styles.info}
                style={{
                  width: hideMeta ? '0' : null,
                  visibility: hideMeta ? 'hidden' : 'visible',
                }}
              >
                {showInfo && (
                  <span
                    className={styles.infoIcon}
                    onClick={this.handleFileInfoClick}
                  />
                )}
              </div>
            </div>
          )}
          {this.props.alignCheckboxesToRight &&
            !allowHubshareDownloads &&
            !showBlockedFileInfo && (
              <span
                aria-label={strings.disallowHubshareDownloadsInfo}
                data-longtip
                className={styles.disallowDownload}
              >
                <span />
              </span>
            )}
          {this.props.alignCheckboxesToRight && showMandatoryFileInfo && (
            <span
              aria-label={strings.mandatoryFileShareInfo}
              data-longtip
              className={styles.tooltip}
            >
              <span />
            </span>
          )}
          {showRemoveButton && (
            <span
              className={styles.removeBtn}
              onClick={this.props.handleRemoveButtonOnClick}
            />
          )}
          {this.props.alignCheckboxesToRight && showBlockedFileInfo && (
            <span
              aria-label={strings.blockedFileInfo}
              data-longtip
              className={blockedFileInfo}
            >
              <span />
            </span>
          )}
          {this.props.alignCheckboxesToRight && showCheckbox && (
            <div
              className={styles.checkbox}
              style={{
                visibility: 'visible',
                marginLeft: '1.5rem',
              }}
            >
              {((category !== 'folder' && !unsupported) ||
                (category === 'folder' && showFolderCheckbox)) && (
                <Checkbox
                  name={`file-${description}`}
                  value={id}
                  checked={isSelected}
                  disabled={disabled}
                  onChange={this.handleInputChange}
                  style={{ opacity: '1' }}
                />
              )}
            </div>
          )}
          {canExpand && (
            <Icon
              name="nav-chevron"
              className={styles.navChevron}
              data-test={`expandable-icon-${id}`}
            />
          )}
          {this.props.unsupported && (
            <span
              ref={c => {
                this.unsupportedInfoIconRef = c;
              }}
              aria-label={strings.unableToAdd}
              data-longtip
              className={unsupportedFileInfo}
            >
              <span />
            </span>
          )}
          {!this.props.unsupported &&
            isSelected &&
            !canExpand &&
            this.props.allowedPlaceModes &&
            typeof renderPlaceModesOptionButton === 'function' &&
            renderPlaceModesOptionButton(id)}
        </>
      );

      // List view without children and show full width file name.
      if (!this.props.children && this.props.showFullWidthName) {
        const fileMetadataAndControlsClasses = cx(
          {
            verticalCenteredInRootContainer: !this.state.isLongFileName,
            noTags:
              this.state.isLongFileName &&
              (!this.tagElement || !this.tagElement.offsetWidth),
          },
          styles.fileMetadataAndControlsContainer
        );

        return (
          <div
            aria-label={description}
            data-category={category}
            data-id={id}
            data-status={status}
            className={itemClasses}
            style={itemStyle}
            title={description}
            onClick={this.handleClick}
          >
            {LeftCheckbox}
            <FileThumb {...fileThumbProps} />
            <div
              style={{ display: 'flex', flexDirection: 'column', flex: '1' }}
              ref={c => {
                this.fileInformationRef = c;
              }}
            >
              <div className={styles.name}>{FileName}</div>
              <div className={styles.row}>
                {FileTags}
                <div
                  className={fileMetadataAndControlsClasses}
                  ref={c => {
                    this.fileMetadataAndControlsRef = c;
                  }}
                  style={{
                    top: this.state.relativeTop,
                  }}
                >
                  {FileMetadataAndControls}
                </div>
              </div>
            </div>
          </div>
        );
      }

      // List with view no children prop
      if (!this.props.children) {
        return (
          <div
            aria-label={description}
            data-category={category}
            data-id={id}
            data-status={status}
            className={itemClasses}
            style={itemStyle}
            onClick={this.handleClick}
          >
            {LeftCheckbox}
            {!isLanguageVariant && (
              <div
                className={styles.thumbnailNameContainer}
                data-test={`thumbnail-name-container-${id}`}
              >
                <FileThumb {...fileThumbProps} />
                <div
                  className={styles.name}
                  style={showCheckbox || hideMeta ? { flex: 1 } : {}}
                  title={descriptionTitle}
                >
                  {FileName}
                  {languageVariantCount > 0 && (
                    <FormattedMessage
                      id="n-languages"
                      defaultMessage="{itemCount, plural, one {# language} other {# languages}}"
                      values={{
                        itemCount: languageVariantCount,
                      }}
                    >
                      {txt => (
                        <span className={styles.languagesInformation}>
                          {txt}
                        </span>
                      )}
                    </FormattedMessage>
                  )}
                  {category === 'learningHub' && (
                    <span className={styles.learningObjectDescription}>
                      {this.props.learningObjectDescription}
                    </span>
                  )}
                  {FileTags}
                </div>
              </div>
            )}
            {LanguageVariantLabel}
            {FileMetadataAndControls}
          </div>
        );
      }

      // List view with a children prop
      return (
        <div
          aria-label={description}
          data-category={category}
          data-id={id}
          data-status={status}
          className={itemClasses}
          style={itemStyle}
          onClick={this.handleClick}
        >
          <FileThumb {...fileThumbProps} />
          {this.props.children}
        </div>
      );
    }
  }
);
