/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Rubenson Barrios <rubenson.barrios@bigtincan.com>
 * @author Nimesh Sherpa <nimesh.sherpa@bigtincan.com>
 */

import React, { forwardRef, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import * as PDFJS from 'pdfjs-dist/webpack';
import * as pdfjsViewer from 'pdfjs-dist/web/pdf_viewer';
import initEventBus from './PdfDocument/Helper';

import styles from './PdfDocument.less';
import './pdf_viewer_customized.css';
import { Helmet } from 'react-helmet';

// Some PDFs need external cmaps.
const CMAP_URL = '/node_modules/pdfjs-dist/cmaps/';
const CMAP_PACKED = true;

const IMAGE_URL = '/node_modules/pdfjs-dist/web/images/';

let l10n = null;

const PdfDocument = forwardRef((props, ref) => {
  const {
    authString,
    currentPage,
    password,
    rotate,
    scale,
    url,
    onCurrentPageChange,
    onDocumentComplete,
    onDocumentError,
    onOutlineComplete,
    onPageAnchorClick,
    onPageRendered,
    onScaleChange,
    onUpdateResultsCount,
    onUpdateState,
  } = props;
  const viewer = useRef();

  const [pdfRef, setPdfRef] = useState();
  const [pdfFileViewer, setPdfFileViewer] = useState();
  const [pdfLinkService, setPdfLinkService] = useState();
  const [pdfLoadingTask, setPdfLoadingTask] = useState();

  const handleDocumentComplete = loadedPdf => {
    setPdfRef(loadedPdf);
    // Request PDF outline
    loadedPdf.getOutline().then(onOutlineComplete);

    const eventBus = new pdfjsViewer.EventBus();

    // (Optionally) enable hyperlinks within PDF files.
    const linkService = new pdfjsViewer.PDFLinkService({
      eventBus,
    });

    // (Optionally) enable find controller.
    const pdfFindController = new pdfjsViewer.PDFFindController({
      eventBus,
      linkService: linkService,
    });

    // initialize localization service, so time stamp in embedded comments are shown correctly
    l10n = new pdfjsViewer.GenericL10n('en-US');

    // Create PDFViewer in iframe
    const pdfViewer = new pdfjsViewer.PDFViewer({
      container: ref.current,
      viewer: viewer.current,
      eventBus,
      linkService: linkService,
      findController: pdfFindController,
      imageResourcesPath: IMAGE_URL,
      l10n: l10n,
    });

    const pdfHistory = new pdfjsViewer.PDFHistory({
      eventBus,
      linkService,
    });
    linkService.setHistory(pdfHistory);

    pdfViewer._currentPageNumber = currentPage;

    // Set document and attach link service
    pdfViewer.setDocument(loadedPdf);
    linkService.setDocument(loadedPdf, null);
    linkService.setViewer(pdfViewer);
    pdfHistory.initialize({
      fingerprint: loadedPdf._pdfInfo.fingerprints[0],
    });

    setPdfFileViewer(pdfViewer);
    setPdfLinkService(linkService);

    initEventBus({
      currentPage,
      eventBus,
      pdfViewer: pdfViewer,
      scale,
      onCurrentPageChange,
      onPageAnchorClick,
      onPageRendered,
      onScaleChange,
      onUpdateResultsCount,
      onUpdateState,
    });

    // Propagate event
    if (typeof onDocumentComplete === 'function') {
      onDocumentComplete(loadedPdf, pdfViewer);
    }
  };

  const closePdfDocument = () => {
    if (!pdfLoadingTask) {
      return Promise.resolve();
    }

    const promise = pdfLoadingTask.destroy();
    setPdfLoadingTask(null);

    if (pdfRef) {
      pdfRef.cleanup();
      setPdfRef(null);

      pdfFileViewer.setDocument(null);
      pdfLinkService.setDocument(null, null);
    }

    return promise;
  };

  const loadPDFDocument = () => {
    if (pdfLoadingTask) {
      // We need to destroy already opened document
      closePdfDocument().then(() =>
        // ... and repeat the open() call.
        loadPDFDocument()
      );
    } else {
      let loadingTask;
      try {
        loadingTask = PDFJS.getDocument({
          url: url + authString,
          password: password,
          cMapPacked: CMAP_PACKED,
          cMapUrl: CMAP_URL,
        });
        setPdfLoadingTask(loadingTask);

        // Return promise
        loadingTask.promise.then(handleDocumentComplete).catch(onDocumentError);
      } catch (e) {
        onDocumentError(e);
      }
    }
  };

  useEffect(() => {
    loadPDFDocument();
  }, [url, password]);

  useEffect(() => {
    if (pdfFileViewer) {
      if (pdfFileViewer.currentScaleValue !== scale) {
        pdfFileViewer.currentScaleValue = scale;
      }
      if (pdfFileViewer.pagesRotation !== rotate) {
        pdfFileViewer.pagesRotation = rotate;
      }
      if (pdfFileViewer.currentPageNumber !== currentPage) {
        pdfFileViewer.currentPageNumber = currentPage;
      }
    }
  }, [rotate, currentPage, scale]);

  return (
    <div className={styles.pdfWrapper}>
      <div ref={ref} tabIndex="-1" className={styles.PdfDocument}>
        <Helmet>
          {/* href location PDFJSLocale is mapped in webpack config */}
          <link type="application/l10n" href="/PDFJSLocale/locale.properties" />
        </Helmet>
        <div ref={viewer} className="pdfViewer" />
      </div>
    </div>
  );
});

PdfDocument.propTypes = {
  url: PropTypes.string.isRequired,
  password: PropTypes.string,

  /** n-based value of the page to render */
  currentPage: PropTypes.number,

  /** angle to rotate */
  rotate: PropTypes.number,

  /** scale in/out */
  scale: PropTypes.any,

  authString: PropTypes.string,

  onDocumentComplete: PropTypes.func,
  onDocumentError: PropTypes.func,
  onPageComplete: PropTypes.func,
  onOutlineComplete: PropTypes.func,
  onPageAnchorClick: PropTypes.func,
  onPageClick: PropTypes.func,
  onCurrentPageChange: PropTypes.func,
};

PdfDocument.defaultProps = {
  currentPage: 1,
  rotate: 0,
  scale: 1,
  authString: '',
};

export default PdfDocument;
