/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package Bigtincan Hub
 * @copyright 2010-2017 BigTinCan Mobile Pty Ltd
 * @author Bhuvan Kakkar <bhuvan.kakkar@bigtincan.com>
 */

/* tslint:disable */

import * as React from "react";
import DropMenu from "components/DropMenu/DropMenu";
import styles from "./index.less";
import Btn from "components/Btn/Btn";
import DateTimePicker from "components/DateTimePicker/DateTimePicker";
import getSearchFilterOptions from "../../helpers/getSearchFilterOptions";

export interface ISearchFilterProps {
    onFilterItemClick: any;
    selectedFilters: any;
    dateTo: number | null;
    dateFrom: number | null;
    onDoneClick: Function;
    onDateChange: Function;
    onClearFiltersClick: Function;
}

export interface ISearchFilterState {
    dateTo: any;
    dateFrom: any;
}

export class SearchFilterBlock extends React.Component<ISearchFilterProps, ISearchFilterState> {
    constructor(props) {
        super(props);
        this.state = {
            dateFrom: null,
            dateTo: null,
        };
    }
    public render() {
        const dropMenuRef = React.createRef() as any;

        const handleDoneClick = () => {
            if (dropMenuRef && dropMenuRef.current) {
                this.props.onDoneClick();
                dropMenuRef.current.handleMenuToggle();
            }
        };
        const handleDropMenuClick = (event) => {
            event.stopPropagation();
        };
        const fileTypeOptions = getSearchFilterOptions({}).fileTypeOptions;
        const searchWithInOptions = getSearchFilterOptions(
            {},
        ).searchWithInOptions.filter((i) => i.for !== "stories");
        const selectedSearchWithInFilter = (this.props.selectedFilters).filter(
            (i) => i.type === "searchWithIn",
        );
        const selectedFileTypeFilters = (this.props.selectedFilters).filter(
            (i) => i.type === "fileType",
        );
        const selectedDateFilters = (this.props.selectedFilters).filter((i) => i.type === "date");

        return (
            <div className={styles.searchFiltersWrapper} >
                <DropMenu
                    heading={<span className={styles.heading}>All</span>}
                    className={styles.dropmenu}
                    position={{ left: 0, right: 0, top: "2.2rem" }}
                >
                    <ul>
                        {searchWithInOptions.map((item) => (
                            <li
                                key={item.label}
                                className={styles.item}
                                onClick={() => this.props.onFilterItemClick({ key: "searchWithIn" }, item)}
                            >
                                {item.label}
                                {selectedSearchWithInFilter.length > 0 &&
                                    selectedSearchWithInFilter[0].value === item.value && (
                                        <span className={styles.iconChecked} />
                                    )}
                            </li>
                        ))}
                    </ul>
                </DropMenu>
                <DropMenu
                    heading={<span className={styles.heading}>Date</span>}
                    className={styles.dropmenu}
                    position={{ left: 0, right: 0, top: "2.2rem", width: "15rem" }}
                    ref={dropMenuRef}
                >
                    <ul>
                        <li
                            key={0}
                            className={styles.item}
                            onClick={() =>
                                this.props.onFilterItemClick({ key: "date" }, { value: "anytime" })
                            }
                        >
                            Anytime
                            {selectedDateFilters.length > 0 &&
                                selectedDateFilters[0].value === "anytime" && (
                                    <span className={styles.iconChecked} />
                                )}
                        </li>
                        <li
                            key={1}
                            style={{ padding: "0.625rem" }}
                            onClick={handleDropMenuClick}
                        >
                            <div style={{ position: "relative" }}>
                                <label htmlFor="from">From</label>
                                {this.props.dateFrom && (
                                    <span className={styles.selectClearZone}>
                                        <span
                                            className={styles.selectClear}
                                            onClick={() => this.props.onDateChange(null, "from")}
                                        />
                                    </span>
                                )}
                                <DateTimePicker
                                    id="dateFrom"
                                    name="dateFrom"
                                    datetime={this.props.dateFrom as number}
                                    placeholder="Select Date..."
                                    max={new Date()}
                                    format="DD MMM YYYY"
                                    showDate={true}
                                    showTime={false}
                                    showTz={false}
                                    onChange={date => this.props.onDateChange(date, "from")}
                                />
                            </div>
                            <div style={{ position: "relative" }}>
                                <label htmlFor="to">To</label>
                                {this.props.dateTo && (
                                    <span className={styles.selectClearZone}>
                                        <span
                                            className={styles.selectClear}
                                            onClick={() => this.props.onDateChange(null, "to")}
                                        />
                                    </span>
                                )}
                                <DateTimePicker
                                    id="dateTo"
                                    name="dateTo"
                                    datetime={this.props.dateTo as number}
                                    placeholder="Select Date..."
                                    max={new Date()}
                                    format="DD MMM YYYY"
                                    showDate={true}
                                    showTime={false}
                                    showTz={false}
                                    onChange={date => this.props.onDateChange(date, "to")}
                                />
                            </div>
                            <Btn
                                disabled={!this.props.dateFrom && !this.props.dateTo}
                                onClick={handleDoneClick}
                                className={styles.doneButton}
                            >
                                Done
                            </Btn>
                        </li>
                    </ul>
                </DropMenu>
                <DropMenu
                    heading={<span className={styles.heading}>Type</span>}
                    className={styles.dropmenu}
                    position={{ left: 0, right: 0, top: "2.2rem" }}
                >
                    <ul className={styles.dropMenuItemContainer}>
                        {fileTypeOptions.map(item => (
                            <li
                                key={item.id}
                                className={styles.item}
                                onClick={() =>
                                    this.props.onFilterItemClick({ key: "fileType", isMulti: true }, item)
                                }
                            >
                                {item.name}
                                {selectedFileTypeFilters.length > 0 &&
                                    selectedFileTypeFilters.filter(type => type.id === item.id)
                                        .length > 0 && <span className={styles.iconChecked} />
                                }
                            </li>
                        ))}
                    </ul>
                </DropMenu>
                <Btn className={styles.clearFiltersBtn} onClick={this.props.onClearFiltersClick} >
                    Reset
                </Btn>
            </div>
        );
    }
}

