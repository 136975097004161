/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Yi Zhang <yi.zhang@bigtincan.com>
 */

export default function (category, source, type = 'category') {
  if (category === 'dsr' && type === 'icon') return 'user-pair';
  if (
    (category !== 'btc-drive-link' || !source) &&
    category !== 'bnsk' &&
    category !== 'zunos'
  ) {
    return category;
  }
  if (type === 'category') {
    if (source.includes('btc_drive')) {
      let fileCategory = source && source.split(':')[2];
      if (fileCategory === 'contPage') {
        fileCategory = 'content-page';
      } else if (fileCategory === 'webpage') {
        fileCategory = 'web';
      } else if (fileCategory === 'form') {
        fileCategory = 'form-updated';
      } else if (fileCategory === 'presentation') {
        fileCategory = 'bnsk-presentation';
      } else if (fileCategory === 'microsite') {
        fileCategory = 'websites';
      }
      return fileCategory;
    } else if (source.includes('urn:btc')) {
      return source.includes('brainshark.com') ? 'bnsk' : 'zunos';
    } else if (category === 'zunos' || category === 'bnsk') {
      return category;
    }
  }
  if (type === 'icon') return 'bnsk-presentation';
  return source.split(':')[1];
}
