/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2020 BigTinCan Mobile Pty Ltd
 * @author Olivia Mo <olivia.mo@bigtincan.com>
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import classNames from 'classnames/bind';
import styles from './StoryBadgesNew.less';

const StoryBadgesNew = ({
  commentCount,
  fileCount,
  ratingCount,
  isBookmark,
  isGeoProtected,
  isLiked,
  isProtected,
  isQuicklink,
  isQuickfile,
  grid,
  className,
}) => {
  const cx = classNames.bind(styles);
  const classes = cx(
    {
      iconsWrapper: true,
      grid: grid,
    },
    className
  );

  const gradientTop = cx({
    gradientTop: ratingCount > 0 || commentCount > 0,
  });

  const ratingClasses = cx({
    ratingCount: true,
    isLiked: isLiked,
  });

  return (
    <div className={classes}>
      <div className={gradientTop}>
        {ratingCount > 0 && (
          <span className={ratingClasses} aria-label="number of likes">
            <FormattedNumber value={ratingCount} />
          </span>
        )}
        {commentCount > 0 && (
          <span className={styles.commentCount} aria-label="number of comments">
            <FormattedNumber value={commentCount} />
          </span>
        )}
      </div>
      <div className={styles.gradientBottom}>
        <div className={styles.leftIcons}>
          {isQuicklink && <span className={styles.quicklinkIcon} />}
          {isQuickfile && <span className={styles.fileIcon} />}
          {!isQuicklink && !isQuickfile && fileCount > 0 && (
            <span className={styles.fileIcon} aria-label="number of files">
              <FormattedNumber value={fileCount} />
            </span>
          )}
        </div>
        <div className={styles.rightIcons}>
          {isGeoProtected && <span className={styles.locationIcon} />}
          {isProtected && <span className={styles.protectedIcon} />}
          {grid && isBookmark && <span className={styles.bookmarkIcon} />}
        </div>
      </div>
    </div>
  );
};

StoryBadgesNew.propTypes = {
  commentCount: PropTypes.number,
  ratingCount: PropTypes.number,

  className: PropTypes.string,
  style: PropTypes.object,
};

export default StoryBadgesNew;
