/* eslint-disable max-classes-per-file */
/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 * @author Nimesh Sherpa <nimesh.sherpa@bigtincan.com>
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'class-autobind';
import classNames from 'classnames/bind';
import { defineMessages, injectIntl } from 'react-intl';

import generateStrings from 'helpers/generateStrings';

import styles from './ViewerToolbar.less';
import Btn from "components/Btn/Btn";

const MARGIN_BETWEEN_ZOOM_MENU_AND_TOOLBAR = 14;

const messages = defineMessages({
  tableOfContents: {
    id: 'table-of-contents',
    defaultMessage: 'Table of Contents',
  },
  search: {
    id: 'search',
    defaultMessage: 'Search',
  },
  files: {
    id: 'files',
    defaultMessage: 'Files',
  },
  thumbnails: {
    id: 'thumbnails',
    defaultMessage: 'Thumbnails',
  },
  showFiles: {
    id: 'show-files',
    defaultMessage: 'Show Files',
  },
});

class ToolbarItem extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    action: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    autobind(this);
  }

  handleClick(event) {
    this.props.onClick(event, this.props.action);
  }

  render() {
    return (
      <li
        title={this.props.title}
        className={this.props.className}
        onClick={this.handleClick}
      >
        {this.props.text}
      </li>
    );
  }
}
class ViewerToolbar extends Component {
  static propTypes = {
    currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    totalPages: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    fullscreen: PropTypes.bool,
    zoom: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    rotate: PropTypes.bool,

    prevPageDisabled: PropTypes.bool,
    nextPageDisabled: PropTypes.bool,
    zoomInDisabled: PropTypes.bool,
    zoomOutDisabled: PropTypes.bool,
    zoomMenuDisabled: PropTypes.bool,

    fullscreenLabel: PropTypes.string,
    prevPageLabel: PropTypes.string,
    nextPageLabel: PropTypes.string,
    zoomInLabel: PropTypes.string,
    zoomOutLabel: PropTypes.string,
    rotateLabel: PropTypes.string,
    fullPageLabel: PropTypes.string,
    pageWidthLabel: PropTypes.string,

    onCurrentPageChange: PropTypes.func,
    onItemClick: PropTypes.func,
    onViewerToolbarItemClick: PropTypes.func.isRequired,

    isActive: PropTypes.bool,
    showToc: PropTypes.bool,
    showPages: PropTypes.bool,
    showFind: PropTypes.bool,
    isPublic: PropTypes.bool,

    className: PropTypes.string,
    style: PropTypes.object,
  };

  static defaultProps = {
    fullscreenLabel: 'Fullscreen',
    ofLabel: 'of',
    prevPageLabel: 'Previous Page',
    nextPageLabel: 'Next Page',
    zoomInLabel: 'Zoom In',
    zoomOutLabel: 'Zoom Out',
    rotateLabel: 'Rotate',
    fullPageLabel: 'Full Page',
    pageWidthLabel: 'Page Width',
    zoomMenuDisabled: true,
    isSidePanelOpen: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      showZoomMenu: false,
    };
    autobind(this);

    // refs
    this.zoomControls = null;
    this.zoomMenuLabel = null;
    this.viewerToolbar = null;
  }

  handleInputFocus(event) {
    event.target.select();
  }

  handleInputChange(event) {
    const { totalPages, onCurrentPageChange } = this.props;
    const pageNumber = parseInt(event.target.value, 10);

    // Propagate event if valid number
    if (
      typeof pageNumber === 'number' &&
      !Number.isNaN(pageNumber) &&
      pageNumber <= totalPages &&
      pageNumber > 0 &&
      typeof onCurrentPageChange === 'function'
    ) {
      onCurrentPageChange(event, pageNumber);
    }
  }

  handleZoomMenuClick() {
    this.setState({ showZoomMenu: !this.state.showZoomMenu }, () => {
      const zoomControls = this.zoomControls;
      if (zoomControls) {
        const zoomMenuLabel = this.zoomMenuLabel;
        zoomControls.style.left = `${
          zoomMenuLabel.offsetLeft +
          zoomMenuLabel.offsetWidth / 2 -
          zoomControls.offsetWidth / 2
        }px`;
        const toolbarHeight = this.viewerToolbar.clientHeight;
        zoomControls.style.bottom = `${
          toolbarHeight + MARGIN_BETWEEN_ZOOM_MENU_AND_TOOLBAR
        }px`;
      }
    });
  }

  handleZoomMenuClose() {
    this.setState({ showZoomMenu: false });
  }

  handleOnItemClick(...args) {
    this.props.onItemClick(...args);
  }

  handleViewerToolbarItemClick(event) {
    if (typeof this.props.onViewerToolbarItemClick === 'function')
      this.props.onViewerToolbarItemClick(event);
  }

  render() {
    const {
      currentPage,
      totalPages,
      fullscreen,
      zoom,
      rotate,

      prevPageDisabled,
      nextPageDisabled,
      zoomInDisabled,
      zoomOutDisabled,

      fullscreenLabel,
      ofLabel,
      prevPageLabel,
      nextPageLabel,
      zoomInLabel,
      zoomOutLabel,
      rotateLabel,
      fullPageLabel,
      pageWidthLabel,
      singlePageLabel,
      continuousLabel,

      isActive,
      isPdfFile,
      isSinglePage,
      availableToolbarOptions,
      isPublic,
      isSidePanelOpen,
      fullScreenToggle,

      className,
      style,
    } = this.props;
    const cx = classNames.bind(styles);
    const { formatMessage } = this.props.intl;

    // Translations
    const strings = generateStrings(messages, formatMessage);

    const classes = cx(
      {
        ViewerToolbar: true,
      },
      className
    );

    const prevPageClasses = cx({
      prevPage: true,
      disabled: prevPageDisabled,
    });

    const nextPageClasses = cx({
      nextPage: true,
      disabled: nextPageDisabled,
    });

    const zoomOutClasses = cx({
      zoomOut: true,
      disabled: zoomOutDisabled,
    });

    const zoomInClasses = cx({
      zoomIn: true,
      disabled: zoomInDisabled,
    });

    const sortHeaderClasses = cx({
      sortKey: true,
      reverseSort: this.state.showZoomMenu,
    });

    const currentPageStyle = {
      width: currentPage > 99 ? '2.5rem' : '1.5rem',
    };

    this.propNamesInActiveFile = {
      tableOfContents: 'showToc',
      thumbnails: 'showPages',
      search: 'showFind',
      files: 'showFiles',
    };

    const isIPad =
      /^Mac/.test(navigator.platform) && navigator.maxTouchPoints > 4;
    const isMobile =
      isIPad ||
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      ) ||
      false;

    return (
      <div
        className={classes}
        style={{ ...style }}
        data-name="viewerToolbar"
        ref={c => {
          this.viewerToolbar = c;
        }}
        data-active={isActive}
      >
        {isPublic && !isMobile && !fullScreenToggle && availableToolbarOptions && (
          <div className={styles.left}>
            {!isSidePanelOpen && <Btn
              large
              inverted
              alt
              icon="arrow-expand"
              data-action="files"
              className={styles.btnShowFiles}
              onClick={this.handleViewerToolbarItemClick}
            >
              {strings.showFiles}
            </Btn>}
            {Object.keys(availableToolbarOptions)
              .filter(item => availableToolbarOptions[item] && item !== 'files')
              .map(item => (
                <div
                  key={item}
                  aria-label={strings[item]}
                  className={styles.toolTip}
                >
                  <span
                    key={item}
                    title={item}
                    data-action={item}
                    className={`${styles[item]} ${styles.topOverlayMenuItem}
                  ${
                    this.props[this.propNamesInActiveFile[item]]
                      ? styles.isActive
                      : ''
                  }`}
                    onClick={this.handleViewerToolbarItemClick}
                  />
                </div>
              ))}
          </div>
        )}
        <div
          className={styles.right}
          style={
            !isPublic || fullScreenToggle || isMobile
              ? {
                  width: '100%',
                }
              : {
                  width: 'auto',
                }
          }
        >
          {totalPages > 0 && (
            <ul className={styles.pageTools}>
              <ToolbarItem
                title={prevPageLabel}
                action="prevPage"
                className={prevPageClasses}
                onClick={this.handleOnItemClick}
              />
              <ul className={styles.pageIndicator}>
                {!isMobile && (
                  <li>
                    <label
                      style={{ marginRight: '0.5rem' }}
                      htmlFor="currentPage"
                    >
                      Page
                    </label>
                  </li>
                )}
                {!isMobile && (
                  <li>
                    <input
                      type="text"
                      id="currentPage"
                      value={currentPage}
                      className={styles.currentPage}
                      style={currentPageStyle}
                      onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus}
                    />
                  </li>
                )}
                {isMobile && <li>{currentPage}</li>}
                <li className={styles.ofLabel}>{ofLabel}</li>
                <li>{totalPages}</li>
              </ul>
              <ToolbarItem
                title={nextPageLabel}
                action="nextPage"
                className={nextPageClasses}
                onClick={this.handleOnItemClick}
              />
            </ul>
          )}
          {zoom && (
            <ul className={styles.zoomTools}>
              {!isMobile && (
                <ToolbarItem
                  title={zoomOutLabel}
                  action="zoomOut"
                  className={zoomOutClasses}
                  onClick={this.handleOnItemClick}
                />
              )}
              {!this.props.zoomMenuDisabled && (
                <li
                  ref={c => {
                    this.zoomMenuLabel = c;
                  }}
                  title={this.props.zoom}
                  action="zoomMenu"
                  onClick={this.handleZoomMenuClick}
                  className={sortHeaderClasses}
                >
                  {parseInt(this.props.zoom * 100, 10)}%
                </li>
              )}
              {!isMobile && (
                <ToolbarItem
                  title={zoomInLabel}
                  action="zoomIn"
                  className={zoomInClasses}
                  onClick={this.handleOnItemClick}
                />
              )}
            </ul>
          )}
          {rotate && (
            <ul className={styles.rotateTools}>
              <ToolbarItem
                title={rotateLabel}
                action="rotate"
                className={styles.rotate}
                onClick={this.handleOnItemClick}
              />
            </ul>
          )}
          {fullscreen && !isMobile && (
            <ul className={styles.screenTools}>
              <ToolbarItem
                title={fullscreenLabel}
                action="fullscreen"
                className={`${styles.fullscreen} ${
                  fullScreenToggle ? styles.isActive : ''
                }`}
                onClick={this.handleOnItemClick}
              />
            </ul>
          )}

          {!this.props.zoomMenuDisabled && this.state.showZoomMenu && (
            <div
              ref={c => {
                this.zoomControls = c;
              }}
              className={styles.zoomControls}
            >
              <ul>
                <ToolbarItem
                  action="zoom25"
                  className={styles.zoomLabel}
                  onClick={this.handleOnItemClick}
                  text="25%"
                  title="25%"
                />
                <ToolbarItem
                  action="zoom50"
                  className={styles.zoomLabel}
                  onClick={this.handleOnItemClick}
                  text="50%"
                  title="50%"
                />
                <ToolbarItem
                  action="zoom75"
                  className={styles.zoomLabel}
                  onClick={this.handleOnItemClick}
                  text="75%"
                  title="75%"
                />
                <ToolbarItem
                  action="zoom100"
                  className={styles.zoomLabel}
                  onClick={this.handleOnItemClick}
                  text="100%"
                  title="100%"
                />
                <ToolbarItem
                  action="zoomPageFit"
                  className={styles.zoomLabel}
                  onClick={this.handleOnItemClick}
                  text={fullPageLabel}
                  title={fullPageLabel}
                />
                <ToolbarItem
                  action="zoomPageWidth"
                  className={styles.zoomLabel}
                  onClick={this.handleOnItemClick}
                  text={pageWidthLabel}
                  title={pageWidthLabel}
                />
                {isPdfFile &&
                  (isSinglePage ? (
                    <ToolbarItem
                      action="continuous"
                      className={styles.zoomLabel}
                      onClick={this.handleOnItemClick}
                      text={continuousLabel}
                      title={continuousLabel}
                    />
                  ) : (
                    <ToolbarItem
                      action="singlePage"
                      className={styles.zoomLabel}
                      onClick={this.handleOnItemClick}
                      text={singlePageLabel}
                      title={singlePageLabel}
                    />
                  ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(ViewerToolbar, { forwardRef: true });
