import hubServiceClient from "../service/HubService";

class CRM {
    public contactFilter: boolean;
    public source: string;
    public serviceDescription: string;
    public serverUrl: string;
    public appId: string;
    public authenticated: boolean;
    public override: boolean;
    public sandbox: boolean;
    public crmAccountId: number;
    public crmEnabled: boolean;
    public selectedOpportunity: any;
    public logToCrm: any;
    public userCapabilities: any;
    public sharing: any;
    public user: any;
    public company: any;
    public naming: any;

    constructor() {
        this.logToCrm = false;
        this.selectedOpportunity = null;
    }
    public get isCrmauthenticated(): boolean {
        return !!this.authenticated;
    }

    public initCompanySettings() {
        return new Promise<void>((resolve, reject) => {
            hubServiceClient.getCompanySetting().then((data) => {
                this.setData(data);
                resolve();
            }).catch((error) => {
                reject(error);
            });
        });
    }
    private setData(data) {
        const { crm, userCapabilities, sharing, user, company, naming } = data;
        const { hasCrmIntegration } = userCapabilities;
        Object.assign(this, (crm || {}));
        this.crmEnabled = hasCrmIntegration;
        this.userCapabilities = userCapabilities;
        this.sharing = sharing;
        this.user = user;
        this.company = company;
        this.naming = naming;
    }
    public setCurrentSelectedOpportunity(item: any) {
        this.selectedOpportunity = item;
        this.logToCrm = !!item;
    }
    public handleOpportunityUnselected(item?: any) {
        this.selectedOpportunity = [];
        this.logToCrm = false;
    }
}

export const crmDetails = new CRM();
