/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package Bigtincan Hub
 * @copyright 2010-2017 BigTinCan Mobile Pty Ltd
 * @author Shibu Bhattarai <shibu.bhattarai@bigtincan.com>
 */

import * as React from "react";
import { RouteComponentProps } from "react-router";
import * as classNames from "classnames/bind";
import Btn from "components/Btn/Btn";
import Radio from "components/Radio/Radio";
import Switch from "react-toggle-switch";
import { crmDetails } from "../../helpers/crm";
import Cookies from "universal-cookie";
import styles from "./index.less";

import authenticationServiceClient from "../../service/AuthneticationService";
// import hubServiceClient from "../../service/HubService";
enum SignInView {
    MainView = 1,
    RegionSelectionView,
    HubLoginView,
    CustomDomainLoginView,
}
enum Region {
    America = 0,
    Europe,
    Asia,
}

interface ISignInOptionProps {
    version: string;
}

type AppProps = RouteComponentProps & ISignInOptionProps;

interface ISignInOptionState {
    view: SignInView;
    selectedRegion: Region;
    selectedOption: number;
    selectedRegionProfile: any;
    userName?: string;
    password?: string;
    loginBtnDisable: boolean;
    loginLdapDisable: boolean;
    loginDisableHubLogin: boolean;
    companyIdentifier: string;
    ldapEnable: boolean;
    showMultipleOption: boolean;
    configAvailable: boolean;
    loginError: boolean;
    samlErrorMsg: string;
}
export class SignIn extends React.Component<AppProps, ISignInOptionState> {
    public Regions: string[];
    public crmEnabled: boolean;
    constructor(props) {
        super(props);
        this.state = {
            loginDisableHubLogin: true,
            loginLdapDisable: true,
            view: SignInView.MainView,
            selectedRegion: Region.America,
            selectedOption: 0,
            selectedRegionProfile: null,
            userName: "",
            password: "",
            loginBtnDisable: true,
            companyIdentifier: "",
            ldapEnable: false,
            showMultipleOption: false,
            configAvailable: false,
            loginError: false,
            samlErrorMsg: "",
        };
        this.Regions = ["Americas", "Europe", "Asia/Pacific"];
    }
    public componentDidMount() {
        if (this.props.location.pathname.indexOf("/loginsuccess.html") > 0) {
            const queryString = require("query-string");
            const parsed = queryString.parse(location.search);
            if (parsed && parsed._sem) {
                const parent = window.parent;
                if (parent != null) {
                    localStorage.setItem("_sem", parsed._sem);
                    window.close();
                }
            }
        }
    }
    public render() {
        if (this.state.view === SignInView.MainView) {
            return this.getMainView();
        } else if (this.state.view === SignInView.HubLoginView) {
            return this.getHubLoginView();
        } else if (this.state.view === SignInView.RegionSelectionView) {
            return this.getRegionSelectionView();
        } else if (this.state.view === SignInView.CustomDomainLoginView) {
            return this.getCustomDomainView();
        } else {
            return this.getMainView();
        }

    }
    public getMainView() {
        const cx = classNames.bind(styles);
        const classes = cx({
            center_hr: true,
        }, styles.topcontainer);

        const itemblockclasseshubLogin = cx({
            rightarrowblock: true,
        }, styles.itemblock);

        const itemblockclassescutomdomain = cx({
            rightarrowblock: true,
            customlogin: true,
        }, styles.itemblock);
        return (
            <div className={classes}>
                <span className={styles.choose_a_sign_in_met}>Choose a sign in method</span>
                <div className={styles.signcontainer}>

                    <div className={itemblockclasseshubLogin} onClick={this.onHubOptionClick.bind(this, 1, SignInView.RegionSelectionView)}>
                        <div className={styles.userimageblock} />
                        <div className={styles.titlecontainer} >
                            <span className={styles.title}>Hub ID</span>
                            <span className={styles.subtitle}>Your email and password</span>
                        </div>
                    </div>

                    <div className={itemblockclassescutomdomain} onClick={this.onHubOptionClick.bind(this, 2, SignInView.RegionSelectionView)}>
                        <div className={styles.customdomainblock} />
                        <div className={styles.titlecontainer}>
                            <span className={styles.title}>Custom Domain</span>
                            <span className={styles.subtitle}>Company identifier required</span>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
    private getCustomDomainView() {
        const cx = classNames.bind(styles);
        const classes = cx({
            view: true,
        }, styles.hublogincontainer);
        const header = this.getHeader();
        return (
            <div className={classes}>
                {header}
                <div>
                    <span className={styles.sign_in_label}>Your custom domain</span>
                    <div className={styles.logincontainer}>
                        <input
                            type="text"
                            name="companyIdentifier"
                            placeholder="Company identifier"
                            className={styles.input}
                            value={this.state.companyIdentifier}
                            onChange={this.onCompanyIdentifierChange.bind(this)}
                        />
                        {this.state.ldapEnable &&
                            <input
                                type="text"
                                name="username"
                                placeholder="User Name"
                                className={styles.input}
                                value={this.state.userName}
                                onChange={this.onUserChange.bind(this)}
                            />
                        }
                        {this.state.ldapEnable &&
                            <input
                                type="password"
                                name="password"
                                placeholder="Password"
                                className={styles.input}
                                value={this.state.password}
                                onChange={this.onPasswordChange.bind(this)}
                            />
                        }
                        {this.state.showMultipleOption &&
                            <div className={styles.radiogroup}>
                                <div className={styles.radiogrouplabel}>Enterprise single sign-on </div>
                                <div className={styles.radiogroupcontainer}>
                                    <Switch onClick={this.onAuthTypeChoose.bind(this)} className={styles.disclosure_indicator} on={!this.state.ldapEnable} />
                                </div>
                            </div>
                        }
                        {this.state.loginError &&
                            <div className={styles.error}>{this.state.samlErrorMsg}</div>
                        }
                        <div className={styles.account}>Need an account? Please contact us <a target="_blank" href="https://go.bigtincan.com/get-a-demo">Bigtincan</a></div>

                    </div>
                    {!this.state.showMultipleOption &&
                        <div className={styles.confirmcontainer}>
                            <Btn className={styles.confirmbtn} onClick={this.onCustomSignIn.bind(this)} disabled={this.state.loginBtnDisable} inverted={true}>Confirm</Btn>
                        </div>
                    }
                    {this.state.showMultipleOption &&
                        <div className={styles.confirmcontainer}>
                            <Btn className={styles.confirmbtn} onClick={this.processCustomSignIn.bind(this)} disabled={this.state.loginLdapDisable} inverted={true}>Login</Btn>
                        </div>
                    }
                </div>
            </div>
        );
    }
    // tslint:disable-next-line:no-empty
    private onAuthTypeChoose() {
        let isEnable = !this.state.ldapEnable;
        if (!this.state.ldapEnable) {
            isEnable = !(this.state.password && this.state.userName);
        }
        this.setState({
            ldapEnable: !this.state.ldapEnable,
            loginLdapDisable: isEnable,
        });
    }
    private getHubLoginView() {
        const cx = classNames.bind(styles);
        const classes = cx({
            view: true,
        }, styles.hublogincontainer);
        const header = this.getHeader();
        return (
            <div className={classes}>
                {header}
                <div>
                    <span className={styles.sign_in_label}>Sign in</span>
                    <div className={styles.logincontainer}>
                        <input
                            type="text"
                            name="username"
                            placeholder="Email"
                            className={styles.input}
                            value={this.state.userName}
                            onChange={this.onUserChange.bind(this)}
                        />
                        <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            className={styles.input}
                            value={this.state.password}
                            onChange={this.onPasswordChange.bind(this)}
                        />
                        {
                            this.state.loginError &&
                            <div className={styles.error}>Login failed. Please try again</div>
                        }
                        <div className={styles.account}>Need an account? Please contact us <a target="_blank" href="https://go.bigtincan.com/get-a-demo">Bigtincan</a></div>
                    </div>
                    <div className={styles.confirmcontainer}>
                        <Btn className={styles.confirmbtn} onClick={this.onSignIn.bind(this)} disabled={this.state.loginDisableHubLogin} inverted={true}>Sign In</Btn>
                    </div>
                </div>

            </div>
        );
    }
    public onCompanyIdentifierChange(event) {
        const companyIdentifier = event.currentTarget.value;
        this.setState({
            companyIdentifier,
            loginBtnDisable: !companyIdentifier,
        });
    }
    public processSaml() {
        localStorage.removeItem("BTCTK_A");
        const officeContext = window.Office.context;
        const loginUrl = window.BTC_PLUGIN_URL + "?samlIdentifier=" + encodeURIComponent(this.state.companyIdentifier) + "&apiBaseUrl=" + encodeURIComponent(window.BTC.BTCAPI);
        officeContext.ui.displayDialogAsync(loginUrl, {}, (asyncResult: { status: string; value: any; }) => {
            if (asyncResult.status === "succeeded") {
                const dialog = asyncResult.value;
                dialog.addEventHandler(window.Office.EventType.DialogMessageReceived, (response: any) => {
                    const tokens = JSON.parse(response.message);
                    if (tokens) {
                        localStorage.setItem("BTCTK_A", tokens.BTCTK_A);
                        localStorage.setItem("BTCTK_R", tokens.BTCTK_R);
                        localStorage.setItem("expires_in", tokens.expires_in);
                        try {
                            const cookies = new Cookies();
                            cookies.set("BTCTK_A", tokens.BTCTK_A, { path: "/" });
                            cookies.set("BTCTK_R", tokens.BTCTK_R, { path: "/" });
                            cookies.set("expires_in", tokens.expires_in, { path: "/" });
                            // tslint:disable-next-line:no-empty
                        } catch {
                        }
                        dialog.close();
                        crmDetails.initCompanySettings().then(() => {
                            this.props.history.push("/home");
                        });
                    }
                });
            }
        });
    }
    public processCustomSignIn() {
        let { userName, password, companyIdentifier } = this.state;
        userName = userName || "";
        password = password || "";
        companyIdentifier = companyIdentifier || "";
        if (this.state.ldapEnable) {
            authenticationServiceClient.loginLdap(userName, password, companyIdentifier).catch((error) => {
                this.setState({
                    loginError: true,
                    samlErrorMsg: error.message,
                });
            }).then((data: any) => {
                if (data.oauth2) {
                    localStorage.setItem("BTCTK_A", data.oauth2.access_token);
                    localStorage.setItem("BTCTK_R", data.oauth2.refresh_token);
                    localStorage.setItem("expires_in", data.oauth2.expires_in);
                    crmDetails.initCompanySettings().then(() => {
                        this.props.history.push("/home");
                    });
                }
            });
        } else {
            this.processSaml();
        }
    }
    public onCustomSignIn() {
        authenticationServiceClient.getSignInOption(this.state.companyIdentifier).then((data) => {
            if (data.saml === "on" && data.ldap === "on") {
                this.setState({
                    ldapEnable: false,
                    showMultipleOption: true,
                    configAvailable: true,
                });
            } else if (data.saml === "on") {
                this.processSaml();
                this.setState({
                    ldapEnable: false,
                    configAvailable: true,
                });
            } else {

                this.setState({
                    configAvailable: true,
                    ldapEnable: true,
                });
            }
        });
    }
    public onSignIn() {
        let { userName, password } = this.state;
        userName = userName || "";
        password = password || "";
        authenticationServiceClient.login(userName, password).then((data) => {
            if (data.oauth2) {
                localStorage.setItem("BTCTK_A", data.oauth2.access_token);
                localStorage.setItem("BTCTK_R", data.oauth2.refresh_token);
                localStorage.setItem("expires_in", data.oauth2.expires_in);
                try {
                    const cookies = new Cookies();
                    cookies.set("BTCTK_A", data.oauth2.access_token, { path: "/" });
                    cookies.set("BTCTK_R", data.oauth2.refresh_token, { path: "/" });
                    cookies.set("expires_in", data.oauth2.expires_in, { path: "/" });
                    // tslint:disable-next-line:no-empty
                } catch {
                }
                crmDetails.initCompanySettings().then(() => {
                    this.props.history.push("/home");
                });
            }
        }).catch((data) => {
            this.setState({
                loginError: true,
            });
        });
    }
    public onUserChange(event) {

        const userValue = event.currentTarget.value;
        const disableButton = !(this.state.password && userValue);
        const loginState: any = {
            userName: userValue,
            loginError: false,
        };
        if (this.state.showMultipleOption) {
            loginState.loginLdapDisable = disableButton;
        } else {
            loginState.loginDisableHubLogin = disableButton;
        }
        this.setState(loginState);

    }
    public onPasswordChange(event) {
        const userPass = event.currentTarget.value;
        const disableButton = !(this.state.userName && userPass);
        const loginState: any = {
            password: userPass,
            loginError: false,
        };
        if (this.state.showMultipleOption) {
            loginState.loginLdapDisable = disableButton;
        } else {
            loginState.loginDisableHubLogin = disableButton;
        }
        this.setState(loginState);
    }
    private getRegionSelectionView() {
        const { selectedRegion } = this.state;
        const cx = classNames.bind(styles);
        const classes = cx({
        }, styles.regionselection);
        const btnClasses = cx({
            navbarback: true,
        }, styles.back);
        let america = require("../../../static/img/america.svg");
        let eu = require("../../../static/img/eu.svg");
        let apec = require("../../../static/img/apec.svg");
        if (selectedRegion === Region.America) {
            america = require("../../../static/img/america_selected.svg");
        } else if (selectedRegion === Region.Europe) {
            eu = require("../../../static/img/eu_selected.svg");
        } else {
            apec = require("../../../static/img/apec_selected.svg");
        }
        return (
            <div className="view">
                <div className={styles.navbar}>
                    <Btn className={btnClasses} onClick={this.onHubOptionClick.bind(this, SignInView.MainView)} borderless={true}>Back</Btn>
                </div>
                <div className={styles.select_your_region}>Select your region</div>
                <div className={classes}>
                    <div className={styles.regionblock}>
                        <img src={america} />
                        <Radio className={styles.regionradio} name="region" checked={selectedRegion === Region.America} onChange={this.onChange.bind(this, Region.America)} />
                        <div className={styles.regiontitle}>Americas</div>
                    </div>
                    <div className={styles.regionblock}>
                        <img src={eu} />
                        <Radio className={styles.regionradio} name="region2" checked={selectedRegion === Region.Europe} onChange={this.onChange.bind(this, Region.Europe)} />
                        <div className={styles.regiontitle}>Europe</div>
                    </div>
                    <div className={styles.regionblock}>
                        <img src={apec} />
                        <Radio className={styles.regionradio} name="region3" checked={selectedRegion === Region.Asia} onChange={this.onChange.bind(this, Region.Asia)} />
                        <div className={styles.regiontitle}>Asia/Pacific</div>
                    </div>
                </div>
                <div className={styles.confirmcontainer}>
                    <Btn className={styles.confirmbtn} onClick={this.onConfirm.bind(this)} inverted={true}>Confirm</Btn>
                </div>
            </div>
        );
    }
    private getHeader() {
        const cx = classNames.bind(styles);
        const btnClasses = cx({
            navbarback: true,
        }, styles.back);
        let previousView = SignInView.RegionSelectionView;
        if (!window.PLUGINCONFIG) {
            previousView = SignInView.MainView;
        }
        return (
            <div className={styles.navbar}>
                <Btn className={btnClasses} onClick={this.onHubOptionClick.bind(this, this.state.selectedOption, previousView)} borderless={true}>Back</Btn>
            </div>
        );
    }
    public onHubOptionClick(selecteOption, view: SignInView) {
        const stateInfo: any = {
            view,
            selectedOption: selecteOption,
        };
        if (view === SignInView.RegionSelectionView) {
            const global: any = window;
            if (global.PLUGINCONFIG) {
                stateInfo.showMultipleOption = stateInfo.showMultipleOption && false;
                stateInfo.configAvailable = false;
                stateInfo.ldapEnable = false;
                stateInfo.loginError = false;
            } else {
                if (selecteOption === 1) {
                    stateInfo.view = SignInView.HubLoginView;
                } else {
                    stateInfo.view = SignInView.CustomDomainLoginView;
                }
            }
        }
        this.setState(stateInfo);
    }
    public onChange(region: Region) {
        this.setState({
            selectedRegion: region,
        });
    }
    public onConfirm() {
        const region = this.Regions[this.state.selectedRegion];
        const global: any = window;
        const regions = global.PLUGINCONFIG.REGIONS;
        const selected = regions.find((item) => {
            return item.label === region;
        });
        global.BTC = selected.apiServer;
        global.BTC_APP_URL = selected.value;
        localStorage.setItem("serverprofile", JSON.stringify(selected));
        /*
        global.BTC = {
            BTCAPI:"https://push.bigtincan.org/v5/webapi",
            BTCAPI4: "https://push.bigtincan.org/webapi"
        }; */
        let selectedView: SignInView = SignInView.CustomDomainLoginView;
        if (this.state.selectedOption === 1) {
            selectedView = SignInView.HubLoginView;
        } else {
            selectedView = SignInView.CustomDomainLoginView;
        }

        this.setState({
            selectedRegionProfile: selected,
            loginLdapDisable: false,
            view: selectedView,
            userName: "",
            password: "",
        });
    }
}
