/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 * @author Nimesh Sherpa <nimesh.sherpa@bigtincan.com>
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import ViewerToolbar from 'components/Viewer/ViewerToolbar';
import styles from './SpreadsheetViewer.less';

/**
 * Displays a <code>excel</code> file. Excel and Numbers files are converted to this format.
 * Move any data parsing and toolbar render logic to HOC: SpreadsheetViewer
 */
export default class SpreadsheetViewer extends PureComponent {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    baseUrl: PropTypes.string,

    inViewer: PropTypes.bool,

    onLoad: PropTypes.func,
    onError: PropTypes.func,

    /** Files Side Panel Toggle On/Off */
    showFiles: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    // refs
    this.elem = null;
  }

  render() {
    const cx = classNames.bind(styles);
    const classes = cx({
      SpreadsheetViewer: true,
      inViewer: this.props.inViewer,
    });

    const { showFiles, availableToolbarOptions, isPublic, isMobile } =
      this.props;

    const frameClass = cx({
      frame: true,
      disablePointEvents: isPublic && showFiles,
    });

    return (
      <div
        ref={c => {
          this.elem = c;
        }}
        tabIndex="-1"
        className={classes}
      >
        {this.props.children}
        <iframe
          src={`${this.props.baseUrl}index.html`}
          data-test="spreadsheet-iframe"
          width="100%"
          height="100%"
          allowFullScreen
          className={frameClass}
          style={{
            height: !isPublic && !isMobile ? '100%' : 'calc(100% - 3.125rem)',
          }}
        />
        {!isMobile && isPublic && (
          <ViewerToolbar
            className={styles.viewerToolbar}
            {...{
              showFiles,
            }}
            {...{
              availableToolbarOptions,
            }}
            onViewerToolbarItemClick={this.props.onViewerToolbarItemClick}
            isPublic={isPublic}
            isSidePanelOpen={this.props.isSidePanelOpen}
          />
        )}
      </div>
    );
  }
}
