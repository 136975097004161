import apiClient from "./ApiClient";
class HubService {
  public getCompanySetting() {
    return apiClient.get("/settings", "webapi", {});
  }
  /* public getLeadForm(data) {
    return apiClient.get("/lead-form", "webapi", {
      params: {
        ...data
      }
    });
  } */
  public getLeadFormList() {
    return apiClient.get("/lead-form/list", "webapi", {});
  }
  public getUserProfileFromIdentity() {
    const params = {
      include: "UserProfileV1",
    };
    return new Promise((resolve, reject) => {
      apiClient.get("/clientapi/v1/discovery", "btc-id", {
        params,
      }).then((data) => {
        resolve(data.data);
      }).catch((error) => {
        reject(error);
      });
    });
  }
  public getRecommendedContent(term: string, requestId: number, filters?: any[]) {
    return new Promise((resolve, reject) => {
      const req = (currentRequestId: number) => {
        let q = term;
        let categories = "";
        let baseUrl = `/search/files?limit=100&keyword=${term}`;

        if (filters) {

          const fileTypeFilters = filters.filter((filter) => filter.type === "fileType");
          categories = fileTypeFilters.map((item) => item.name.toLowerCase()).join(",");

          filters.forEach((filter) => {
            const value = filter.value;
            const filterType = filter.type;

            if (filterType === "searchWithIn") {
              if (value === "text") {
                q = `blocks.text:${term}`;
              } else if (value === "title") {
                q = `description:${term}`;
              } else if (value === "tags") {
                q = `tags:${term}`;
              }

            } else if (filterType === "date" && filter.id === "dateFilter") {

              baseUrl = baseUrl + `&created_at[gte]=${encodeURIComponent(filter.from)}&created_at[lte]=${encodeURIComponent(filter.to)}`;

            }
          });
          if (categories) {
            baseUrl = baseUrl + `&category=${categories}`;
          }
        }

        const url = baseUrl + `&q=${q}`;

        apiClient
          .get(url, "webapi", {
            params: {
              shareable: true,
              offset: 0,
              highlighted_excerpt_length: 100,
            },
          })
          .then((data) => {
            const localData = this.fixedMissingData(data || []);
            const resData = {
              requestId: currentRequestId,
              data: localData.filter((item, index, self) => {
                const similarItems = self.findIndex((file) => // code for removing duplicate files from the list of files
                  file.filename === item.filename &&
                  file.description === item.description,
                );
                return !(item.category === "btc" || item.category === "form") && similarItems === index;
              }),
            };
            resolve(resData);
          })
          .catch((error) => {
            error.requestId = currentRequestId;
            reject(error);
          });
      };
      req(requestId);
    });
  }
  public loadHtmlData(id) {
    return apiClient.get("/file/getHtmlData", "webapi", {
      params: {
        id,
      },
    });
  }

  public sendShare(data: any) {
    const daysToExpiry = data.daysToExpiry;
    const leadFormId = data.leadFormId || 1; // default lead form ID is 1
    const title = data.subject;
    const includeAccessForm = data.includeAccessForm;
    const downloadSharedFilesAsPDF = data.downloadSharedFilesAsPDF;
    const crmSource = data.crmSource;
    const canCreateHubShareLink = data.canCreateHubShareLink;

    return new Promise((resolve, reject) => {
      if (canCreateHubShareLink) {
        const shareData: any = {
          crmSource,
          title,
          // expire_link_after: daysToExpiry || 30,
          logToCrm: Boolean(data.logToCrm),
        };

        if (data.daysToExpiry != null) {
          shareData.expire_link_after = daysToExpiry;
        }

        if (data.files) {
          const files = data.files.map((item) => ({ id: item.id }));
          shareData.files = files;
        }

        if (includeAccessForm) {
          shareData.lead_form_id = leadFormId;
        }

        if (downloadSharedFilesAsPDF) {
          shareData.download_shared_files_as_pdf = 1;
        }

        if (data.opportunity) {
          const oppData: any = data.opportunity[0];
          if (oppData) {
            shareData.crmTasks = [{
              relatedToId: oppData.id,
              relatedToName: oppData.name,
              type: "opportunity",
              stage: oppData.stageName,
              amount: "0",
            }];
          }
        }

        apiClient
          .post("/hubshare-link", "webapi", {
            data: shareData,
          })
          .then((result) => {
            resolve({
              files: data.files
                .filter(
                  (file) => file.selected || file.shareStatus === "mandatory",
                )
                .map((obj) => obj.id),
              result,
            });
          })
          .catch((error) => {
            reject({
              files: data.files
                .filter(
                  (file) => file.selected || file.shareStatus === "mandatory",
                )
                .map((obj) => obj.id),
              error,
            });
          });
      } else {
        const emails = data.emails;
        const shareData: any = {
          emails: JSON.stringify(emails),
          sendEmail: 0,
          crmSource: "salesforce",
          logToCrm: Boolean(data.logToCrm),
        };
        if (data.opportunity) {
          const oppData: any = data.opportunity[0];
          if (oppData) {
            shareData.crmTask = JSON.stringify({
              relatedToId: oppData.id,
              relatedToName: oppData.name,
              type: "opportunity",
              stage: oppData.stageName,
              amount: "0",
            });
          }
        }
        const toEmailList: any[] = [];
        const ccEmailList: any[] = [];

        if (data.toAddress && data.toAddress.length) {
          data.toAddress.forEach(({ id, email, type }) => {
            if (type === "user" || !type) {
              toEmailList.push(email || id);
            }
          });
          shareData.emails = JSON.stringify(toEmailList);
        }

        if (data.ccAddress && data.ccAddress.length) {
          data.ccAddress.forEach(({ id, email, type }) => {
            if (type === "user" || !type) {
              ccEmailList.push(email || id);
            }
          });
          shareData.ccemails = JSON.stringify(ccEmailList);
        }

        if (data.subject) {
          shareData.subject = title;
        }
        if (data.message) {
          shareData.note = data.message.substring(
            0,
            2000,
          ); /* limit note to 2000 characters */
        }
        if (data.files) {
          shareData.files = data.files.filter(
            (file) => file.selected || file.shareStatus === "mandatory",
          );
          shareData.files = JSON.stringify(shareData.files.map((obj) => obj.id));
        }

        apiClient
          .post("/story/serverShare", "webapi", {
            params: shareData,
          })
          .then((result) => {
            resolve({
              files: data.files
                .filter(
                  (file) => file.selected || file.shareStatus === "mandatory",
                )
                .map((obj) => obj.id),
              result,
            });
          })
          .catch((error) => {
            reject({
              files: data.files
                .filter(
                  (file) => file.selected || file.shareStatus === "mandatory",
                )
                .map((obj) => obj.id),
              error,
            });
          });
      }
    });
  }
  public getFile(fileId: number) {
    return apiClient.get("/file/get", "webapi", {
      params: {
        id: fileId,
        include_story: true,
      },
    });
  }
  public getTabs(offset = 0, accumulatedTabs = []) {
    return new Promise(async (resolve, reject) => {
      const limit = 100;
      try {
        const response = await apiClient.get("/content/tabs", "webapi", {
          params: {
            show_hidden_channels: 0,
            includePersonal: 0,
            limit,
            offset,
            sort_by: "name",
            can_post: 0,
            can_share: 1,
            exclude_feed_channels: 0,
            includeMyContent: 1,
          },
        });

        const tabs = response;
        const newTabs = accumulatedTabs.concat(tabs);

        if (tabs.length === limit) {  /* Check if there are more tabs than the limit */
          const newOffset = offset + limit;
          this.getTabs(newOffset, newTabs).then((newTabresponse: any) => {
            resolve(newTabresponse); /* Fetch tabs again to get the complete list of tabs */
          }).catch((error: any) => {
            reject(error);
          });
        } else {
          resolve(newTabs);
        }
      } catch (error) {
        reject(error);
      }
    });
  }
  public getChannels(tabId: string, offset = 0, accumulatedChannels = []) {
    return new Promise(async (resolve, reject) => {
      const limit = 100;
      try {
        const response = await apiClient.get("/content/channels", "webapi", {
          params: {
            tab_id: tabId,
            limit,
            offset,
            sort_by: "name",
            can_post: 0,
            can_share: 1,
            show_hidden_channels: 0,
            exclude_feed_channels: 0,
          },
        });

        const channels = response;
        const newChannels = accumulatedChannels.concat(channels);

        if (channels.length === limit) {  /* Check if there are more channels than the limit */
          const newOffset = offset + limit;
          this.getChannels(tabId, newOffset, newChannels).then((newTabresponse: any) => {
            resolve(newTabresponse); /* Fetch channels again to get the complete list of channels */
          }).catch((error: any) => {
            reject(error);
          });
        } else {
          resolve(newChannels);
        }

      } catch (error) {
        reject(error);
      }
    });
  }
  public getStories(channelId: string, offset = 0, accumulatedStories = []) {
    return new Promise(async (resolve, reject) => {
      const limit = 100;
      try {
        const response = await apiClient.get("/content/stories", "webapi", {
          params: {
            channel_id: channelId,
            limit: 100,
            offset,
            sort_by: "title",
            can_share: 1,
          },
        });

        const stories = response;
        const newStories = accumulatedStories.concat(stories);

        if (stories.length === limit) {  /* Check if there are more stories than the limit */
          const newOffset = offset + limit;
          this.getStories(channelId, newOffset, newStories).then((newStoryResponse: any) => {
            resolve(newStoryResponse); /* Fetch stories again to get the complete list of stories */
          }).catch((error: any) => {
            reject(error);
          });
        } else {
          resolve(newStories);
        }

      } catch (error) {
        reject(error);
      }
    });
  }
  public getFilesFromStoryId(storyId: string, offset = 0, accumulatedFiles = []) {
    return new Promise(async (resolve, reject) => {
      const limit = 100;
      try {
        const response = await apiClient.get("/content/files", "webapi", {
          params: {
            permId: storyId,
            limit: 100,
            offset,
            canShare: 1,
          },
        });

        const files = response;
        const newFiles = accumulatedFiles.concat(files);

        if (files.length === limit) {  /* Check if there are more files than the limit */
          const newOffset = offset + limit;
          this.getFilesFromStoryId(storyId, newOffset, newFiles).then((newFileResponses: any) => {
            resolve(newFileResponses); /* Fetch files again to get the complete list of files */
          }).catch((error: any) => {
            reject(error);
          });
        } else {
          resolve(newFiles);
        }

      } catch (error) {
        reject(error);
      }
    });
  }
  private fixedMissingData(data: any[]) {
    const returnData = data.map((item) => {
      return {
        ...item,
        category: this.getCategory(item),
      };
    });
    return returnData;
  }
  private getCategory(f) {
    const ext = f.filename.split(".").pop();
    let category = "none";
    switch (ext) {
      // app
      case "btca":
        category = "app";
        break;
      case "btc":
      case "btcd":
      case "btcp":
        category = "btc";
        break;
      case "cad":
      case "dwg":
      case "dxf":
        category = "cad";
        break;
      case "kml":
      case "kmz":
        category = "earthviewer";
        break;
      case "mobi":
        category = "ebook";
        break;
      case "epub":
        category = "epub";
        break;
      // form - btcf
      case "btcf":
        category = "form";
        break;
      case "ibook":
        category = "ibook";
        break;
      case "ipa":
        category = "ipa";
        break;
      case "key":
        category = "keynote";
        break;
      case "numbers":
        category = "numbers";
        break;
      case "oomph":
        category = "oomph";
        break;
      case "pages":
        category = "pages";
        break;
      // project
      case "mpp":
        category = "project";
        break;

      // prov
      case "prov":
        category = "prov";
        break;

      // rtfd
      case "rtfd":
        category = "rtfd";
        break;

      // scrollmotion
      case "scrollmotion":
        category = "scrollmotion";
        break;

      // twixl
      case "twixl":
        category = "twixl";
        break;

      // vcard
      case "vcf":
        category = "vcard";
        break;

      case "vdw":
      case "vdx":
      case "vsd":
      case "vsdx":
      case "vss":
      case "vst":
      case "vsx":
      case "vt":
        category = "visio";
        break;
      case "xls":
      case "xlsx":
        category = "excel";
        break;
      case "png":
      case "jpg":
      case "jpeg":
      case "bmp":
        category = "image";
        break;
      case "pdf":
        category = "pdf";
        break;
      case "ppt":
      case "pptx":
        category = "powerpoint";
        break;
      case "doc":
      case "docx":
        category = "word";
        break;
      case "rtf":
        category = "rtf";
        break;
      case "txt":
        category = "txt";
        break;
      default:
        category = "none";
        break;
    }
    if (f.mimeType.indexOf("video") >= 0) {
      category = "video";
    }
    if (f.mimeType.indexOf("image") >= 0) {
      category = "image";
    }

    return category;
  }
}
const hubServiceClient = new HubService();
export default hubServiceClient;
