/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Yi Zhang <yi.zhang@bigtincan.com>
 */
import { useEffect } from 'react';

/**
 * @function useClickOutside add & remove 'click' event listener, run 'callback' function if clicking outside of the 'elementRef'.
 * @param {RefObject} elementRef
 * @param {Function} callback
 * @param {Object} options - { mobileOnly: bool }
 */
function useClickOutside(elementRef, callback, options) {
  const handleClickOutside = event => {
    if (
      elementRef &&
      elementRef.current &&
      !elementRef.current.contains(event.target) &&
      typeof callback === 'function'
    ) {
      if (options && options.mobileOnly) {
        // iPad including iPadOS 13+
        const isIPad =
          /^Mac/.test(navigator.platform) && navigator.maxTouchPoints > 4;
        const isMobile =
          isIPad ||
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
            navigator.userAgent
          ) ||
          false;
        if (!isMobile) return;
        callback(event);
      } else {
        callback(event);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [callback, elementRef]);
}

export default useClickOutside;
