/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package Bigtincan Hub
 * @copyright 2010-2017 BigTinCan Mobile Pty Ltd
 * @author Shibu Bhattarai <shibu.bhattarai@bigtincan.com>
 */

import * as classNames from "classnames/bind";
import Radio from "components/Radio/Radio";
import * as React from "react";
import { crmDetails } from "../../helpers/crm";
import { IOpportunityModel } from "../../models/index";
import styles from "./Index.less";

export interface IOpportunityItemBlockState {
    item: IOpportunityModel;
}
export interface IOpportunityItemBlockProp {
    item: IOpportunityModel;
    isLastItem: boolean;
    onItemSelected: (item: any) => void;
}
export class OpportunityItemBlock extends React.Component<IOpportunityItemBlockProp, IOpportunityItemBlockState> {

    constructor(props) {
        super(props);
        this.state = {
            item: this.props.item,
        };
    }
    public render() {
        const { item } = this.state;
        const cx = classNames.bind(styles);
        const classes = cx({
            firstopp: this.state.item.index === 0,
            lastitem: this.props.isLastItem,
        }, styles.itemblock);
        const selectedOpp = JSON.parse(localStorage.getItem("selectedOpportunity") as string);

        if (selectedOpp) {
            if (selectedOpp.id === item.id) {
                item.selected = true;
            }
        }

        const handleClear = (oppItem) => {
            localStorage.removeItem("selectedOpportunity");
            if (oppItem.id === item.id) {
                crmDetails.handleOpportunityUnselected(item);
                item.selected = false;
                this.setState({
                    item,
                });
            }
        };

        return (
            <div className={item.selected ? styles.selectedItem : ""} >
                <div className={classes}>
                    <div className={styles.control}>
                        <Radio className={styles.regionradio} name={item.id} checked={item.selected} onClick={this.onOppItemClick.bind(this, item.selected)} onChange={this.onOppItemChange.bind(this, item.selected)} />
                    </div>
                    <div className={styles.body}>
                        <div className={styles.title}> {item.name}</div>
                        <div className={styles.accountBlock}>
                            Stage : <span>{item.stage}</span>
                        </div>
                    </div>
                    {item.selected &&
                        <div onClick={handleClear.bind(this, item)} className={styles.close} />
                    }
                </div>
            </div>
        );
    }
    // tslint:disable-next-line:no-empty
    public onOppItemChange() {
    }
    public onOppItemClick(isSelected: boolean) {
        const { item } = this.state;
        if (item.logged === true) {
            return;
        }
        item.selected = !item.selected;
        this.setState({
            item,
        });
        if (this.props.onItemSelected) {
            this.props.onItemSelected(item);
        }
    }
}

