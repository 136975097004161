/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Yi Zhang <yi.zhang@bigtincan.com>
 */

import { defineMessages } from 'react-intl';
import generateStrings from 'helpers/generateStrings';

const MandatoryErrorColor = '#ff0000';
const NonMandatoryErrorColor = '#ffd400';

const messages = defineMessages({
  mandatory_conversion_failed: {
    id: 'mandatory-conversion-failed',
    defaultMessage:
      'Unable to process file. Some functionality will be unavailable. Adding this file again may resolve the problem.',
  },
  drive_placement_failed: {
    id: 'drive-placement-failed',
    defaultMessage:
      'File could not be added. Adding this file again may resolve the problem.',
  },
  drive_add_failed: {
    id: 'drive-add-failed',
    defaultMessage:
      'File could not be added. Adding this file again may resolve the problem.',
  },
  non_mandatory_conversion_failed: {
    id: 'non-mandatory-conversion-failed',
    defaultMessage:
      'Processing this file could not be completed. Some functionality will be unavailable.',
  },
  drive_pending_retry_for_update: {
    id: 'drive-pending-retry-for-update',
    defaultMessage:
      'Syncing this file is taking longer than usual. The old version of the file can still be opened',
  },
  drive_update_failed: {
    id: 'drive-update-failed',
    defaultMessage:
      'A newer version of this file exists but is unavailable. The old version of the file can still be opened',
  },
});

const SUCCESS_MESSAGES = ['drive_add_success'];

const ErrorDictionary = {
  // mandatory conversions for rendering file fails
  // temporarily hide https://bigtincan.atlassian.net/browse/BTCH-12235
  // mandatory_conversion_failed: {
  //   colorCode: MandatoryErrorColor,
  //   isMandatory: true,
  //   fileStatusCheck: 'failed',
  // },

  // placement of drive files fails when publishing story
  drive_placement_failed: {
    colorCode: MandatoryErrorColor,
    isMandatory: true,
    fileStatusCheck: 'failed',
  },

  // add callback from drive for adding the file binary fails
  drive_add_failed: {
    colorCode: MandatoryErrorColor,
    isMandatory: true,
    fileStatusCheck: 'failed',
  },

  // non-mandatory conversions for rendering file fails like thumbnail and test extraction
  // temporarily hide https://bigtincan.atlassian.net/browse/BTCH-12235
  // non_mandatory_conversion_failed: {
  //   colorCode: NonMandatoryErrorColor,
  //   isMandatory: false,
  //   fileStatusCheck: null,
  // },

  // syncing for drive updates fails and drive has a pending retry according to the retry config
  drive_pending_retry_for_update: {
    colorCode: NonMandatoryErrorColor,
    isMandatory: false,
    fileStatusCheck: null,
  },

  // update call and the retries for failed updates fail
  drive_update_failed: {
    colorCode: MandatoryErrorColor,
    isMandatory: false,
    fileStatusCheck: null,
  },
};

/**
 * This helper function will map file conversion/placement status details
 *
 * @param {string} fileStatus - The status of files during uploading or updating.
 * @param {string} fileStatusMessage - The status message returned from server and will be used to map error detail.
 * @param {string} driveUrn - The unique identifier of a BTC-Drive file.
 * @param {function}  formatMessage - react-intl build-in method.
 *
 * @return {object | null} file status detail
 */
export default function getFileStatusDetails(
  fileStatus,
  fileStatusMessage,
  driveUrn,
  formatMessage
) {
  if (
    !fileStatusMessage ||
    !driveUrn ||
    SUCCESS_MESSAGES.includes(fileStatusMessage)
  )
    return null;

  const matchedError = ErrorDictionary[fileStatusMessage];

  if (
    !matchedError ||
    (matchedError.fileStatusCheck &&
      fileStatus !== matchedError.fileStatusCheck)
  )
    return null;

  if (formatMessage) {
    // Translations
    const strings = generateStrings(messages, formatMessage);

    return { ...matchedError, message: strings[fileStatusMessage] };
  }

  return matchedError;
}
