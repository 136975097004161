/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package Bigtincan Hub
 * @copyright 2010-2017 BigTinCan Mobile Pty Ltd
 * @author Shibu Bhattarai <shibu.bhattarai@bigtincan.com>
 */

import * as React from "react";
import * as classNames from "classnames/bind";
import Checkbox from "components/Checkbox/Checkbox";
import FileThumb from "components/FileThumb/FileThumb";
import styles from "./Index.less";
import { updateExcerpt } from "../../helpers/updateExcerpt";
export interface IFilteItemState {
    item: any;
    highlightInTitle: any;
    highlightInExcerpt: any;
}
export interface IFilteItemProps {
    item: any;
    isLastItem?: boolean;
    emailMode: number;
    searchTerm?: string;
    filters?: any[];
    onSelected: (item: any) => void;
    searchOption?: string;
    shareOption?: string;
}
export class RecommendedFileItem extends React.Component<IFilteItemProps, IFilteItemState> {
    constructor(props) {
        super(props);
        this.state = {
            item: this.props.item,
            highlightInTitle: true,
            highlightInExcerpt: true,
        };
    }

    public componentDidMount() {
        const filters = this.props.filters;

        if (filters) {
            filters.forEach((filter) => {
                const value = filter.value;
                const filterType = filter.type;
                if (filterType === "searchWithIn") {
                    if (value === "text") {
                        this.setState({
                            highlightInTitle: false,
                            highlightInExcerpt: true,
                        });
                    } else if (value === "title") {
                        this.setState({
                            highlightInTitle: true,
                            highlightInExcerpt: false,
                        });
                    } else if (value === "tags") {
                        this.setState({
                            highlightInTitle: false,
                            highlightInExcerpt: false,
                        });
                    }
                }
            });
        }
    }

    public render() {
        let highlight;
        let updatedExcerpt;
        const { description, excerpt, matchedBlocks, /*category,*/ id, selected, isLocked } = this.state.item;
        const { emailMode } = this.props;
        const cx = classNames.bind(styles);
        const classes = cx({
            item_row: true,
            active: selected,
            lastitem: this.props.isLastItem,
        });
        if (this.props.shareOption) {
            highlight = matchedBlocks.length && matchedBlocks[0].highlight;
            updatedExcerpt = updateExcerpt(highlight && highlight.indexOf("<mark>") >= 0 &&
                ((excerpt && excerpt.indexOf("<mark>") <= 0) || !excerpt)
                ? highlight
                : excerpt);
        }
        const name = id + "-chk";

        return (
            <div className={classes}>
                <div className={styles.checkboxitem}>
                    {emailMode === 2 &&
                        <Checkbox disabled={isLocked} name={name} onChange={this.onChange.bind(this)} checked={selected || false} className={styles.checkbox} label="" />
                    }
                </div>
                <div className={styles.rightcontainer} onClick={this.onChange.bind(this)}>
                    <FileThumb {...this.state.item} className={styles.thumb} thumbSize="small" />
                    <div className={styles.description}>
                        <div style={{ fontWeight: this.props.shareOption ? "bold" : "normal", marginTop: this.props.shareOption ? "0px" : "10px" }} className={styles.title}>
                            {this.props.searchOption && this.state.highlightInTitle && description ?
                                this.highlightWords(description, this.props.searchTerm) :
                                description}
                        </div>
                        {this.props.shareOption &&
                            <>
                                <div className={styles.excerpt}>
                                    {this.props.searchOption && this.state.highlightInExcerpt && updatedExcerpt ?
                                        this.highlightWords(updatedExcerpt, this.props.searchTerm) :
                                        updatedExcerpt}
                                </div>
                            </>
                        }
                        <div className={styles.subtitlecontainer}>
                            <div className={styles.view} onClick={(event) => this.onViewFile(event, this.state.item)} style={{ cursor: "pointer" }}>
                                View File
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
    public highlightWords(content, searchTerm) {
        const words = searchTerm.split(" ").filter((word) => word.trim() !== "");

        let regex;
        if (words.length > 1) { // if multiple words
            regex = new RegExp(`(${words.join("|")})`, "gi");
        } else { // if single word
            regex = new RegExp(`(${searchTerm})`, "gi");
        }

        return content.split(regex).map((part, index) =>
            words.some((word) => word.toLowerCase() === part.toLowerCase())
                ? <span key={index} className={styles.highlight}>{part}</span>
                : part,
        );
    }
    public onViewFile(event: any, item: any) {
        event.stopPropagation(); // to prevent propagation of the click event to any other component
        const token = localStorage.getItem("BTCTK_A");
        const url = window.BTC_PLUGIN_URL + "#/viewer/" + this.state.item.id + "/" + token;
        const officeContext = window.Office.context;
        if (officeContext && officeContext.ui) {
            officeContext.ui.displayDialogAsync(url, {
                displayInIframe: false,
            });
        } else {
            window.open(url);
        }
    }
    public onChange() {
        const { item } = this.state;
        item.selected = !item.selected;
        this.setState({
            item,
        });
        if (this.props.onSelected) {
            this.props.onSelected(item);
        }
    }
    public timeSince(date: Date) {
        const seconds = (Math.floor((new Date().getTime() - date.getTime())) * 0.001);
        let interval = Math.floor(seconds / 31536000);

        if (interval > 1) {
            return interval + " years";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
            return interval + " months";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
            return interval + " days";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
            return interval + " hours";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
            return interval + " minutes";
        }
        return Math.floor(seconds) + " seconds";
    }
}
