/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2020 BigTinCan Mobile Pty Ltd
 * @author Olivia Mo <olivia.mo@bigtincan.com>
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';
import Highlighter from 'react-highlight-words';

import FileItemNew from 'components/FileItemNew/FileItemNew';
import FileThumbNew from 'components/FileThumbNew/FileThumbNew';
import StoryThumbNew from 'components/StoryThumbNew/StoryThumbNew';
import Icon from 'components/Icon/Icon';

import styles from './ShareItemNew.less';

const ShareItemNew = props => {
  const {
    className,
    contactsCount,
    file,
    filesCount,
    viewsCount,
    shareType,
    grid,
    highlightTitle,
    isActive,
    isExpired,
    searchKeyword,
    onClick,
    shareSessionId,
    showThumb,
    story,
    strings,
    subject,
    thumbSize,
    authString,
    hubshareLink,
  } = props;

  const cx = classNames.bind(styles);
  const itemClasses = cx(
    {
      ShareItem: !grid,
      gridItem: grid,
      isActive: isActive,
    },
    className
  );

  const iconName = shareType !== 'room' ? shareType : 'user-pair';
  const iconClass = cx(
    {
      emailIcon: shareType === 'email',
      linkIcon: shareType === 'link',
      roomIcon: shareType === 'room',
    },
    styles.fileNameIcon
  );

  const nameClass = cx(
    {
      ellipsis: true,
      fileText: grid,
      noMargin: !grid,
    },
    styles.fileName
  );

  const shareDetailsClass = cx({
    generalShareDetails: true,
    gridShareDetails: grid && !!story,
    textLeft: !grid,
  });

  const shareItemWrapper = {
    width: '12.5rem',
  };

  const { category, driveUrn, driveMode, thumbnail: fileThumbnail } = file;

  const handleClick = event => {
    event.preventDefault();

    if (typeof onClick === 'function') {
      onClick(props);
    }
  };

  const renderThumbnails = () => {
    const customerLogo = hubshareLink && hubshareLink.thumbnail; // logo image of DSR
    const thumbnailCategory =
      shareType === 'hubshare_dsr' && !file.id ? 'dsr' : category;

    if (story)
      return (
        <StoryThumbNew
          colour={story.colour}
          commentCount={story.commentsCount}
          grid={grid}
          ratingCount={story.likesCount}
          style={!grid ? { marginRight: 0 } : {}}
          thumbnail={story.thumbnail}
          thumbSize={thumbSize}
          {...{ authString }}
        />
      );

    if (!grid && file.id)
      return (
        <FileThumbNew
          category={category}
          grid={false}
          thumbnail={fileThumbnail}
          {...{ authString }}
        />
      );

    if (grid)
      return (
        <FileItemNew
          isShare
          id={shareSessionId}
          category={thumbnailCategory}
          driveUrn={driveUrn}
          driveMode={driveMode}
          grid
          thumbnail={customerLogo || fileThumbnail}
          onClick={() => {}}
          {...{ authString }}
        />
      );

    return null;
  };

  return (
    <div
      className={itemClasses}
      onClick={handleClick}
      style={grid ? shareItemWrapper : null}
    >
      {showThumb && renderThumbnails()}
      <div className={grid ? null : styles.listShareDetails}>
        {!grid && shareType && <Icon name={iconName} className={iconClass} />}
        <div style={{ marginLeft: '0.5rem', width: '100%' }}>
          <div className={styles.fileNameWrapper}>
            <p data-test="share-item-new-p-subject" className={nameClass}>
              {highlightTitle && subject ? (
                <Highlighter
                  highlightClassName={styles.highlight}
                  searchWords={[searchKeyword]}
                  autoEscape
                  textToHighlight={subject}
                />
              ) : (
                subject || `(${strings.noSubject})`
              )}
            </p>
            {isExpired && (
              <div
                data-test="share-item-new-div-expired"
                className={styles.expiredTag}
              >
                Expired
              </div>
            )}
          </div>
          <div className={shareDetailsClass}>
            {shareType === 'link' ? (
              <FormattedMessage
                id="share-links-count-shareItem"
                defaultMessage="{viewsCount, plural, =0 {} one {# View · } other {# Views · }}{filesCount, plural, one {# File} other {# Files}}"
                values={{
                  viewsCount,
                  filesCount,
                }}
                tagName="span"
              />
            ) : (
              <FormattedMessage
                id="files-count-shareItem"
                defaultMessage="{contactsCount, plural, one {# Contact} other {# Contacts}} · {filesCount, plural, one {# File} other {# Files}}"
                values={{
                  contactsCount,
                  filesCount,
                }}
                tagName="span"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ShareItemNew.propTypes = {
  className: PropTypes.string,
  contactsCount: PropTypes.number,
  filesCount: PropTypes.number,
  viewsCount: PropTypes.number,
  file: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  shareType: PropTypes.oneOfType([PropTypes.string]),

  grid: PropTypes.bool,

  /** Highlights item to indicate active state */
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  shareSessionId: PropTypes.string.isRequired,
  story: PropTypes.object,
  style: PropTypes.object,
  subject: PropTypes.string,
  thumbSize: PropTypes.string,
};

ShareItemNew.defaultProps = {
  className: '',
  contactsCount: 0,
  filesCount: 0,
  viewsCount: 0,
  file: {},
  shareType: undefined,
  grid: false,
  isActive: false,
  subject: '',
  story: {},
  strings: {
    noSubject: 'No Subject',
  },
  style: {},
  thumbSize: 'small',
  showThumb: true,
};

export default ShareItemNew;
