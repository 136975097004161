import apiClient from "./ApiClient";
import Cookies from "universal-cookie";
class AuthenticationService {
  public login(userName: string, password: string) {
    return apiClient.post("/signin", "webapi", {
      data: {
        email: userName,
        password,
      },
    });
  }
  public loginLdap(userName: string, password: string, companyIdentifier: string) {
    return apiClient.post("/signin", "webapi", {
      data: {
        ldap_username: userName,
        password,
      },
      companyIdentifier,
    });
  }
  public getSignInOption(companyIdentifier: string) {
    const url = "/system/authSettings";
    return apiClient.get(url, "webapi", {
      companyIdentifier,
    });
  }
  public getAccessToken() {
    return apiClient.post("/signin", "webapi", {
      data: {
        refresh_token: localStorage.getItem("BTCTK_R"),
        grant_type: "refresh_token",
      },
    });
  }
  public checkSession() {
    return apiClient.get("/settings", "webapi", {});
  }
  public getTokens() {
    const queryString = require("query-string");
    const query = queryString.parse(window.location.search);
    let tokens: any = {};

    // refresh_token exists in localStorage (assume access_token)
    if (localStorage.getItem("BTCTK_R") !== null && localStorage.getItem("BTCTK_R") !== "null") {
      tokens = {
        BTCTK_A: localStorage.getItem("BTCTK_A"),
        BTCTK_R: localStorage.getItem("BTCTK_R"),
        expires_in: localStorage.getItem("expires_in"),
      };
      // access_token passed via query string (SAML)
    } else if (query.access_token) {
      tokens = {
        BTCTK_A: query.access_token,
        BTCTK_R: query.refresh_token,
        expires_in: query.expires_in,
      };
      localStorage.setItem("BTCTK_A", tokens.BTCTK_A);
      localStorage.setItem("BTCTK_R", tokens.BTCTK_R);
      localStorage.setItem("expires_in", tokens.expires_in);
    } else if (query._sem) {  // eslint-disable-line
      // tslint:disable-next-line:no-console
      console.info("SAML login error");
    } else {
      try {
        const cookies = new Cookies();
        const BTCTK_A = cookies.get("BTCTK_A");
        const BTCTK_R = cookies.get("BTCTK_R");
        const expireIn = cookies.get("expires_in");
        if (BTCTK_A) {
          localStorage.setItem("BTCTK_A", BTCTK_A);
          localStorage.setItem("BTCTK_R", BTCTK_R);
          localStorage.setItem("expires_in", expireIn);
          tokens = {
            BTCTK_A,
            BTCTK_R,
            expires_in: expireIn,
          };
        }
        // tslint:disable-next-line:no-empty
      } catch {
      }
    }

    return tokens;
  }
}
const authenticationServiceClient = new AuthenticationService();
export default authenticationServiceClient;
