/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-starter
 * @copyright 2010-2017 BigTinCan Mobile Pty Ltd
 * @author Shibu Bhattarai <shibu.bhattaraie@bigtincan.com>
 */

import * as React from "react";
import autobind from "class-autobind";
import * as classNames from "classnames/bind";
import styles from "./Index.less";

export interface ISlideItemProps {
    title: string;
    className?: string;
    desc: string;
}
export class SlideItem extends React.PureComponent<ISlideItemProps, {}> {

    constructor(props) {
        super(props);
        autobind(this);
    }
    public render() {
        const {
            title,
            desc,
        } = this.props;
        const cx = classNames.bind(styles);
        const classes = cx({
            centerimage: true,
        }, this.props.className);

        const descClasses = cx({
            decstitle: true,
        }, styles.attach_hub_files_desc);

        return (
            <div className={styles.slidecontainer} >
                <div className={styles.item}>
                    <div className={styles.attach_hub_files_to}>{title}</div>
                </div>
                <div className={styles.item}>
                    <div className={classes} />
                </div>
                <div className={styles.item}>
                    <div className={descClasses}>{desc}</div>
                </div>
            </div>
        );
    }
}
