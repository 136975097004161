/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 * @author Nimesh Sherpa <nimesh.sherpa@bigtincan.com>
 */

/**
 * Wrapper for PDF.js
 * PDF.js Find service is wrapped into PdfFind.
 * See <a href="https://github.com/mozilla/pdf.js">PDF.JS</a> for more information.
 * <a href="http://www.pdfscripting.com/public/Free-Sample-PDF-Files-with-scripts.cfm">Useful test files</a>
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'class-autobind';
import classNames from 'classnames/bind';
import { defineMessages, injectIntl } from 'react-intl';
import throttle from 'lodash/throttle';

import generateStrings from 'helpers/generateStrings';

import Btn from 'components/Btn/Btn';
import FullScreen from 'components/Fullscreen/Fullscreen';
import Icon from 'components/Icon/Icon';
import Loader from 'components/Loader/Loader';

import PdfDocument from './PdfDocument';
import PdfFind from './PdfFind';
import PdfOutline from './PdfOutline';
import PdfPages from './PdfPages';
import ViewerToolbar from 'components/Viewer/ViewerToolbar';
import {
  SCROLL_MODE,
  LOCAL_STORAGE_KEY_OF_SAVED_SCROLL_MODE,
} from './PdfDocument/Helper';

import styles from './PdfViewer.less';

const throttleWaitingTime = 100;

const SCROLLING_TRENDS = {
  increase: 1,
  flat: 0,
  decrease: -1,
};

const messages = defineMessages({
  search: { id: 'search', defaultMessage: 'Search' },
  tableOfContents: {
    id: 'table-of-contents',
    defaultMessage: 'Table of Contents',
  },
  unlockFile: { id: 'unlock-file', defaultMessage: 'Unlock File' },
  passwordProtected: {
    id: 'password-protected',
    defaultMessage: 'Password Protected File',
  },
  passwordMessage: {
    id: 'password-message',
    defaultMessage: 'Enter password to open this file',
  },
  incorrectPassword: {
    id: 'incorrect-password',
    defaultMessage: 'Incorrect Password ',
  },
  singlePageLabel: {
    id: 'single-page',
    defaultMessage: 'Single Page',
  },
  continuousLabel: {
    id: 'continuous',
    defaultMessage: 'Continuous',
  },
});

const DEFAULT_SCALE_DELTA = 1.1;
const MIN_SCALE = 0.25;
const MAX_SCALE = 4.0;
const DEFAULT_SCALE = 1.0;

/**
 *  Wrapper for PDF.js
 * * PDF.js Content is rendered in PdfDocument component.
 * * PDF.js Find service is wrapped into PdfFind.
 * * PDF.js Page navigation is wrapped into PdfPages.
 */
class PdfViewer extends Component {
  static propTypes = {
    /** URL to PDF file */
    url: PropTypes.string.isRequired,

    /** Set current page, can be used to start PDF at a set page on load */
    currentPage: PropTypes.number,

    /** Initial 'find' query, loads page with highlighted text */
    initialQuery: PropTypes.string,

    /** Auth string required for secure storage */
    authString: PropTypes.string,

    /** States opened/closed of side panel */
    togglePartConst: PropTypes.any,

    /** States opened/closed of hubshare modal */
    isHubShareModalOpen: PropTypes.bool,

    /** file is active or not */
    isActive: PropTypes.bool,

    /** Callback once pdf document loaded */
    onDocumentComplete: PropTypes.func,

    /** Callback if pdf document failed to load */
    onDocumentError: PropTypes.func,

    /** Callback if pdf document outline opened or closed */
    onOutlineComplete: PropTypes.func,

    /** Callback if an anchor is clicked in pdf document */
    onAnchorClick: PropTypes.func,

    /** Callback if a page is clicked clicked in pdf document */
    onPageClick: PropTypes.func,

    /** Callback when a pdf document page is rendered, returns pageIndex */
    onPageRendered: PropTypes.func,

    /** Callback on page changed by scroll or click */
    onCurrentPageChange: PropTypes.func,

    /** Callback when all side panels  (Find/Outline/Pages..) are closed */
    onSidePanelClose: PropTypes.func,

    /** Callback when anchor is clicked in the pdf */
    onPageAnchorClick: PropTypes.func,

    /** Callback when viewer left toolbar icons(Thumbnails, Search, TOC, Files) is clicked in the pdf */
    onViewerToolbarItemClick: PropTypes.func.isRequired,

    showToc: PropTypes.bool,
    showPages: PropTypes.bool,
    showFind: PropTypes.bool,
    showFiles: PropTypes.bool,
  };

  static defaultProps = {
    togglePartConst: {
      None: '',
      PdfFind: 'PdfFind',
      PdfOutline: 'PdfOutline', // not used, if outline s not togged but open modal
      PdfPages: 'PdfPages',
    },
    authString: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      currentPage: props.currentPage || 1,
      totalPages: 0,
      scale: DEFAULT_SCALE,
      rotate: 0,
      outline: [],
      loading: true,
      pdfViewer: null,
      togglePart: props.togglePartConst.None,
      fullScreenToggle: false,
      password: '',
      needPassword: false,
      isPasswordIcorrect: false,
      query: props.initialQuery,
      findContents: [],
      findResults: [],
    };
    autobind(this);

    // refs
    this.PdfComponent = React.createRef();
    this.PdfViewer = React.createRef();

    this.viewerToolbar = null;
    this.keyDownListener = null;
    this.lastDeltaValue = 0;
    this.lastScrollingTrend = SCROLLING_TRENDS.flat;
    this.touchStartX = 0;
    this.touchStartY = 0;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.currentPage &&
      nextProps.currentPage !== this.state.currentPage
    ) {
      this.handleCurrentPageChange(null, nextProps.currentPage);
    }
  }

  componentDidUpdate(preProps) {
    // using saved user preference scroll mode
    if (this.props.isActive && !preProps.isActive) {
      const savedPdfScrollType = localStorage.getItem(
        LOCAL_STORAGE_KEY_OF_SAVED_SCROLL_MODE
      );

      if (
        this.state.pdfViewer &&
        this.state.pdfViewer.scrollMode !== SCROLL_MODE[savedPdfScrollType]
      ) {
        this.handleOnSwitchScrollMode(savedPdfScrollType);
      }
    }
  }

  componentWillUnmount() {
    if (this.keyDownListener) {
      document.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  findText(query) {
    // Don't search until query is over 2 characters
    // prevents freezing of the UIs
    const cur_query = query.toLowerCase().trim();
    if (query.length < 2) return;
    this.state.pdfViewer.eventBus.dispatch('find', {
      query: cur_query,
      phraseSearch: true,
      caseSensitive: false,
      highlightAll: true,
    });
  }

  handleUpdateResultsCount({ source }) {
    const results = source._pageMatches;
    const contents = source._pageContents;

    this.setState({
      findResults: results,
      findContents: contents,
    });
  }

  handleUpdateState({ state, source }) {
    // 3 denotes pending find state
    if (state === 3) {
      this.setState({
        findResults: [],
        findContents: [],
      });
    } else if (!source._pageMatches[0] || source._pageMatches[0].length === 0) {
      this.setState({
        findResults: [0],
      });
    }
  }

  handleKeyDown(e) {
    if (
      document.activeElement.value === undefined &&
      !this.props.isHubShareModalOpen &&
      this.PdfViewer.frame
    ) {
      // not currently in a text input
      this.PdfViewer.frame.focus();
    }

    switch (e.keyCode) {
      case 37:
      case 38:
        this.pageNavigate(-1);
        break;
      case 39:
      case 40:
        this.pageNavigate(1);
        break;
      default:
        break;
    }
  }

  handleAnchorClick(event) {
    event.preventDefault();

    // Propagate to parent
    if (typeof this.props.onAnchorClick === 'function') {
      this.props.onAnchorClick(event);
    }
  }

  handleDocumentComplete(pdf, pdfViewer) {
    this.setState(
      {
        pdf: pdf,
        pdfViewer: pdfViewer,
        totalPages: pdf.numPages,
        loading: false,
      },
      () => {
        // Execute find if initialQuery is set
        if (this.props.initialQuery) {
          this.findText(this.props.initialQuery);
        }

        if (this.PdfViewer.current) {
          // iPad including iPadOS 13+
          const isIPad =
            /^Mac/.test(navigator.platform) && navigator.maxTouchPoints > 4;
          const isMobile =
            isIPad ||
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
              navigator.userAgent
            ) ||
            false;

          if (isMobile) {
            this.PdfViewer.current.addEventListener('touchstart', e => {
              this.touchStartX = e.changedTouches[0].clientX;
              this.touchStartY = e.changedTouches[0].clientY;
            });
            this.PdfViewer.current.addEventListener(
              'touchend',
              throttle(this.handleTouchEnd, throttleWaitingTime)
            );
          } else {
            this.PdfViewer.current.addEventListener(
              'wheel',
              throttle(this.handleOnWheel, throttleWaitingTime)
            );
          }

          this.keyDownListener = this.PdfViewer.current.addEventListener(
            'keydown',
            this.handleKeyDown
          );
        }

        // Propagate to parent
        if (typeof this.props.onDocumentComplete === 'function') {
          this.props.onDocumentComplete(this.props.id, pdf, pdfViewer);
        }
      }
    );
  }

  handleDocumentError(error) {
    if (error.code === 1 && error.name === 'PasswordException') {
      // code 1 denotes missing password
      this.setState({ needPassword: true, loading: false });
    } else if (error.code === 2) {
      // code 2 denotes incorrect password
      this.setState({
        isPasswordIcorrect: true,
        needPassword: true,
        loading: false,
        password: '',
      });
    } else if (typeof this.props.onDocumentError === 'function') {
      this.props.onDocumentError(this.props.id, error);
    }
  }

  handleFileUnlock() {
    this.setState({
      needPassword: false,
      isPasswordIcorrect: false,
      loading: true,
    });
  }

  handleKeyEnter(event) {
    if (this.state.password && event.key === 'Enter') {
      this.handleFileUnlock();
    }
  }

  handlePageComplete(data) {
    this.handleCurrentPageChange(null, data.currentPage);

    if (typeof this.props.onHandlePdfResize === 'function') {
      this.props.onHandlePdfResize(this.PdfViewer);
    }
  }

  handleOutlineComplete(outline) {
    if (outline && outline.length) {
      this.setState({
        outline: outline,
      });

      // Propagate event
      if (typeof this.props.onOutlineComplete === 'function') {
        this.props.onOutlineComplete(this.props.id, true);
      }
    }
  }

  pageNavigate(to) {
    // early escape, if the current scroll mode isn't singlePage
    if (
      !this.state.pdfViewer ||
      this.state.pdfViewer.scrollMode !== SCROLL_MODE.singlePage
    )
      return;

    const {
      scrollWidth,
      scrollHeight,
      offsetWidth,
      offsetHeight,
      scrollTop,
      scrollLeft,
    } = this.PdfViewer.current;

    const reachedBottomEdge = offsetHeight + scrollTop >= scrollHeight;
    const reachedTopEdge = scrollTop === 0;
    const reachedLeftEdge = scrollLeft === 0;
    const reachedRightEdge = offsetWidth + scrollLeft >= scrollWidth;

    // early escape, if the document doesn't reach the edge of the view
    if (
      reachedBottomEdge +
        reachedTopEdge +
        reachedLeftEdge +
        reachedRightEdge ===
      0
    )
      return;

    let newPage = this.state.currentPage;
    // if scroll down/right go to the next page
    if (to > 0 && (reachedBottomEdge || reachedRightEdge)) {
      newPage = this.state.currentPage + 1;
      if (newPage > this.state.totalPages) {
        newPage = 1;
      }
    }
    // if scroll up/left go to the previous page
    if (to < 0 && (reachedTopEdge || reachedLeftEdge)) {
      newPage = this.state.currentPage - 1;
      if (newPage < 1) {
        newPage = this.state.totalPages;
      }
    }

    if (to !== 0 && newPage !== this.state.currentPage)
      this.handleCurrentPageChange(null, newPage);
  }

  getScrollingTrend(deltaValue) {
    if (this.lastDeltaValue < Math.abs(deltaValue)) {
      return SCROLLING_TRENDS.increase;
    }

    if (this.lastDeltaValue > Math.abs(deltaValue)) {
      return SCROLLING_TRENDS.decrease;
    }

    return SCROLLING_TRENDS.flat;
  }

  handleOnWheel(e) {
    const deltaValue =
      Math.abs(e.deltaY) >= Math.abs(e.deltaX) ? e.deltaY : e.deltaX;
    const wheelOnTouchPad = Number.isInteger(deltaValue);

    if (!wheelOnTouchPad) this.pageNavigate(deltaValue);

    // For touch pad, because of 'Predictive Touch',
    // It may trigger multiple `onwheel` events even though it throttled by 200ms.
    // To prevent changing the page number multiple times in a single touch move,
    // the page navigation should only be triggered when the deltaValue start increasing.
    if (wheelOnTouchPad) {
      const scrollingTrend = this.getScrollingTrend(deltaValue);

      this.lastDeltaValue = Math.abs(deltaValue);

      if (this.lastScrollingTrend === scrollingTrend) return;

      if (scrollingTrend === SCROLLING_TRENDS.increase)
        this.pageNavigate(deltaValue);

      this.lastScrollingTrend = scrollingTrend;
    }
  }

  handleTouchEnd(e) {
    const diffInX = this.touchStartX - e.changedTouches[0].clientX;
    const diffInY = this.touchStartY - e.changedTouches[0].clientY;
    const diffValue =
      Math.abs(diffInX) >= Math.abs(diffInY) ? diffInX : diffInY;
    this.pageNavigate(diffValue);
  }

  handleOnSwitchScrollMode(type) {
    const mode = SCROLL_MODE[type];

    if (typeof mode !== 'number') return;

    this.state.pdfViewer.eventBus.dispatch('switchscrollmode', {
      mode: mode,
    });

    if (this.viewerToolbar) this.viewerToolbar.handleZoomMenuClose();
  }

  handleOutlineItemClick(event, dest) {
    /* if (dest && dest[0]) { // keeping this block as it might be needed to handle different usecase
      const goToDestination = data => {
        this.state.pdf.getPageIndex(data[0]).then(pageIndex => {
          this.handleCurrentPageChange(null, parseInt(pageIndex + 1, 10));
        });
      };

      if (typeof dest === 'string') {
        this.state.pdf.getDestination(dest).then(goToDestination);
      } else {
        goToDestination(dest);
      }
    } */
    this.state.pdfViewer.linkService.goToDestination(dest);
  }

  handleOutlineCloseClick() {
    this.setState({ togglePart: this.props.togglePartConst.None });
  }

  handleExitFullScreen() {
    this.setState({ fullScreenToggle: false, scale: this.originalScale });
    this.originalScale = undefined;
  }

  handleToolbarItemClick(event, action) {
    switch (action) {
      case 'fullscreen':
        if (!this.state.fullScreenToggle) {
          // keep original scale before going fullscreen
          this.originalScale = this.state.scale;
        }

        this.setState({
          togglePart: this.props.togglePartConst.None,
          scale: 1.5,
          fullScreenToggle: !this.state.fullScreenToggle,
        });

        // inform parent state changed
        if (this.props.onSidePanelClose) this.props.onSidePanelClose();

        break;
      case 'prevPage': {
        let newPage = this.state.currentPage - 1;
        if (newPage < 1) {
          newPage = this.state.totalPages;
        }
        this.handleCurrentPageChange(null, newPage);
        break;
      }
      case 'nextPage': {
        let newPage = this.state.currentPage + 1;
        if (newPage > this.state.totalPages) {
          newPage = 1;
        }

        this.handleCurrentPageChange(null, newPage);
        break;
      }
      case 'zoomIn': {
        let newScale = this.state.scale;
        newScale = (newScale * DEFAULT_SCALE_DELTA).toFixed(2);
        newScale = Math.ceil(newScale * 10) / 10;
        newScale = Math.min(MAX_SCALE, newScale);
        this.setState({ scale: newScale });
        this.viewerToolbar.handleZoomMenuClose();
        break;
      }
      case 'zoomOut': {
        let newScale = this.state.scale;
        newScale = (newScale / DEFAULT_SCALE_DELTA).toFixed(2);
        newScale = Math.floor(newScale * 10) / 10;
        newScale = Math.max(MIN_SCALE, newScale);
        this.setState({ scale: newScale });
        this.viewerToolbar.handleZoomMenuClose();
        break;
      }
      case 'zoomPageFit': {
        this.state.pdfViewer.currentScaleValue = 'page-fit';
        this.viewerToolbar.handleZoomMenuClose();
        break;
      }
      case 'zoomPageWidth': {
        this.state.pdfViewer.currentScaleValue = 'page-width';
        this.viewerToolbar.handleZoomMenuClose();
        break;
      }
      case 'zoom25': {
        this.state.pdfViewer.currentScaleValue = 0.25;
        this.viewerToolbar.handleZoomMenuClose();
        break;
      }
      case 'zoom50': {
        this.state.pdfViewer.currentScaleValue = 0.5;
        this.viewerToolbar.handleZoomMenuClose();
        break;
      }
      case 'zoom75': {
        this.state.pdfViewer.currentScaleValue = 0.75;
        this.viewerToolbar.handleZoomMenuClose();
        break;
      }
      case 'zoom100': {
        this.state.pdfViewer.currentScaleValue = 1;
        this.viewerToolbar.handleZoomMenuClose();
        this.setState({ scale: 1 });
        break;
      }
      case 'rotate': {
        // rotate may change current page, keep a fixed page number to reinstate
        const fixedPage = this.state.currentPage;
        const newrotate = (this.state.rotate + 90) % 360;
        this.setState({ rotate: newrotate }, () => {
          // reinstate page number
          this.setState({ currentPage: fixedPage });
        });
        break;
      }
      case 'continuous':
      case 'singlePage': {
        this.handleOnSwitchScrollMode(action);
        break;
      }
      default:
        break;
    }
  }

  handleDefaultPageWidthZoom() {
    window.setTimeout(() => {
      this.state.pdfViewer.currentScaleValue = 'page-width';
    }, 1);
  }

  handleToggleFind() {
    if (this.state.togglePart === this.props.togglePartConst.PdfFind) {
      this.setState({ togglePart: this.props.togglePartConst.None });
    } else {
      this.setState({ togglePart: this.props.togglePartConst.PdfFind });
    }

    if (typeof this.props.onHandlePdfResize === 'function') {
      this.props.onHandlePdfResize(this.PdfViewer);
    }
    this.handleDefaultPageWidthZoom();
  }

  handleToggleOutline() {
    if (this.state.togglePart === this.props.togglePartConst.PdfOutline) {
      this.setState({ togglePart: this.props.togglePartConst.None });
    } else {
      this.setState({ togglePart: this.props.togglePartConst.PdfOutline });
    }

    if (typeof this.props.onHandlePdfResize === 'function') {
      this.props.onHandlePdfResize(this.PdfViewer);
    }
    this.handleDefaultPageWidthZoom();
  }

  handleTogglePages() {
    if (this.state.togglePart === this.props.togglePartConst.PdfPages) {
      this.setState({ togglePart: this.props.togglePartConst.None });
    } else {
      this.setState({ togglePart: this.props.togglePartConst.PdfPages });
    }

    if (typeof this.props.onPdfResize === 'function') {
      this.props.onPdfResize(this.PdfViewer);
      this.handleDefaultPageWidthZoom();
    }
  }

  handleToggleFiles() {
    this.handleDefaultPageWidthZoom();
  }

  handleCurrentPageChange(event, pageNumber) {
    if (this.state.currentPage === pageNumber) return;
    this.setState({ currentPage: pageNumber });
    // propagate to parent
    if (typeof this.props.onCurrentPageChange === 'function') {
      this.props.onCurrentPageChange(event, pageNumber);
    }
  }

  handleScaleChange(context) {
    if (!this.props.isActive) return;
    this.setState({ scale: context.scale });
  }

  handlePageClick(event, page) {
    this.setState({ currentPage: page });
    // propagate to parent
    if (typeof this.props.onPageClick === 'function') {
      this.props.onPageClick(event, page);
    }
  }

  handleQueryChange(query) {
    this.setState({ query: query });
    this.findText(query);
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  render() {
    const {
      currentPage,
      scale,
      rotate,
      togglePart,
      loading,
      outline,
      pdf,
      query,
      fullScreenToggle,
      password,
      needPassword,
      isPasswordIcorrect,
    } = this.state;
    const {
      url,
      authString,
      showFind,
      showPages,
      showToc,
      showFiles,
      availableToolbarOptions,
      isPublic,
    } = this.props;

    const isIPad =
      /^Mac/.test(navigator.platform) && navigator.maxTouchPoints > 4;
    const isMobile =
      isIPad ||
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      ) ||
      false;

    const { formatMessage } = this.props.intl;
    const cx = classNames.bind(styles);
    const classes = cx(
      {
        PdfViewer: true,
      },
      this.props.className
    );

    const loaderWrapperStyle = {
      width: '100%',
      height: '100%',
      flex: 'none',
    };

    const loaderStyle = {
      left: '50%',
      top: '50%',
    };

    // Translations
    const strings = generateStrings(messages, formatMessage);

    let displayStyle = {};
    if (needPassword) {
      displayStyle = { display: 'none' };
    } else {
      displayStyle = { height: '100%' };
    }

    return (
      <div ref={this.PdfComponent} className={classes}>
        {needPassword && (
          <div className={styles.PasswordBlock}>
            <Icon name="lock" size={64} className={styles.LockIcon} /> <br />
            <span className={styles.PasswordProtected}>
              {strings.passwordProtected}
            </span>{' '}
            <br />
            <div className={styles.PasswordMessage}>
              {strings.passwordMessage}
            </div>
            <input
              type="password"
              value={password}
              className={styles.input}
              onChange={this.handlePasswordChange}
              onKeyPress={this.handleKeyEnter}
            />
            <Btn
              type="submit"
              disabled={!password}
              inverted
              large
              onClick={this.handleFileUnlock}
            >
              {strings.unlockFile}
            </Btn>
            {isPasswordIcorrect && (
              <p className={styles.error}>{strings.incorrectPassword}</p>
            )}
          </div>
        )}

        {!needPassword && (
          <FullScreen
            fullScreenToggle={fullScreenToggle}
            onExitFullScreen={this.handleExitFullScreen}
            style={displayStyle}
          >
            {this.props.children}
            {showFind && (
              <PdfFind
                pdf={pdf}
                placeholder={strings.search}
                pdfViewer={this.state.pdfViewer}
                query={query}
                contents={this.state.findContents}
                results={this.state.findResults}
                onPageClick={this.handlePageClick}
                onQueryChange={this.handleQueryChange}
                onSidePanelClose={this.props.onSidePanelClose}
                showCloseButton={isPublic}
                {...{ isMobile }}
              />
            )}
            {showToc && (
              <PdfOutline
                pdf={pdf}
                outline={outline}
                heading={strings.tableOfContents}
                onItemClick={this.handleOutlineItemClick}
                onCloseClick={this.handleOutlineCloseClick}
                onSidePanelClose={this.props.onSidePanelClose}
                showCloseButton={isPublic}
                {...{ isMobile }}
              />
            )}
            {showPages && pdf && this.state.pdfViewer && (
              <PdfPages
                pdf={pdf}
                pdfViewer={this.state.pdfViewer}
                currentPage={currentPage}
                onPageClick={this.handlePageClick}
                onSidePanelClose={this.props.onSidePanelClose}
                showCloseButton={isPublic}
                {...{ isMobile }}
              />
            )}
            {loading && (
              <div style={loaderWrapperStyle}>
                <Loader style={loaderStyle} type="content" />
              </div>
            )}
            <section
              className={styles.pdfviewerSection}
              style={
                isMobile && isPublic
                  ? {
                      height: 'calc(100% - 3.125rem)',
                      marginTop: '3.125rem',
                    }
                  : {}
              }
            >
              <PdfDocument
                ref={this.PdfViewer}
                url={url}
                authString={authString}
                password={password}
                currentPage={currentPage}
                scale={scale}
                rotate={rotate}
                togglePart={togglePart}
                onDocumentComplete={this.handleDocumentComplete}
                onDocumentError={this.handleDocumentError}
                onPageComplete={this.handlePageComplete}
                onOutlineComplete={this.handleOutlineComplete}
                onPageClick={this.handlePageClick}
                onCurrentPageChange={this.handleCurrentPageChange}
                onScaleChange={this.handleScaleChange}
                onPageRendered={this.props.onPageRendered}
                onPageAnchorClick={this.props.onPageAnchorClick}
                onUpdateState={this.handleUpdateState}
                onUpdateResultsCount={this.handleUpdateResultsCount}
              />
              {!loading && (
                <ViewerToolbar
                  ref={c => {
                    this.viewerToolbar = c;
                  }}
                  currentPage={currentPage}
                  totalPages={this.state.totalPages}
                  fullscreen={!this.props.hasWatermark}
                  zoom={scale}
                  rotate={!this.state.fullscreen}
                  onCurrentPageChange={this.handleCurrentPageChange}
                  onItemClick={this.handleToolbarItemClick}
                  zoomMenuDisabled={false}
                  continuousLabel={strings.continuousLabel}
                  singlePageLabel={strings.singlePageLabel}
                  isActive={this.props.isActive}
                  isPdfFile
                  isPublic={isPublic}
                  isSidePanelOpen={this.props.isSidePanelOpen}
                  isSinglePage={
                    this.state.pdfViewer.scrollMode === SCROLL_MODE.singlePage
                  }
                  {...{
                    showToc,
                    showFind,
                    showPages,
                    showFiles,
                    fullScreenToggle,
                  }}
                  {...{
                    availableToolbarOptions,
                  }}
                  onViewerToolbarItemClick={this.props.onViewerToolbarItemClick}
                />
              )}
            </section>
          </FullScreen>
        )}
      </div>
    );
  }
}
export default injectIntl(PdfViewer, { forwardRef: true });
