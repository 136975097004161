/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import ReactSelect, { components } from 'react-select';
import ReactAsyncSelect from 'react-select/async';
import styles from './Select.less';

const SingleValue = props => {
  const { valueRenderer, ValueComponent } = props.selectProps;
  if (valueRenderer && typeof valueRenderer === 'function') {
    return (
      <components.SingleValue {...props}>
        {valueRenderer()}
      </components.SingleValue>
    );
  }
  if (ValueComponent) {
    return (
      <components.SingleValue {...props}>
        <ValueComponent {...props} value={props.data} />
      </components.SingleValue>
    );
  }
  return <components.SingleValue {...props} />;
};

const Placeholder = props => (
  <components.Placeholder {...props} className={styles.placeholder} />
);

const DropdownIndicator = props => {
  const { menuIsOpen } = props.selectProps;
  const cx = classNames.bind(styles);
  const className = cx(
    {
      menuIsOpen: menuIsOpen,
    },
    styles.dropdownIndicator
  );
  return (
    <components.DropdownIndicator {...props} style={{ paddingLeft: 0 }}>
      <span className={className} />
    </components.DropdownIndicator>
  );
};

const Option = props => {
  const { id, data, options } = props;
  const { labelKey, isMulti, value, optionRenderer, OptionComponent, strings } =
    props.selectProps;

  let optionLabel = '';

  if (labelKey) {
    optionLabel = props.data[labelKey];
  } else {
    optionLabel = props.label || props.name;
  }

  let isChecked = false;

  if (props.isSelected) {
    if (isMulti || (value && typeof value.map === 'function')) {
      isChecked = value.some(ele => {
        const optionValue = ele.id === undefined ? ele.value : ele.id;
        const selectedValue = data.id === undefined ? data.value : data.id;

        return optionValue === selectedValue;
      });
    } else {
      const optionValue = value.id === undefined ? value.value : value.id;
      const selectedValue = data.id === undefined ? data.value : data.id;

      isChecked = optionValue === selectedValue;
    }
  }

  if (OptionComponent)
    return (
      <components.Option {...props}>
        <OptionComponent {...props} option={data} isSelected={isChecked} />
      </components.Option>
    );

  if (optionRenderer && typeof optionRenderer === 'function')
    return (
      <components.Option {...props}>
        {optionRenderer({
          ...props,
          ...data,
          key: id,
          option: data,
          valueArray: options,
          strings: strings,
        })}
      </components.Option>
    );

  const cx = classNames.bind(styles);
  const optionClass = cx(
    {
      isChecked: isChecked,
    },
    styles.optionItem
  );

  return (
    <div className={optionClass}>
      <components.Option {...props}>{optionLabel}</components.Option>
    </div>
  );
};

const getCSSVar = key =>
  getComputedStyle(document.documentElement).getPropertyValue(key);

function Select(props) {
  const selectRef = useRef(null);
  const selectContainerRef = useRef(null);
  const [maxMenuHeight, setMaxMenuHeight] = useState(NaN);

  const handleLabelClick = event => {
    event.preventDefault();
    selectRef.focus();
  };

  const {
    label,
    selectClassName,
    selectStyle,
    className,
    style,
    clearable,
    id,
    name,
    options,
    placeholder,
    searchable,
    isSearchable,
    onChange,
    value,
    valueRenderer,
    labelKey,
    valueKey,
    isMulti,
    multi,
    disabled,
    optionComponent,
    valueComponent,
    optionRenderer,
    filterOptions,
    filterOption,
    optionHeight,
    strings,
    async,
    defaultValue,
    isClearable,
    autoBlur,
    singleValueStyle,
    inputStyle,
    dynamicMenuListHeight,
    ...others
  } = props;

  const cx = classNames.bind(styles);
  const classes = cx(
    {
      Select: true,
      isDisabled: disabled,
    },
    className
  );

  const getSelectValue = () => {
    let selectedValue = value;

    const isOptionSelected = option => {
      if (!option.options) {
        let optionValue;
        if (valueKey) {
          optionValue = option[valueKey];
        } else {
          optionValue = option.id || option.value;
        }
        if (optionValue === value) selectedValue = option;
        return;
      }
      option.options.forEach(o => isOptionSelected(o));
    };

    if (value === '') {
      options.forEach(option => isOptionSelected(option));
      return selectedValue;
    }
    if (!value && value !== 0) return value;
    if (options && typeof options[0] !== 'object') return value;
    if (typeof value !== typeof options[0]) {
      options.forEach(option => isOptionSelected(option));
      return selectedValue;
    }

    return selectedValue;
  };

  /**
   * @function getMenuListHeight - calculate the proper height of the menu list component
   *
   * @param {Number} previousHeight - The previous Height of the menu.
   * @returns {Number} The height of the menu list component.
   */
  const getMenuListHeight = previousHeight => {
    if (!dynamicMenuListHeight) return previousHeight;

    if (selectContainerRef && selectContainerRef.current) {
      const { offsetTop, clientHeight, offsetParent } =
        selectContainerRef.current;

      const distanceBetweenSelectAndParentBottom =
        offsetParent.clientHeight -
        clientHeight -
        offsetTop +
        offsetParent.scrollTop;

      // "-5" is used to give some margin between the bottom edge and menuList
      // to make it easy for eyes.
      return distanceBetweenSelectAndParentBottom - 5;
    }

    return previousHeight;
  };

  const handleOnChange = params => {
    onChange(params);
  };

  const handleOnMenuOpen = () => {
    const newMenuHeight = getMenuListHeight(maxMenuHeight);
    setMaxMenuHeight(newMenuHeight);
  };

  if (async) return <ReactAsyncSelect {...props} />;

  const optionHeightFunction =
    typeof optionHeight === 'function' ? optionHeight : () => optionHeight;

  return (
    <div className={classes} style={style} ref={selectContainerRef}>
      {label && (
        <label htmlFor={id} onClick={handleLabelClick}>
          {label}
        </label>
      )}
      <ReactSelect
        ref={selectRef}
        id={id}
        name={name}
        className={selectClassName}
        style={selectStyle}
        options={options}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={handleOnChange}
        maxMenuHeight={dynamicMenuListHeight ? maxMenuHeight : undefined}
        labelKey={labelKey}
        valueKey={valueKey}
        getOptionValue={option =>
          valueKey ? option[valueKey] : option.id || option.value
        }
        getOptionLabel={option =>
          labelKey ? option[labelKey] : option.name || option.label
        }
        OptionComponent={optionComponent}
        ValueComponent={valueComponent}
        filterOption={filterOptions || filterOption}
        styles={{
          option: (base, state) => ({
            ...base,
            backgroundColor: '#fff',
            color: getCSSVar('--primary-text'),
            padding: optionHeight ? 0 : base.padding,
            display: 'flex',
            alignItems: 'center',
            height: optionHeight
              ? optionHeightFunction({ ...state, option: state.data })
              : base.height,
            ':hover': {
              backgroundColor:
                state.isDisabled || optionComponent
                  ? 'transparent'
                  : getCSSVar('--light-base-color'),
            },
            ':active': { backgroundColor: 'transparent' },
          }),
          indicatorSeparator: base => ({
            ...base,
            display: 'none',
          }),
          indicatorsContainer: base => ({
            ...base,
            div: { paddingLeft: 0 },
          }),
          control: (base, state) => ({
            ...base,
            backgroundColor: state.selectProps.menuIsOpen ? '#fff' : '#eee',
            borderColor: state.isFocused ? '#d1d1d6' : '#eee',
            boxShadow: 'none',
            borderRadius: state.selectProps.menuIsOpen
              ? '0.25rem 0.25rem 0 0'
              : '0.25rem',
            ':hover': { borderColor: '#eee' },
          }),
          menu: base => ({
            ...base,
            marginTop: 0,
            borderRadius: '0 0 0.25rem 0.25rem',
            backgroundColor: '#fff',
            zIndex: 999,
          }),
          singleValue: base => ({
            ...base,
            width: '100%',
            ...singleValueStyle,
          }),
          input: base => ({
            ...base,
            ...inputStyle,
          }),
          multiValue: base => ({
            ...base,
            position: 'relative',
            margin: '0.3rem 0.55rem 0.3rem 0',
          }),
          multiValueLabel: base => ({
            ...base,
            padding: '0.15rem 0.5rem',
            fontSize: 14,
            borderRadius: 4,
            backgroundColor: getCSSVar('--base-color'),
            whiteSpace: 'nowrap',
            color: '#fff',
            position: 'relative',
          }),
          multiValueRemove: base => ({
            ...base,
            position: 'absolute',
            top: '-0.35rem',
            right: '-0.35rem',
            borderRadius: '50%',
            padding: 0,
            backgroundColor: getCSSVar('--secondary-text'),
            svg: { color: '#fff' },
          }),
        }}
        components={{
          Option,
          DropdownIndicator,
          SingleValue,
          Placeholder,
        }}
        value={getSelectValue()}
        valueRenderer={valueRenderer}
        optionRenderer={optionRenderer}
        isMulti={isMulti || multi}
        isSearchable={isSearchable || searchable}
        isDisabled={disabled}
        isClearable={clearable || isClearable}
        autoBlur={autoBlur}
        strings={strings}
        onMenuOpen={handleOnMenuOpen}
        {...others}
      />
    </div>
  );
}

Select.propTypes = {
  /** id attribute for input, required when <code>label</code> is provided */
  id: function (props) {
    if (props.label && typeof props.id !== 'string') {
      return new Error('id is required when label is provided.');
    }
    return null;
  },
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]),
  options: PropTypes.array,
  dynamicMenuListHeight: PropTypes.bool,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  onChange: PropTypes.func,
  searchable: PropTypes.bool,
  clearable: PropTypes.bool,
  placeholder: PropTypes.string,

  /** Text to place above input */
  label: PropTypes.string,

  selectClassName: PropTypes.string,

  selectStyle: PropTypes.object,

  className: PropTypes.string,
  style: PropTypes.object,
};

export default React.memo(Select);
