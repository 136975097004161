/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-starter
 * @copyright 2010-2017 BigTinCan Mobile Pty Ltd
 * @author Shibu Bhattarai <shibu.bhattarai@bigtincan.com>
 */

import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Welcome } from "../../components/Welcome/Index";
import { Introduction } from "../../components/Introduction/Index";
import authenticationServiceClient from "../../service/AuthneticationService";
import { crmDetails } from "../../helpers/crm";

interface IAppState {
  showWelcome: boolean;
  showIntroductionView: boolean;
  showRegionSelectionView: boolean;
}

interface IAppProps {
  version: string;
}

type AppProps = RouteComponentProps & IAppProps;

class App extends React.Component<AppProps, IAppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      showWelcome: true,
      showIntroductionView: false,
      showRegionSelectionView: false,
    };
  }

  public componentDidMount() {

    const tokens: any = authenticationServiceClient.getTokens();

    localStorage.removeItem("fileCollection");
    localStorage.removeItem("shareLinkData");
    localStorage.removeItem("selectedOpportunity");
    localStorage.removeItem("includeAccessFormUpdated");
    localStorage.removeItem("addLeadFormDefault");
    localStorage.removeItem("defaultExpirationDate");
    localStorage.removeItem("selectedLeadFormId");
    localStorage.removeItem("downloadSharedFilesAsPDFUpdated");
    localStorage.removeItem("daysToExpiry");

    if (!tokens.BTCTK_R) {
      setTimeout(() => {
        this.showIntroduction();
      }, 3000);
    } else {
      setTimeout(() => {
        crmDetails.initCompanySettings().then(() => {
          crmDetails.selectedOpportunity = [];
          this.props.history.push("/home");
        }).catch((data) => {
          if (data && data.response && data.response.body && data.response.body.code === 101) {
            authenticationServiceClient.getAccessToken().then((tokenInfo) => {
              if (tokenInfo.oauth2) {
                localStorage.setItem("BTCTK_A", tokenInfo.oauth2.access_token);
                localStorage.setItem("BTCTK_R", tokenInfo.oauth2.refresh_token);
                localStorage.setItem("expires_in", tokenInfo.oauth2.expires_in);
                this.props.history.push("/home");
              } else {
                this.initLogin();
              }
            }).catch(() => {
              this.initLogin();
            });
          } else {
            this.initLogin();
          }
        });
      }, 1000);
    }
  }

  private initLogin() {
    localStorage.clear();
    setTimeout(() => {
      this.showIntroduction();
    }, 3000);
  }

  public render() {
    let comp = <Welcome />;
    if (this.state.showIntroductionView) {
      comp = <Introduction onSkip={this.onSkip.bind(this)} />;
    }
    return comp;
  }

  public onSkip() {
    this.props.history.push("/signin");
  }

  public showIntroduction() {
    this.setState({ showWelcome: false, showIntroductionView: true });
  }
}

export default App;
