/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package Bigtincan Hub
 * @copyright 2010-2020 BigTinCan Mobile Pty Ltd
 * @author Bhuvan Kakkar <bhuvan.kakkar@bigtincan.com>
 */

import * as React from "react";
import * as classNames from "classnames/bind";
import { OpportunityBlock } from "../../components/OpportunityBlock/Index";
import { IOpportunityModel } from "../../models/index";
import crmService from "../../service/CrmService";
import outlookClient from "../../service/OutlookClient";
import { crmDetails } from "../../helpers/crm";
import styles from "./index.less";
import Checkbox from "components/Checkbox/Checkbox";

export interface IDynamicsBlockState {
    enableSalesforce: boolean;
    isUserLogin: boolean;
    companySettings?: any;
    selectedOpportunities: IOpportunityModel[];
    successMessage: string;
    showInfo: boolean;
    composeMode: boolean;
    showCrmEntityBlock: boolean;
}
export interface IDynamicsBlockProps {
    crmSource: string;
    onOpportunityItemSelected?: (items: any[]) => void;
}
export class DynamicsBlock extends React.Component<IDynamicsBlockProps, IDynamicsBlockState> {
    constructor(props) {
        super(props);
        this.state = {
            composeMode: true,
            showCrmEntityBlock: false,
            enableSalesforce: this.enableSalesforce,
            companySettings: crmDetails,
            selectedOpportunities: [],
            successMessage: "Email logged",
            showInfo: false,
            isUserLogin: this.isCrmAuthenticated,
        };
    }
    public componentDidMount() {
        outlookClient.getEmailBody().then((emailItem: any) => {
            this.setState({
                composeMode: emailItem.emailMode === 2,
            });
        });
        if (this.isCrmAuthenticated) {
            crmService.getCrmSettings(this.props.crmSource).then((crmData) => {
                if (crmData.cloudAccountId) {
                    localStorage.setItem("opportunityFilter", crmData.opportunityFilter || "[]");
                    localStorage.setItem("cloudAccountId", crmData.cloudAccountId);
                    this.setState({
                        showCrmEntityBlock: true,
                    });
                }
            });
        }
    }
    public render() {
        const cx = classNames.bind(styles);
        const footerClasses = cx({
            showInfo: this.state.showInfo,
        }, styles.footer);
        if (!this.state.showCrmEntityBlock) {
            return null;
        }
        return (
            <div className={styles.crmBlockRoot}>
                <div className={styles.log_email_to_salesfo}>
                    <h3>CRM logging</h3>
                    <p>This share can be logged against any opportunity.</p>
                    <div>
                        <div>
                            <Checkbox
                                label="Log Share to Microsoft Dynamics"
                                name="logShare"
                                checked={this.state.enableSalesforce}
                                onChange={this.onSFEnable.bind(this)}
                                className={styles.disclosure_indicator}
                            />
                            <div className={styles.icon} />
                        </div>
                        {this.state.enableSalesforce && this.state.isUserLogin && this.state.showCrmEntityBlock &&
                            <div>
                                <OpportunityBlock
                                    crmSource={this.props.crmSource}
                                    onItemSelected={this.onOpportunityItemSelected.bind(this)}
                                />
                            </div>
                        }
                    </div>
                </div>
                <div className={styles.crmBlock}>
                    {!this.state.isUserLogin &&
                        <div className={styles.crmErrorBlock}>
                            <div className={styles.title}>You must authenticate Saleforce from Bigtincan Hub</div>
                            <div className={styles.title} />
                        </div>
                    }
                </div>
                <div className={footerClasses}>
                    {this.state.showInfo &&
                        <div className={styles.oppCmdContainer}>
                            <div className={styles.title}>{this.state.successMessage}</div>
                        </div>
                    }
                </div>
            </div>
        );
    }
    public onSFEnable(event: any) {
        window.logCRM = !this.state.enableSalesforce;
        this.setState({
            enableSalesforce: !this.state.enableSalesforce,
        });
        if (window.logCRM) {
            localStorage.setItem("logToCrm", "true");
        } else {
            localStorage.removeItem("logToCrm");
        }
        crmDetails.logToCrm = window.logCRM;
    }
    public onOpportunityItemSelected(items: any[]) {
        this.setState({
            selectedOpportunities: items,
        });
        if (this.props.onOpportunityItemSelected) {
            this.props.onOpportunityItemSelected(items);
        }
    }
    public onClearClicked(items: any[]) {
        this.setState({
            selectedOpportunities: [],
        });
    }
    public onOppLogClick() {
        const promiseCollection: Array<Promise<any>> = [];
        outlookClient.getEmailBody().then((emailItem: any) => {
            if (emailItem) {
                const subject = emailItem.emailMode === 1 ? emailItem.subject : emailItem.composeSubject;
                let msgBody = "";
                if (emailItem.emailMode === 2) {
                    msgBody = emailItem.bodyText;
                } else {
                    const from = emailItem.from.displayName + "< " + emailItem.from.emailAddress + "> ";
                    msgBody = "From :  " + from + "  " + emailItem.bodyText;
                }
                this.state.selectedOpportunities.forEach((item) => {
                    if (!item.logged) {
                        const defaultValue: any = {
                            Description: msgBody,
                            CallDisposition: emailItem.itemId,
                            Priority: "Normal",
                            Status: "Completed",
                            Subject: subject,
                            TaskSubtype: "Email",
                            ActivityDate: Date.now(),
                        };
                        defaultValue.WhatId = item.id;
                        promiseCollection.push(crmService.logEmail(defaultValue, this.props.crmSource));
                    }

                });
                Promise.all(promiseCollection).then(() => {
                    const selectedOpportunities = Object.assign([], this.state.selectedOpportunities);
                    selectedOpportunities.forEach((item: any) => {
                        item.logged = true;
                    });
                    this.setState({
                        successMessage: "Email logged ",
                        showInfo: true,
                    });
                    setTimeout(() => {
                        this.setState({
                            successMessage: " ",
                            showInfo: false,
                            selectedOpportunities,
                        });
                    }, 3000);
                    if (window && window.Office && window.Office && window.Office.context && window.Office.context.mailbox) {
                        const emailSubject = emailItem.emailMode === 1 ? emailItem.subject : emailItem.composeSubject;
                        const successMessage = "BTC: EmailLog:  " + emailSubject;
                        window.Office.context.mailbox.item.notificationMessages.addAsync("information", {
                            type: "informationalMessage",
                            message: successMessage,
                            icon: "green-icon-16",
                            persistent: true,
                        });
                    }
                });
            }
        });
    }
    private get isCrmAuthenticated(): boolean {
        const authenticated = crmDetails.authenticated;
        return authenticated;
    }

    private get enableSalesforce(): boolean {
        const logToCrm = localStorage.getItem("logToCrm");
        if (crmDetails.crmEnabled && this.isCrmAuthenticated && logToCrm === "true") {
            crmDetails.logToCrm = true;
            return true;
        } else {
            return false;
        }
    }
}
