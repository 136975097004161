/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2016 BigTinCan Mobile Pty Ltd
 * @author Shibu Bhattarai <shibu.bhattarai@bigtincan.com>
 */
import { getParam } from "../helpers/urlHelpers";
const superagent = require("superagent");

class CrmApiClient {

  private InternalCall(method: string, path: any, options?: any): Promise<any> {
    const { params, data, headers } = options;
    return new Promise((resolve, reject) => {
      const apiPath = window.BTC.BTCAPI + path;

      // Our superagent request
      const request = superagent[method](apiPath);
      request.set({ Authorization: "Bearer " + localStorage.getItem("BTCTK_A") });
      if (headers) {
        Object.keys(headers).map((key) => {
          const item = headers[key];
          request.set(key, item);
          return item;
        });
      }
      if (params) {
        delete params.access_token;  // eslint-disable-line
        delete params.account_id;  // eslint-disable-line
        delete params.keyword;  // eslint-disable-line
        request.query(params);
      }
      if (data) {
        request.send(data);
      }
      request.end((err, res) => {
        const body = res.body;
        if (err) {
          reject(body || err);
        } else {
          const requestTimeStamp = getParam("requestTimeStamp", res.req.url);
          const bdy = {
            ...body,
            requestTimeStamp: requestTimeStamp ? +requestTimeStamp : 0,
          };
          resolve(bdy);
        }
      });
    });
  }
  public get(path, source?: string | null, options?: any): Promise<any> {
    return this.InternalCall("get", path, options);
  }
  public post(path, source?: string, options?: any): Promise<any> {
    return this.InternalCall("post", path, options);
  }
  public patch(path, source?: string, options?: any): Promise<any> {
    return this.InternalCall("patch", path, options);
  }
  public put(path, source?: string, options?: any): Promise<any> {
    return this.InternalCall("put", path, options);
  }

}
const crmApiClient = new CrmApiClient();
export default crmApiClient;
